import React, { ChangeEvent, useEffect, useState } from 'react'
import GetHtmlStrLocalized from '../../datas/GetHtmlStrLocalized'
import Modal, { ModalBody, ModalFooter, ModalHeader } from './Modal'
import { HcInput } from '../input/HcInput';
import { SiteOfASourceProps } from '../../types/SourceEdition';


interface SiteEditionModalProps {
    language: string;
    addOrEdit: boolean;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    isDarkTheme: boolean;
    site: SiteOfASourceProps;
    onSiteValidated: (r: SiteOfASourceProps) => any;
}


function SiteEditionModal({ language, addOrEdit, show, setShow, isDarkTheme, site, onSiteValidated }: Readonly<SiteEditionModalProps>) {
    const [title, setTitle] = useState<string>("")
    const [url, setUrl] = useState<string>("")
    const [updateAutomatically, setUpdateAutomatically] = useState<string>("false")
    const [maxTimeInSForAutomaticUpdate, setMaxTimeInSForAutomaticUpdate] = useState<number | undefined>(undefined)


    useEffect(() => {
        if (show) {
            setTitle(site.title)
            setUrl(site.url)
            setUpdateAutomatically(site.updateAutomatically)
            setMaxTimeInSForAutomaticUpdate(site.maxTimeInSForAutomaticUpdate)
        }
    }, [show]);

    return (
        <Modal
            language={language}
            show={show}
            setShow={setShow}
            isDarkTheme={isDarkTheme}
        >
            <ModalHeader>
                <h3><GetHtmlStrLocalized language={language} textId={addOrEdit ? "addASite" : "modifyASite"} /></h3>
            </ModalHeader>
            <ModalBody>

                <b><GetHtmlStrLocalized language={language} textId="url" /></b>
                <HcInput value={url} onChange={(e: ChangeEvent<HTMLInputElement>) => { setUrl(e.target.value); }} />
                <br />
                <br />
                <br />

                <b><GetHtmlStrLocalized language={language} textId="title" /></b>
                <HcInput value={title} onChange={(e: ChangeEvent<HTMLInputElement>) => { setTitle(e.target.value); }} />

                <br />
                <br />
                <br />
                <input type="checkbox" checked={updateAutomatically === "true"} onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setUpdateAutomatically(event.target.checked ? "true" : "false")
                }
                } />
                <GetHtmlStrLocalized language={language} textId="updateAutomatically" />
                <br />
                <br />
                <b><GetHtmlStrLocalized language={language} textId="maxTimeForAutomaticUpdate" /></b>
                <HcInput value={maxTimeInSForAutomaticUpdate} onChange={(e: ChangeEvent<HTMLInputElement>) => { setMaxTimeInSForAutomaticUpdate(Number(e.target.value)); }} />

            </ModalBody>
            <br />
            <ModalFooter>
                <button onClick={() => {
                    onSiteValidated({ url, title, updateAutomatically, maxTimeInSForAutomaticUpdate })
                    setShow(false)
                }}>
                    <GetHtmlStrLocalized language={language} textId="validate" />
                </button>
            </ModalFooter>
        </Modal>
    );
}

export default SiteEditionModal;