import './bubble.scss';
import { Link } from "react-router-dom"
import { MessageProps } from "../../props/messageprops";
import H2TitleId from "../title/H2TitleId"
import BubbleFromUser from "./BubbleFromUser"



interface BubblesFromUserProps {
    language: string;
    titleId: string;
    chatMsgs: MessageProps[];
    onClick?: (id: string) => void;
}

function BubblesFromUser({ language, titleId, chatMsgs, onClick }: Readonly<BubblesFromUserProps>) {

    return (
        <>
            {
                chatMsgs.length > 0 &&
                <>
                    <br />
                    <H2TitleId language={language} titleId={titleId} />
                    <div className='hc-categories-left-margin'>
                        {
                            chatMsgs.map((item, index) => {
                                return (
                                    <span key={"chat-msg-" + item.id + "-" + index}>
                                        {
                                            onClick !== undefined ?
                                                <span className='hc-move-up-animation hc-bubble-from-user' onClick={() => onClick(item.id)}>
                                                    <BubbleFromUser>{item.mainTrigger}</BubbleFromUser>
                                                </span>
                                                :
                                                <Link className='hc-move-up-animation hc-bubble-from-user' to={'/' + language + '/' + item.id}>
                                                    <BubbleFromUser>{item.mainTrigger}</BubbleFromUser>
                                                </Link>
                                        }
                                    </span>
                                )
                            })
                        }
                    </div>
                </>
            }
        </>
    )
}

export default BubblesFromUser



