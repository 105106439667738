import image_wait from '../assets/resources/custom/icons/wait.gif'
import image_add_small from '../assets/resources/custom/icons/add_small.png'
import image_edit_small from '../assets/resources/custom/icons/edit_small.png'
import image_trash_small from '../assets/resources/custom/icons/trash_small.png'
import image_tests_small from '../assets/resources/custom/icons/tests_small.png'
import image_move from '../assets/resources/custom/icons/move.png'
import { useEffect, useState } from "react"
import { Navigate, useLocation } from "react-router-dom"
import H1Title from '../components/title/H1Title'
import PageContent from '../components/util/PageContent'
import GetStrLocalized from '../datas/GetStrLocalized'
import { HcContext, SizeOfWindow } from '../types/hccontext'
import HorizontalSpace from '../components/util/HorizontalSpace'
import GetHtmlStrLocalized from "../datas/GetHtmlStrLocalized"
import Centered from "../components/util/Centered"
import { getWaitPopupStyle } from "../components/util/GetWaitPopupStyle"
import { HCFileTypeIcon } from '../components/icon/HCFileTypeIcon'
import { createErrorModalContent } from '../props/ModalContentProps'


interface ModificationProps {
	path: string;
	action: string;
	date: string;
	author: string;
	type: string;
	fileType: string;
	applied: string;
	filenameToMove: string;
}

interface ContentModificationsProps {
	language: string;
	hcContext: HcContext;
}


class ApplyAModificationsPostContentType {
	language: string;
	userToken: string;
	date: string;

	constructor(
		language: string,
		userToken: string,
		date: string) {
		this.language = language
		this.userToken = userToken
		this.date = date
	}
}


class ContentModificationsPostContentType {
	language: string;
	userToken: string;

	constructor(
		language: string,
		userToken: string) {
		this.language = language
		this.userToken = userToken
	}
}


function ContentEditHistory({ language, hcContext }: Readonly<ContentModificationsProps>) {

	useEffect(() => {
		hcContext.setDocumentTitle(GetStrLocalized(language, "contentEditHistory") + " - Holy Chatter");
	}, [hcContext, language]);

	const location = useLocation()
	const [lastPath, setLastPath] = useState("")
	const [lastSearch, setLastSearch] = useState("")
	const [modifications, setModifications] = useState<ModificationProps[]>([])
	const [processingModifications, setProcessingModifications] = useState(false)

	function fetchContent() {
		if (hcContext.userToken) {
			const postContent = new ContentModificationsPostContentType(language, hcContext.userToken)
			fetch(hcContext.backendUrl + "/content_modifications", {
				method: 'POST',
				body: JSON.stringify(postContent),
				headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
			})
				.then(res => res.json())
				.then(jsonData => {
					if (jsonData.errorMessage) {
						hcContext.setInfo(createErrorModalContent(language, jsonData.errorMessage))
						return
					}
					if (jsonData.modifications === "")
						jsonData.modifications = []
					setModifications(jsonData.modifications)
				})
				.catch(rejected => {
					hcContext.setInfo(createErrorModalContent(language, rejected.message))
				});
		}
	}


	function applyAModification(date: string) {
		if (hcContext.userToken) {
			setProcessingModifications(true)
			hcContext.setBlackCoverOfContent(true)

			const postContent = new ApplyAModificationsPostContentType(language, hcContext.userToken, date)
			fetch(hcContext.backendUrl + "/apply_a_modification", {
				method: 'POST',
				body: JSON.stringify(postContent),
				headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
			})
				.then(res => res.json())
				.then(jsonData => {
					if (jsonData.errorMessage) {
						hcContext.setInfo(createErrorModalContent(language, jsonData.errorMessage))
						return
					}
					setProcessingModifications(false)
					hcContext.setBlackCoverOfContent(false)
					fetchContent()
				})
				.catch(rejected => {
					setProcessingModifications(false)
					hcContext.setBlackCoverOfContent(false)
					hcContext.setInfo(createErrorModalContent(language, rejected.message))
				});
		}
	}


	if (location.pathname !== lastPath || location.search !== lastSearch) {
		setLastPath(location.pathname)
		setLastSearch(location.search)
		fetchContent()
	}

	function DisplayTitle() {
		return (
			<div>
				<table width="100%" style={{ marginTop: '20px', marginBottom: '20px' }}>
					<tbody>
						<tr>
							<td className='hc-content-text hc-content-text-paddings' width="40%">
								<b><GetHtmlStrLocalized language={language} textId="name" /></b>
							</td>
							{
								hcContext.sizeOfWindow !== SizeOfWindow.VERY_SHORT &&
								<td className='hc-content-text hc-content-text-paddings' width="15%">
									<b><GetHtmlStrLocalized language={language} textId="type" /></b>
								</td>
							}
							{
								hcContext.sizeOfWindow !== SizeOfWindow.VERY_SHORT &&
								<td className='hc-content-text hc-content-text-paddings' width="15%">
									<b><GetHtmlStrLocalized language={language} textId="author" /></b>
								</td>
							}
							{
								hcContext.sizeOfWindow !== SizeOfWindow.VERY_SHORT &&
								<td className='hc-content-text hc-content-text-paddings' width="15%">
									<b><GetHtmlStrLocalized language={language} textId="date" /></b>
								</td>
							}
							<td className='hc-content-text hc-content-text-paddings' width="15%">
								<Centered>
									<b><GetHtmlStrLocalized language={language} textId="action" /></b>
								</Centered>
							</td>
						</tr>
					</tbody>
				</table>
				<hr style={{ height: 2, backgroundColor: '#333333', border: 'none' }} />
			</div>
		)
	}


	interface DisplayModificationLineProps {
		modification: ModificationProps;
	}

	function DisplayModificationLine({ modification }: Readonly<DisplayModificationLineProps>) {

		return (
			<div style={{ fontWeight: 'bold' }}>
				<table width="100%" style={{ marginTop: '20px', marginBottom: '20px' }}>
					<tbody>
						<tr>
							<td className='hc-content-text hc-content-text-paddings' width="40%">
								<HCFileTypeIcon fileType={modification.fileType} />
								&nbsp; &nbsp; {modification.path}
							</td>
							{
								hcContext.sizeOfWindow !== SizeOfWindow.VERY_SHORT &&
								<td className='hc-content-text hc-content-text-paddings' width="15%">
									<img src={modification.action === "add" ? image_add_small :
										(modification.action === "remove" ? image_trash_small :
											(modification.action === "tests_update" ? image_tests_small :
												(modification.action === "move" ? image_move : image_edit_small)))}
										alt="message icon" height="15px" width="15px" style={{ paddingTop: '6px' }} />
									&nbsp; &nbsp; {GetStrLocalized(language, "modificationType_" + modification.action)}
								</td>
							}
							{
								hcContext.sizeOfWindow !== SizeOfWindow.VERY_SHORT &&
								<td className='hc-content-text hc-content-text-paddings' width="15%">
									{modification.author}
								</td>
							}
							{
								hcContext.sizeOfWindow !== SizeOfWindow.VERY_SHORT &&
								<td className='hc-content-text hc-content-text-paddings' width="15%">
									{modification.date}
								</td>
							}
							<td className='hc-content-text hc-content-text-paddings' width="15%">
								<Centered>
									<button onClick={() => applyAModification(modification.date)}>
										{GetStrLocalized(language, modification.applied === "true" ? "cancel" : "restore")}
									</button>
								</Centered>
							</td>
						</tr>

					</tbody>
				</table>
				{
					hcContext.sizeOfWindow === SizeOfWindow.WIDE &&
					<hr style={{ height: 1, backgroundColor: 'grey', border: 'none' }} />
				}
			</div>
		)
	}

	return (
		<PageContent>
			{
				!hcContext.userAsRightsToModifyTheContent &&
				<Navigate to={"/" + language} />
			}

			{
				processingModifications &&
				<Centered>
					<div style={getWaitPopupStyle(true, 50)}>
						<img src={image_wait} alt="wait" height="100%" width="100%" />
					</div>
				</Centered>
			}

			<table width='100%'>
				<thead className="visually-hidden"><tr><th></th></tr></thead>
				<tbody>
					<tr>
						<td width={(30 + 15) * 3 + 45} style={{ paddingTop: 20 }} className="hc-not-very-short-screen">
							<HorizontalSpace width={45} />
						</td>
						<td>
							<H1Title><GetHtmlStrLocalized language={language} textId="contentEditHistory" /></H1Title>
						</td>
						<td width={(30 + 15) * 3 + 45} className="hc-not-very-short-screen"></td>
					</tr>
				</tbody>
			</table>

			<br />
			<div className='hc-content-text-paddings'>
				<DisplayTitle />
				{modifications.map((item, index) => { return <DisplayModificationLine key={"modification-" + index} modification={item} /> })}
			</div>
		</PageContent>
	)
}

export default ContentEditHistory;