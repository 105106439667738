import React from "react"
import BubbleFromUser from "./BubbleFromUser"

interface BubblesFromUserInChatProps {
    children: React.ReactNode;
}


const BubbleFromUserInChat: React.FC<BubblesFromUserInChatProps> = ({ children }) => {

    return (
        <table width="100%">
            <thead className="visually-hidden"><tr><th></th></tr></thead>
            <tbody>
                <tr>
                    <td width="96%" style={{paddingTop: '7px'}}>
                        <div style={{ float: 'right' }}>
                            <BubbleFromUser>{children}</BubbleFromUser>
                        </div>
                    </td>
                    <td width="4%"></td>
                </tr>
            </tbody>
        </table>
    )
}

export default BubbleFromUserInChat



