import image_wait from '../../assets/resources/custom/icons/wait.gif'
import React, { ChangeEvent, useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import GetHtmlStrLocalized from '../../datas/GetHtmlStrLocalized'
import Button from '../button/Button'
import { ButtonType } from '../../props/buttondataprops'
import HorizontalSpace from '../util/HorizontalSpace'
import { HcContext, SelectEltProps } from '../../types/hccontext'
import Centered from '../util/Centered'
import EditionLabel from '../title/EditionLabel'
import H1Title from '../title/H1Title'
import { ArticleEdition, ArticleEditionProps, PlannerActionProps, RecommendationsInfosProps, createEmptyPlannerActionProps, createEmptyRecommendationsInfosProps } from '../../types/ArticleEdition'
import ReferencesListViewer from '../listview/ReferencesListViewer'
import { ReferenceEditionProps } from '../../props/ReferenceEditionProps'
import { BibleBookProps } from '../../props/BibleBookProps'
import TriggersListViewer from '../listview/TriggersListViewer'
import TagsListViewer from '../listview/TagsListViewer'
import YesNoModal from '../modal/YesNoModal'
import { FolderProps } from '../../props/folderprops'
import GetStrLocalized from '../../datas/GetStrLocalized'
import { getWaitPopupStyle } from '../util/GetWaitPopupStyle'
import { imgToFirebaseUrl2 } from '../util/imagefallbacks'
import BigButtonWithTextAtBottom from '../bigButton/BigButtonWithTextAtBottom'
import SelectImageModal from '../modal/SelectImageModal'
import ArticleSelectorModal from '../modal/ArticleSelectorModal'
import RecommendationsListViewer from '../listview/RecommendationsListViewer'
import VerticalDisplay from '../util/VerticalDisplay'
import SubEditionLabel from '../title/SubEditionLabel'
import { removeItem } from '../../util/removeItem'
import { HcMessageInput } from '../input/HcMessageInput'
import SourceSelectWithUrl from '../source/SourceSelectWithUrl'
import { createErrorModalContent } from '../../props/ModalContentProps'
import InputWithStyle from '../banner/InputWithStyle'


interface ArticleEditionPossibilitiesProps {
	tags: string[]
	sources: SelectEltProps[]
	bibleBooks: BibleBookProps[]
	linksFromCorpus: SelectEltProps[]
}

interface ArticleModifierProps {
	language: string;
	hcContext: HcContext;
	parentFolders: FolderProps[];
	currentFolderName?: string;
	articleId: string;
	lastPath: string;
};


class ArticleModificationPostContentType {
	language: string;
	userKey: string;
	parentFolders: FolderProps[];
	currentFolderName: string;
	id: string;
	oldValue: ArticleEditionProps;
	value: ArticleEditionProps;

	constructor(
		language: string,
		userKey: string,
		parentFolders: FolderProps[],
		currentFolderName: string,
		id: string,
		oldValue: ArticleEditionProps,
		value: ArticleEditionProps) {
		this.language = language;
		this.userKey = userKey;
		this.parentFolders = parentFolders;
		this.currentFolderName = currentFolderName;
		this.id = id;
		this.oldValue = oldValue;
		this.value = value;
	}
}

function ArticleModifier({ language, hcContext, parentFolders, currentFolderName = "", articleId, lastPath }: Readonly<ArticleModifierProps>) {

	const navigate = useNavigate()
	const [modificationDone, setModificationDone] = useState(false)
	const [canBeValidated, setCanBeValidated] = useState(false)
	const [processingModifications, setProcessingModifications] = useState(false)
	const [pageFound, setPageFound] = useState<boolean | undefined>(undefined)
	const [showCanCancelModifictionsModal, setShowCanCancelModifictionsModal] = useState(false)
	const [showCanDeleteModifictionsModal, setShowCanDeleteModifictionsModal] = useState(false)
	const [showNextMessageIdSelectionModal, setShowNextMessageIdSelectionModal] = useState(false)
	const [articlePossibilities, setArticlePossibilities] = useState<ArticleEditionPossibilitiesProps>({
		tags: [],
		sources: [],
		bibleBooks: [],
		linksFromCorpus: []
	})
	const [articleEditionGetOld, setArticleEditionGetOld] = useState<ArticleEditionProps>({
		nb: null, name: "", html: "", isDeadLink: false, thumbnail: "",
		duration: "", tags: [], source: null, sourceUrl: "", references: [],
		triggers: [], nextMessageId: "",
		recommendationsInfos: createEmptyRecommendationsInfosProps(),
		plannerAction: createEmptyPlannerActionProps(),
		description: "", descriptions: []
	})
	const [nb, setNb] = useState<number | null>(null)
	const [name, setName] = useState<string>("")
	const [html, setHtml] = useState<string>("")
	const [isDeadLink, setIsDeadLink] = useState<boolean>(false)
	const [thumbnail, setThumbnail] = useState<string>("")
	const [duration, setDuration] = useState<string>("")
	const [tags, setTags] = useState<string[]>([])
	const [tagsStr, setTagsStr] = useState("")
	const [selectedSource, setSelectedSource] = useState<SelectEltProps | null>(null)
	const [sourceUrl, setSourceUrl] = useState<string>("")
	const [references, setReferences] = useState<ReferenceEditionProps[]>([])
	const [referencesStr, setReferencesStr] = useState("")
	const [triggers, setTriggers] = useState<string[]>([])
	const [triggersStr, setTriggersStr] = useState("")
	const [nextMessageId, setNextMessageId] = useState<string>("")
	const [recommendationsInfos, setRecommendationsInfos] = useState<RecommendationsInfosProps>(createEmptyRecommendationsInfosProps())
	const [recommendationsInfosStr, setRecommendationsInfosStr] = useState("")
	const [plannerAction, setPlannerAction] = useState<PlannerActionProps>(createEmptyPlannerActionProps())
	const [plannerActionStr, setPlannerActionStr] = useState("")
	const [description, setDescription] = useState<string>("")

	const articleEdition = useMemo(() => new ArticleEdition({
		nb, name, html, isDeadLink, thumbnail, duration, tags, source: selectedSource,
		sourceUrl, references, triggers, nextMessageId, recommendationsInfos, plannerAction,
		description, descriptions:[]
	},
		setNb, setName, setHtml, setIsDeadLink, setThumbnail, setDuration, setTags, setTagsStr, setSelectedSource, setSourceUrl,
		setReferences, setReferencesStr, setTriggers, setTriggersStr, setNextMessageId, setRecommendationsInfos, setRecommendationsInfosStr,
		setPlannerAction, setPlannerActionStr, setDescription),
		[nb, name, html, isDeadLink, thumbnail, duration, tags, selectedSource, sourceUrl, references, triggers,
			nextMessageId, recommendationsInfos, plannerAction, description]);
	const [showThumbnailAdditionModal, setShowThumbnailAdditionModal] = React.useState(false)

	useEffect(() => {
		if (pageFound) {
			if (articleId === "" ||
				JSON.stringify(articleEditionGetOld) !== JSON.stringify(articleEdition.value)) {
				setModificationDone(true)
				setCanBeValidated(
					articleEdition.value.name.length > 0 &&
					articleEdition.value.html.length > 0 &&
					articleEdition.value.tags.length > 0 &&
					articleEdition.value.source !== null)
			} else {
				setModificationDone(false)
				setCanBeValidated(false)
			}
		}
	}, [nb, name, html, isDeadLink, thumbnail, duration, tagsStr, selectedSource, sourceUrl,
		referencesStr, triggersStr, nextMessageId, recommendationsInfosStr,
		plannerActionStr, description,
		pageFound, articleId, articleEdition.value, articleEditionGetOld]);

	const [fetched, setFetched] = useState(false)
	useEffect(() => {
		if (!fetched) {
			setFetched(true)
			setPageFound(undefined)
			fetch(hcContext.backendUrl + "/article_edit_get_page_json?l=" + language + "&id=" + articleId)
				.then(res => res.json())
				.then(jsonData => {
					setArticlePossibilities(jsonData.possibilities)
					if (articleId !== "") {
						jsonData.article.isDeadLink = jsonData.article.isDeadLink === "true"
						if (jsonData.article.tags === "")
							jsonData.article.tags = []
						if (jsonData.article.references === "")
							jsonData.article.references = []
						if (jsonData.article.triggers === "")
							jsonData.article.triggers = []
						jsonData.article.descriptions = [] // For retro compatiblity only
						if (jsonData.article.recommendationsInfos.recommendations === "")
							jsonData.article.recommendationsInfos.recommendations = []
						setArticleEditionGetOld(jsonData.article)
						articleEdition.set(jsonData.article)
						hcContext.setDocumentTitle(jsonData.article.name + " - Edition")
					}
					else {
						hcContext.setDocumentTitle("Ajout d'un article")
					}
					setPageFound(true)
				})
				.catch(rejected => {
					hcContext.setInfo(createErrorModalContent(language, rejected.message))
					setPageFound(false)
				});
		}
	}, [fetched, hcContext, language, articleId, articleEdition])


	function sendArticleModification() {
		if (!hcContext.userToken)
			return
		setProcessingModifications(true)
		hcContext.setBlackCoverOfContent(true)
		const postContent = new ArticleModificationPostContentType(language, hcContext.userToken, parentFolders,
			currentFolderName, articleId, articleEditionGetOld, articleEdition.value)
		const postContentStr = JSON.stringify(postContent)
		fetch(hcContext.backendUrl + "/modify_article", {
			method: 'POST',
			body: postContentStr,
			headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
		})
			.then(res => res.json())
			.then(jsonData => {
				setProcessingModifications(false)
				hcContext.setBlackCoverOfContent(false)
				if (jsonData.errorMessage) {
					hcContext.setInfo(createErrorModalContent(language, jsonData.errorMessage))
					return
				}
				navigate('/' + language + '/' + jsonData.id)
			})
			.catch(rejected => {
				setProcessingModifications(false)
				hcContext.setBlackCoverOfContent(false)
				hcContext.setInfo(createErrorModalContent(language, rejected.message))
			});
	}

	return (
		<>
			{
				processingModifications &&
				<Centered>
					<div style={getWaitPopupStyle(true, 50)}>
						<img src={image_wait} alt="wait" height="100%" width="100%" />
					</div>
				</Centered>
			}
			{pageFound === true ?
				<>
					<table width='100%'>
						<tbody>
							<tr>
								<td width={(30 + 15) * 2 + 45} style={{ paddingTop: 20 }}>
									{
										canBeValidated &&
										!processingModifications &&
										<button onClick={sendArticleModification}>
											<GetHtmlStrLocalized language={language} textId="modify" />
										</button>
									}
								</td>
								<td>
									<H1Title>
										<VerticalDisplay
											elts={[
												{
													component: <></>,
													width: '50%'
												},
												{
													component: <EditionLabel paddingTop={0} language={language} textId="number" />,
													width: '140px',
													paddingRight: '20px'
												},
												{
													component: <InputWithStyle value={articleEdition.value.nb?.toString()}
														onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
															articleEdition.setNb(Number(event.target.value))
														}} isDarkTheme={hcContext.isDarkTheme} height={hcContext.defaultLineEditHeight()} width='100px' />,
													width: '100px',
													paddingRight: '50px'
												},
												{
													component: <>{
														articleEdition.value.name.length > 0 ?
															<EditionLabel paddingTop={0} language={language} textId="name" />
															:
															<EditionLabel paddingTop={0} language={language} textId="name" color="red" />
													}</>,
													width: '40px',
													paddingRight: '20px'
												},
												{
													component: <InputWithStyle value={articleEdition.value.name}
														onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
															articleEdition.setName(event.target.value)
														}} isDarkTheme={hcContext.isDarkTheme} height={hcContext.defaultLineEditHeight()} width='500px' />,
													width: '500px',
													paddingRight: '20px'
												},
												{
													component: <></>,
													width: '50%'
												}
											]}
										/>
									</H1Title>
								</td>
								{
									(hcContext.userAsRightsToModifyTheContent && !processingModifications) ?
										<td width={(30 + 15) * 2 + 45} style={{ paddingTop: 20 }} className="hc-not-very-short-screen">
											{
												(modificationDone || articleId === "") ?
													<>
														{
															canBeValidated ?
																<>
																	<Button btnData={{ type: ButtonType.YES, onClick: sendArticleModification }} language={language} altId="modify" isDarkTheme={hcContext.isDarkTheme} />
																	<HorizontalSpace />
																</>
																:
																<>
																	<HorizontalSpace width={30} />
																	<HorizontalSpace />
																</>
														}
													</>
													:
													<>
														<Button btnData={{ type: ButtonType.DELETE, onClick: () => setShowCanDeleteModifictionsModal(true) }} language={language} isDarkTheme={hcContext.isDarkTheme} />
														<HorizontalSpace />
													</>
											}
											<Button btnData={{ type: ButtonType.NO, onClick: () => modificationDone ? setShowCanCancelModifictionsModal(true) : navigate(lastPath) }} language={language} altId="cancel" isDarkTheme={hcContext.isDarkTheme} />
											<HorizontalSpace />
										</td>
										:
										<td width={(30 + 15) * 2 + 45} className="hc-not-very-short-screen"></td>
								}
							</tr>
						</tbody>
					</table>
					<br />

					<div style={{ marginLeft: 20, marginRight: 20 }}>
						<table style={{ width: "100%" }}>
							<thead className="visually-hidden">
								<tr>
									<th>Main Content</th>
									<th>Additional Information</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td style={{ width: "50%", paddingRight: 20 }}>
										<HcMessageInput language={language} hcContext={hcContext} titleId="message" htmlText={articleEdition.value.html} setHtmlText={articleEdition.setHtml} />
										<br />
										<input type="checkbox" checked={isDeadLink} onChange={(event: ChangeEvent<HTMLInputElement>) => {
											articleEdition.setIsDeadLink(event.target.checked)
										}
										} />
										<GetHtmlStrLocalized language={language} textId="pointsToADeadLink" />
										<br />
										<br />
										<table>
											<thead className="visually-hidden">
												<tr>
													<th>Label</th>
													<th>Spacer</th>
													<th>Button</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td>
														<EditionLabel paddingTop={0} language={language} textId="thumbnail" />
													</td>
													<td style={{ textAlign: 'center', width: '100%' }}></td>
													<td style={{ paddingRight: '15px' }}>
														{
															articleEdition.value.thumbnail.length === 0 ?
																<Button btnData={{
																	type: ButtonType.ADD, onClick: () => {
																		setShowThumbnailAdditionModal(true)
																	}
																}} language={language} isDarkTheme={hcContext.isDarkTheme} />
																:
																<Button btnData={{
																	type: ButtonType.EDIT, onClick: () => {
																		setShowThumbnailAdditionModal(true)
																	}
																}} language={language} isDarkTheme={hcContext.isDarkTheme} />
														}
													</td>
												</tr>
											</tbody>
										</table>
										{
											articleEdition.value.thumbnail.length > 0 &&
											<BigButtonWithTextAtBottom link="" image={imgToFirebaseUrl2("buttons/" + articleEdition.value.thumbnail)} duration="" title={articleEdition.value.thumbnail} tags=""
												beginOfClassName="hc-big-button-normal" onClick={() => { }} hcContext={hcContext} />
										}
										<br />
										<br />

										<VerticalDisplay
											elts={[
												{
													component: <EditionLabel language={language} textId="duration" />,
													width: '140px',
													paddingRight: '20px'
												},
												{
													component: <InputWithStyle value={articleEdition.value.duration}
														onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
															articleEdition.setDuration(event.target.value)
														}} isDarkTheme={hcContext.isDarkTheme} height={hcContext.defaultLineEditHeight()} width='100px' />
												}
											]}
										/>
										<br />
										<br />

										<TagsListViewer
											language={language}
											tags={articleEdition.value.tags}
											setTags={(t) => { articleEdition.setTagsFn(t) }}
											allPossibleTags={articlePossibilities.tags}
											hcContext={hcContext}
										/>
										<br />
										<br />

										<SourceSelectWithUrl language={language} hcContext={hcContext}
											source={articleEdition.value.source} setSource={articleEdition.setSource}
											sourceUrl={articleEdition.value.sourceUrl} setSourceUrl={articleEdition.setSourceUrl}
											sourcesPossibilities={articlePossibilities.sources} setSourcesPossibilities={(sources) => {
												const newArticlePossibilities = articlePossibilities
												newArticlePossibilities.sources = sources
												setArticlePossibilities(newArticlePossibilities)
											}} />

										<br />
										<br />
										<ReferencesListViewer
											language={language}
											references={articleEdition.value.references}
											setReferences={(r) => { articleEdition.setRefs(r) }}
											bibleBooks={articlePossibilities.bibleBooks}
											linksFromCorpus={articlePossibilities.linksFromCorpus}
											hcContext={hcContext}
											articleId={articleId}
										/>
									</td>
									<td style={{ width: "50%", paddingLeft: 20 }}>
										<TriggersListViewer
											language={language}
											triggers={articleEdition.value.triggers}
											setTriggers={(t) => { articleEdition.setTriggersFn(t) }}
											isDarkTheme={hcContext.isDarkTheme}
										/>

										<br />
										<br />
										<table>
											<tbody>
												<tr>
													<td style={{ width: '50%' }}>
														<EditionLabel language={"fr"} textId="followedByTheMessage" />
													</td>
													<td style={{ textAlign: 'center', width: '50%' }}></td>
													{
														articleEdition.value.nextMessageId !== "" ?
															<td style={{ paddingRight: '15px' }}><Button btnData={{
																type: ButtonType.DELETE, onClick: () => {
																	articleEdition.setNextMessageId("")
																}
															}} language={language} isDarkTheme={hcContext.isDarkTheme} /></td>
															:
															<td style={{ paddingRight: '15px' }}><Button btnData={{
																type: ButtonType.ADD, onClick: () => {
																	setShowNextMessageIdSelectionModal(true)
																}
															}} language={language} isDarkTheme={hcContext.isDarkTheme} /></td>
													}
												</tr>
											</tbody>
										</table>
										{
											articleEdition.value.nextMessageId !== "" &&
											<>
												{articleEdition.value.nextMessageId}
											</>
										}

										<br />
										<br />
										<RecommendationsListViewer
											language={language}
											backendUrl={hcContext.backendUrl}
											recommendationsInfos={articleEdition.value.recommendationsInfos}
											setRecommendationsInfos={(rec) => articleEdition.setRecommendationsInfosFn(rec)}
											hcContext={hcContext}
										/>

										<br />
										<br />
										<EditionLabel language={"fr"} textId="planning" />
										<VerticalDisplay
											elts={[
												{
													component: <SubEditionLabel language={language} textId="preferInContext" />,
													width: '210px',
													paddingRight: '20px'
												},
												{
													component: <InputWithStyle value={articleEdition.value.plannerAction.preferInContext}
														onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
															articleEdition.value.plannerAction.preferInContext = event.target.value
															articleEdition.setPlannerActionFn(articleEdition.value.plannerAction)
														}} isDarkTheme={hcContext.isDarkTheme} height={hcContext.defaultLineEditHeight()} />,
													paddingRight: '20px'
												}
											]}
										/>
										<VerticalDisplay
											elts={[
												{
													component: <SubEditionLabel language={language} textId="precondition" />,
													width: '210px',
													paddingRight: '20px'
												},
												{
													component: <InputWithStyle value={articleEdition.value.plannerAction.precondition}
														onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
															articleEdition.value.plannerAction.precondition = event.target.value
															articleEdition.setPlannerActionFn(articleEdition.value.plannerAction)
														}} isDarkTheme={hcContext.isDarkTheme} height={hcContext.defaultLineEditHeight()} />,
													paddingRight: '20px'
												}
											]}
										/>
										<VerticalDisplay
											elts={[
												{
													component: <SubEditionLabel language={language} textId="possibleEffect" />,
													width: '210px',
													paddingRight: '20px'
												},
												{
													component: <InputWithStyle value={articleEdition.value.plannerAction.potentialEffect}
														onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
															articleEdition.value.plannerAction.potentialEffect = event.target.value
															articleEdition.setPlannerActionFn(articleEdition.value.plannerAction)
														}} isDarkTheme={hcContext.isDarkTheme} height={hcContext.defaultLineEditHeight()} />,
													paddingRight: '20px'
												}
											]}
										/>
										<VerticalDisplay
											elts={[
												{
													component: <SubEditionLabel language={language} textId="effect" />,
													width: '210px',
													paddingRight: '20px'
												},
												{
													component: <InputWithStyle value={articleEdition.value.plannerAction.effect}
														onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
															articleEdition.value.plannerAction.effect = event.target.value
															articleEdition.setPlannerActionFn(articleEdition.value.plannerAction)
														}} isDarkTheme={hcContext.isDarkTheme} height={hcContext.defaultLineEditHeight()} />,
													paddingRight: '20px'
												}
											]}
										/>
										<VerticalDisplay
											elts={[
												{
													component: <SubEditionLabel language={language} textId="goalsToAdd" />,
													width: '210px',
													paddingRight: '20px'
												},
												{
													component: <InputWithStyle value={articleEdition.value.plannerAction.goalsToAdd}
														onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
															articleEdition.value.plannerAction.goalsToAdd = event.target.value
															articleEdition.setPlannerActionFn(articleEdition.value.plannerAction)
														}} isDarkTheme={hcContext.isDarkTheme} height={hcContext.defaultLineEditHeight()} />,
													paddingRight: '20px'
												}
											]}
										/>

										<br />
										<br />
										<EditionLabel language={language} textId="description" />
										<br />
										<textarea className='form-control' style={{ width: "100%", height: "250px" }} value={articleEdition.value.description} onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => { articleEdition.setDescription(event.target.value); }} />
									</td>
								</tr>
							</tbody>
						</table>

					</div>
				</>
				:
				<>
					<br />
					<br />
					{pageFound === false &&
						<div className='hc-content-text' style={{ textAlign: 'center', margin: '0 auto' }}>
							<GetHtmlStrLocalized language={language} textId="sorryThisPageIsNotAvailable" />
						</div>
					}
				</>
			}

			<YesNoModal language={language} show={showCanCancelModifictionsModal} setShow={setShowCanCancelModifictionsModal}
				isDarkTheme={hcContext.isDarkTheme}
				title={GetStrLocalized(language, "cancellationConfirmation")}
				text={GetStrLocalized(language, "areYouSureToUndotheChanges")}
				onYes={() => navigate(lastPath)} />

			<YesNoModal language={language} show={showCanDeleteModifictionsModal} setShow={setShowCanDeleteModifictionsModal}
				isDarkTheme={hcContext.isDarkTheme}
				title={GetStrLocalized(language, "removalConfirmation")}
				text={GetStrLocalized(language, "areYouSureToPermanentlyDeletethisMessage")}
				onYes={() => removeItem(articleId, "article", hcContext, language, setProcessingModifications, navigate)} />

			<SelectImageModal show={showThumbnailAdditionModal} setShow={setShowThumbnailAdditionModal}
				language={language}
				beginOfPathImage="buttons"
				onImageSelected={(s: string) => {
					articleEdition.setThumbnail(s)
				}}
				hcContext={hcContext} />

			<ArticleSelectorModal show={showNextMessageIdSelectionModal} setShow={setShowNextMessageIdSelectionModal}
				language={language} backendUrl={hcContext.backendUrl} articleSelected={(id) => articleEdition.setNextMessageId(id)} hcContext={hcContext} />
		</>
	)
}

export default ArticleModifier
