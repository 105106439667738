import * as React from "react";
import EditionLabel from "../title/EditionLabel";
import Button from "../button/Button";
import { ButtonType } from "../../props/buttondataprops";
import GetStrLocalized from "../../datas/GetStrLocalized";
import Modal, { ModalBody, ModalFooter, ModalHeader } from "../modal/Modal";
import GetHtmlStrLocalized from "../../datas/GetHtmlStrLocalized";
import { InsideBookRangesProps, ReferenceEdition, ReferenceEditionProps, createEmptyInsideBookRangesProps } from "../../props/ReferenceEditionProps";
import ReferenceEditionModal from "../modal/ReferenceEditionModal";
import { BibleBookProps } from "../../props/BibleBookProps";
import { HcContext, SelectEltProps } from "../../types/hccontext";



interface ReferencesListViewerProps {
  language: string;
  references: ReferenceEditionProps[];
  setReferences: (r: ReferenceEditionProps[]) => any;
  bibleBooks: BibleBookProps[];
  linksFromCorpus: SelectEltProps[];
  hcContext: HcContext;
  articleId: string;
}

export default function ReferencesListViewer(
  { language, references, setReferences, bibleBooks, linksFromCorpus, hcContext, articleId }: Readonly<ReferencesListViewerProps>
) {
  const [selectedReference, setSelectedReference] = React.useState<number | undefined>()
  const [showSelectBookModal, setShowSelectBookModal] = React.useState(false);
  const [exportModal, setExportModal] = React.useState(false);
  const emptyRef = { type: "book_scope", bookScope: "bible", book: "", insideBookRanges: [createEmptyInsideBookRangesProps()], linkFromCorpus: "normal", sourceId: "", url: "", str: "" }

  const [refEditionType, setRefEditionType] = React.useState<string>("");
  const [refEditionBookScope, setRefEditionBookScope] = React.useState<string>("");
  const [refEditionBook, setRefEditionBook] = React.useState<string>("");
  const [refEditionInsideBookRange, setRefEditionInsideBookRange] = React.useState<InsideBookRangesProps[]>([]);
  const [refEditionLinkFromCorpus, setRefEditionLinkFromCorpus] = React.useState<string>("");
  const [refEditionUrl, setRefEditionUrl] = React.useState<string>("")
  const [refEditionStr, setRefEditionStr] = React.useState<string>("")
  const [addOrEditReference, setAddOrEditReference] = React.useState<boolean>(true);
  const refEdition = new ReferenceEdition({
    type: refEditionType, bookScope: refEditionBookScope, book: refEditionBook,
    insideBookRanges: refEditionInsideBookRange, linkFromCorpus: refEditionLinkFromCorpus, url: refEditionUrl,
    str: refEditionStr
  },
    setRefEditionType, setRefEditionBookScope, setRefEditionBook,
    setRefEditionInsideBookRange, setRefEditionLinkFromCorpus, setRefEditionUrl, setRefEditionStr);
  const [importExportReferences, setImportExportReferences] = React.useState<string>("");

  function swapReferences(firstIndex: number, secondIndex: number) {
    let refCopied = references;
    const temp = refCopied[firstIndex];
    refCopied[firstIndex] = refCopied[secondIndex];
    refCopied[secondIndex] = temp;
    setReferences(refCopied)
  }


  function printRefProps(refProps: ReferenceEditionProps): string {
    if (refProps.type == "book_scope") {
      let res = refProps.bookScope + ": " + refProps.str
      if (refProps.linkFromCorpus !== "" && refProps.linkFromCorpus !== "normal")
        res += " (" + GetStrLocalized(language, "linkFromCorpus_" + refProps.linkFromCorpus) + ")"
      return res
    }
    return "site: " + refProps.str
  }

return (
    <>
      <table>
        <tbody>
          <tr>
            <td><EditionLabel language={"fr"} textId="references" /></td>
            <td style={{ textAlign: 'center', width: '100%' }}></td>
            {
              selectedReference !== undefined &&
              <>
                {
                  references.length > 1 &&
                  <>
                    <td style={{ paddingRight: '15px' }}><Button btnData={{
                      type: ButtonType.TOP, onClick: () => {
                        if (selectedReference > 0) {
                          swapReferences(selectedReference - 1, selectedReference)
                          setSelectedReference(selectedReference - 1)
                        }
                      }
                    }} language={language} alt={GetStrLocalized(language, "move_up")} isDarkTheme={hcContext.isDarkTheme} /></td>
                    <td style={{ paddingRight: '15px' }}><Button btnData={{
                      type: ButtonType.DOWN, onClick: () => {
                        if (selectedReference < references.length - 1) {
                          swapReferences(selectedReference, selectedReference + 1)
                          setSelectedReference(selectedReference + 1)
                        }
                      }
                    }} language={language} alt={GetStrLocalized(language, "move_down")} isDarkTheme={hcContext.isDarkTheme} /></td>
                  </>
                }
                <td style={{ paddingRight: '15px' }}><Button btnData={{
                  type: ButtonType.EDIT, onClick: () => {
                    setAddOrEditReference(false)
                    refEdition.set(references[selectedReference])
                    setShowSelectBookModal(true)
                  }
                }} language={language} isDarkTheme={hcContext.isDarkTheme} /></td>
                <td style={{ paddingRight: '15px' }}><Button btnData={{
                  type: ButtonType.DELETE, onClick: () => {
                    var refCopied = references;
                    refCopied.splice(selectedReference, 1)
                    setReferences(refCopied)
                    setSelectedReference(undefined)
                  }
                }} language={language} isDarkTheme={hcContext.isDarkTheme} /></td>
              </>
            }
            <td style={{ paddingRight: '15px' }}><Button btnData={{
              type: ButtonType.ADD, onClick: () => {
                setAddOrEditReference(true)
                refEdition.set(emptyRef)
                setShowSelectBookModal(true)
              }
            }} language={language} isDarkTheme={hcContext.isDarkTheme} /></td>
            <td><Button btnData={{
              type: ButtonType.EXPORT, onClick: () => {
                setImportExportReferences(JSON.stringify(references))
                setExportModal(true)
              }
            }} language={language} isDarkTheme={hcContext.isDarkTheme} /></td>
          </tr>
        </tbody>
      </table>
      <ul style={{ listStyleType: "none", cursor: 'pointer' }}>
        {references.map((item, index) => {
          return <li key={"refrence-list-elt-" + index} onClick={() => setSelectedReference(index)} >
            {
              selectedReference == index ?
                <b>{printRefProps(item)}</b>
                :
                printRefProps(item)
            }
          </li>;
        })}
      </ul>

      <ReferenceEditionModal
        language={language}
        addOrEdit={addOrEditReference}
        show={showSelectBookModal}
        setShow={setShowSelectBookModal}
        refEdition={refEdition}
        onReferenceValidated={(elt) => {
          if (addOrEditReference) {
            setReferences([...references, elt])
            setSelectedReference(undefined)
          }
          else {
            let cpRefs = references
            cpRefs[selectedReference!!] = elt
            setReferences(cpRefs)
          }
        }}
        bibleBooks={bibleBooks}
        linksFromCorpus={linksFromCorpus}
        hcContext={hcContext}
        articleId={articleId}
      />
      <Modal
        language={language}
        show={exportModal}
        setShow={setExportModal}
        isDarkTheme={hcContext.isDarkTheme}
      >
        <ModalHeader>
          <h3>Import / Export</h3>
        </ModalHeader>
        <ModalBody>
          <textarea style={{ width: "100%", height: "150px" }} value={importExportReferences} onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => { setImportExportReferences(event.target.value); }} />
        </ModalBody>
        <br />
        <ModalFooter>
          <button onClick={() => {
            setReferences(JSON.parse(importExportReferences))
            setExportModal(false)
          }}>
            <GetHtmlStrLocalized language={language} textId="validate" />
          </button>
        </ModalFooter>
      </Modal>
    </>

  );
}