
import '../assets/resources/custom/style/main-6.css'
import React, { useEffect } from 'react'
import GetInnerHtmlStrLocalized from '../datas/GetInnerHtmlStrLocalized'
import PageContent from '../components/util/PageContent'
import H1Title from '../components/title/H1Title';


interface ApiProps {
    language: string;
    setDocumentTitle: React.Dispatch<React.SetStateAction<string>>;
};


function Api({ language, setDocumentTitle }: Readonly<ApiProps>) {

    useEffect(() => {
        setDocumentTitle("API - Holy Chatter");
    }, [setDocumentTitle, language]);

    return (
        <PageContent>
            <H1Title>API</H1Title>
            <br /><br />

            <div className='hc-content-text hc-content-text-paddings'>
                <GetInnerHtmlStrLocalized language={language} textId="apiHolyChatterContent" />
            </div>
        </PageContent>
    )
}

export default Api
