import * as React from "react";
import EditionLabel from "../title/EditionLabel";
import Button from "../button/Button";
import { ButtonType } from "../../props/buttondataprops";
import GetStrLocalized from "../../datas/GetStrLocalized";
import TextEditionModal from "../modal/TextEditionModal";


interface TextsListViewerProps {
  language: string;
  titleId: string;
  descriptions: string[];
  setDescriptions: (r: string[]) => any;
  isDarkTheme: boolean;
}

export default function TextsListViewer(
  { language, titleId, descriptions, setDescriptions, isDarkTheme }: Readonly<TextsListViewerProps>
) {
  const [selectedElt, setSelectedElt] = React.useState<number | undefined>()
  const [showModal, setShowModal] = React.useState(false)
  const [addOrEdit, setAddOrEdit] = React.useState<boolean>(true)
  const [descEdition, setDescEdition] = React.useState<string>("")

  function swapElts(firstIndex: number, secondIndex: number) {
    let eltsCopied = descriptions;
    const temp = eltsCopied[firstIndex];
    eltsCopied[firstIndex] = eltsCopied[secondIndex];
    eltsCopied[secondIndex] = temp;
    setDescriptions(eltsCopied)
  }

  return (
    <>
      <table style={{ width: '100%' }}>
        <tbody>
          <tr>
            <td style={{ width: '300px' }}>
              <EditionLabel language={"fr"} textId={titleId} />
            </td>
            <td style={{ textAlign: 'center' }}></td>
            {
              selectedElt !== undefined &&
              <>
                {
                  descriptions.length > 1 &&
                  <>
                    <td style={{ paddingRight: '15px', width: '45px' }}><Button btnData={{
                      type: ButtonType.TOP, onClick: () => {
                        if (selectedElt > 0) {
                          swapElts(selectedElt - 1, selectedElt)
                          setSelectedElt(selectedElt - 1)
                        }
                      }
                    }} language={language} alt={GetStrLocalized(language, "move_up")} isDarkTheme={isDarkTheme} /></td>
                    <td style={{ paddingRight: '15px', width: '45px' }}><Button btnData={{
                      type: ButtonType.DOWN, onClick: () => {
                        if (selectedElt < descriptions.length - 1) {
                          swapElts(selectedElt, selectedElt + 1)
                          setSelectedElt(selectedElt + 1)
                        }
                      }
                    }} language={language} alt={GetStrLocalized(language, "move_down")} isDarkTheme={isDarkTheme} /></td>
                  </>
                }
                <td style={{ paddingRight: '15px', width: '45px' }}><Button btnData={{
                  type: ButtonType.EDIT, onClick: () => {
                    setAddOrEdit(false)
                    setDescEdition(descriptions[selectedElt])
                    setShowModal(true)
                  }
                }} language={language} isDarkTheme={isDarkTheme} /></td>
                <td style={{ paddingRight: '15px', width: '45px' }}><Button btnData={{
                  type: ButtonType.DELETE, onClick: () => {
                    var refCopied = descriptions;
                    refCopied.splice(selectedElt, 1)
                    setDescriptions(refCopied)
                    setSelectedElt(undefined)
                  }
                }} language={language} isDarkTheme={isDarkTheme} /></td>
              </>
            }
            <td style={{ paddingRight: '15px', width: '45px' }}><Button btnData={{
              type: ButtonType.ADD, onClick: () => {
                setAddOrEdit(true)
                setDescEdition("")
                setShowModal(true)
              }
            }} language={language} isDarkTheme={isDarkTheme} /></td>
          </tr>
        </tbody>
      </table>

      {
        descriptions.length > 0 &&
        <>
          {descriptions.map((item, index) => {
            return <div key={"desc-list-elt-" + index}><span style={{ listStyleType: "none", cursor: 'pointer' }} onClick={() => setSelectedElt(index)} >
              {
                selectedElt == index ?
                  <b>{item}</b>
                  :
                  <>{item}</>
              }
            </span></div>
          })}
        </>
      }

      <TextEditionModal
        language={language}
        titleId={addOrEdit ? "addADescription" : "modifyADescription"}
        show={showModal}
        setShow={setShowModal}
        isDarkTheme={isDarkTheme}
        text={descEdition}
        onTextValidated={(elt) => {
          if (addOrEdit) {
            setDescriptions([...descriptions, elt])
            setSelectedElt(undefined)
          }
          else {
            let cpTriggers = descriptions
            cpTriggers[selectedElt!!] = elt
            setDescriptions(cpTriggers)
          }
        }}
      />
    </>

  );
}