import '../assets/resources/custom/style/main-6.css'
import PageContent from '../components/util/PageContent'
import H1TitleId from '../components/title/H1TitleId'
import { Navigate, useLocation } from 'react-router-dom'
import { HcContext } from '../types/hccontext'
import { useState } from 'react'
import { ArticleTestProps } from '../types/ArticleTests'
import TestsListViewer from '../components/listview/TestsListViewer'
import Centered from '../components/util/Centered'
import image_wait from '../assets/resources/custom/icons/wait.gif'
import { getWaitPopupStyle } from '../components/util/GetWaitPopupStyle'
import GetStrLocalized from '../datas/GetStrLocalized'
import { createErrorModalContent } from '../props/ModalContentProps'


export enum TestsResultsType {
    SHOW_MODIFIED = "show_modifier",
    SHOW_NOTOK = "show_notok"
}

interface TestsResultsProps {
    language: string;
    hcContext: HcContext;
    testsResultsType: TestsResultsType;
}


function TestsResults({ language, hcContext, testsResultsType }: Readonly<TestsResultsProps>) {

    const location = useLocation();
    const [lastPath, setLastPath] = useState("")
    const [nbOfTests, setNbOfTests] = useState<number | undefined>(undefined)
    const [tests, setTests] = useState<ArticleTestProps[]>([])
    const [processingModifications, setProcessingModifications] = useState(false)
    const titleId = testsResultsType === TestsResultsType.SHOW_MODIFIED ? "testsResults" : "testsNotOk"
    const apiName = testsResultsType === TestsResultsType.SHOW_MODIFIED ? "tests_results_json" : "notok_tests_json"

    hcContext.setDocumentTitle(GetStrLocalized(language, titleId) + "Holy Chatter");

    if (location.pathname !== lastPath && hcContext.userAsRightsToModifyTheContent) {
        setLastPath(location.pathname);
        setProcessingModifications(true)
        hcContext.setBlackCoverOfContent(true)
        fetch(hcContext.backendUrl + "/" + apiName + "?l=" + language)
            .then(res => res.json())
            .then(jsonData => {
                setProcessingModifications(false)
                hcContext.setBlackCoverOfContent(false)
                if (jsonData.errorMessage) {
                    hcContext.setInfo(createErrorModalContent(language, jsonData.errorMessage))
                    return
                }
                if (testsResultsType === TestsResultsType.SHOW_MODIFIED)
                    setNbOfTests(jsonData.nbOfTests);
                else
                    setNbOfTests(undefined);
                if (jsonData.tests === "")
                    jsonData.tests = []
                setTests(jsonData.tests);
            })
            .catch(rejected => {
                setProcessingModifications(false)
                hcContext.setBlackCoverOfContent(false)
                hcContext.setInfo(createErrorModalContent(language, rejected.message))
            });
    }


    return (
        <PageContent>
            {
                !hcContext.userAsRightsToModifyTheContent &&
                <Navigate to={"/" + language} />
            }

            {
                processingModifications &&
                <Centered>
                    <div style={getWaitPopupStyle(true, 50)}>
                        <img src={image_wait} alt="wait" height="100%" width="100%" />
                    </div>
                </Centered>
            }
            <H1TitleId language={language} titleId={titleId} />
            <br />

            <TestsListViewer language={language} tests={tests} setTests={setTests} hcContext={hcContext}
                nbOfTestsTotal={nbOfTests} maxNbTestsToDisplay={30} setProcessingModifications={setProcessingModifications} />

        </PageContent>
    )
}

export default TestsResults
