import React from 'react'
import BigButtonWithTextAtBottom from './BigButtonWithTextAtBottom'
import { HcContext } from '../../types/hccontext';

interface ChapterProps {
    link: string;
    image: string;
	title: string;
  }

  interface ChaptersProps {
    chapters: ChapterProps[];
	hcContext: HcContext;
  };

function Chapters({chapters, hcContext}: Readonly<ChaptersProps>) {

	return (
		<div className="hc-chapters-left-margin">
		{
			chapters &&
			chapters.length > 0 &&
			<React.Fragment>
				{
					chapters.map((item, index) => {
						return (
							<BigButtonWithTextAtBottom key={"categories-btn-" + index} link={item.link} image={item.image} duration="" title={item.title} tags="" beginOfClassName="hc-big-button-chapter" hcContext={hcContext} />
						)
					})
				}
			</React.Fragment>
		}
	</div>
	)
}

export default Chapters