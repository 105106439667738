import React from 'react'
import Modal, { ModalBody, ModalFooter, ModalHeader } from './Modal'
import { HcContext } from '../../types/hccontext';
import GetHtmlStrLocalized from '../../datas/GetHtmlStrLocalized';
import GoogleLogin, { GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';


interface LoginModalProps {
    language: string;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    hcContext: HcContext;
    onLoggedIn?: () => any;
}

function LoginModal({ language, setShow, hcContext, onLoggedIn }: Readonly<LoginModalProps>) {

    const handleGoogleLogin = async (googleData: GoogleLoginResponseOffline | GoogleLoginResponse) => {
        if ("profileObj" in googleData) {
            const res = await fetch(hcContext.backendUrlNodeJS + "/api/v1/auth/google", {
                method: "POST",
                body: JSON.stringify({
                    token: googleData.tokenId
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            })
            const data = await res.json()
            hcContext.setNewUser(data.user, data.userToken)
            setShow(false)
            if (onLoggedIn)
                onLoggedIn()
        }
    }

    return (
        <Modal
            language={language}
            show={true}
            setShow={setShow}
            isDarkTheme={hcContext.isDarkTheme}
        >
            <ModalHeader>
                <h3><GetHtmlStrLocalized language={language} textId="signin" /></h3>
            </ModalHeader>
            <ModalBody>
                <GoogleLogin
                    clientId={process.env.REACT_APP_CLIENT_ID !== undefined ? process.env.REACT_APP_CLIENT_ID : ""}
                    onSuccess={handleGoogleLogin}
                    onFailure={err => {
                        console.log("Google login error: " + JSON.stringify(err))
                    }}
                    cookiePolicy="single_host_origin"
                />
            </ModalBody>
            <br />
            <ModalFooter>
                <button onClick={() => setShow(false)}>
                    <GetHtmlStrLocalized language={language} textId="close" />
                </button>
            </ModalFooter>
        </Modal>
    );
}

export default LoginModal;