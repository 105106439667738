
const englishStrings: { [name: string]: string } = {
    "categoriesFolderName": "categories",
    "reasonsToBelieveFolderName": "reasons-to-believe",
    "chatbotFolderName": "chatbot",
    "chatbotConfigFolderName": "chatbotConfig",
    "readingsOfTheDayFolderName": "readings-of-the-day",
    "gospelOfTheDayFolderName": "gospel-of-the-day",
    "gospelFolderName": "gospel",
    "readingsFolderName": "readings",
    "bibleFolderName": "bible",
    "oldTestamentFolderName": "old-testament",
    "psalmsFolderName": "Ps",
    "newTestamentFolderName": "new-testament",
    "catechismOfTheCatholicChurchFolderName": "catechism-of-the-catholic-church",
    "gospelsFolderName": "gospels",
    "sourcesFolderName": "sources",
    "glossaryFolderName": "glossary",
    "tagsFolderName": "tags",
    "factsFolderName": "facts",
    "aboutFolderName": "about",
    "apiFolderName": "api",
    "searchFolderName": "search",
    "christianMessageFolderName": "christian-message",
    "cmsFolderName": "cms",
    "testsFolderName": "tests",
    "historyFolderName": "history",
    "chatHistoryFolderName": "chat-history",
    "contentEditHistoryFolderName": "content-edit-history",
    "resultsFolderName": "results",
    "notOkFolderName": "not-ok",
    "prologueFolderName": "prologue",
    "theProfessionOfFaithFolderName": "the-profession-of-faith",
    "theCelebrationOfChristianMisteryFolderName": "the-celebration-of-the-christian-mystery",
    "lifeInChristFolderName": "life-in-christ",
    "christianPrayerFolderName": "christian-prayer",
    "textsOfThePopeFolderName": "texts-of-the-pope",
    "search": "Search",
    "close": "Close",
    "verse": "Verse",
    "verses": "Verses",
    "listen": "Listen",
    "stop": "Stop",
    "bible": "Bible",
    "catechismOfTheCatholicChurch": "Catechism of the Catholic Church",
    "site": "Site",
    "textsOfThePope": "Texts of the pope",
    "theSocialDoctrineOfTheChurchUrl": "http://www.vatican.va/roman_curia/pontifical_councils/justpeace/documents/rc_pc_justpeace_doc_20060526_compendio-dott-soc_en.html",
    "readings": "Readings",
    "oldTestament": "Old testament",
    "psalms": "Psalms",
    "newTestament": "New testament",
    "about": "About",
    "holyChatterSearch": "Holy Chatter Search",
    "readingsOfTheDay": "Readings of the day",
    "gospelOfTheDay": "Gospel of the day",
    "sources": "Sources",
    "christianMessage": "Christian message",
    "writeASentence": "Write a sentence",
    "facebookPage": "Facebook page",
    "youtubeChannel": "YouTube channel",
    "accessTheApi": "Access the API",
    "speakNow": "Speak now.",
    "left_alt": "Previous",
    "up_alt": "Parent",
    "down_alt": "Child",
    "right_alt": "Next",
    "edit_alt": "Edit",
    "emoji_alt": "Emoji",
    "gif_alt": "Gif",
    "html_alt": "Html",
    "delete_alt": "Delete",
    "test_alt": "Test",
    "add_alt": "Add",
    "bold_alt": "Bold",
    "italic_alt": "Italic",
    "see_alt": "See",
    "export_alt": "Import / Export",
    "yes_alt": "Yes",
    "no_alt": "No",
    "move_alt": "Move",
    "search_alt": "Search",
    "move_up": "Move up",
    "move_down": "Move down",
    "ok_alt": "Set as ok",
    "update_alt": "Update",
    "firstChapter": "First chapter",
    "lastChapter": "Last chapter",
    "firstVerse": "First verse",
    "lastVerse": "Last verse",
    "move_left": "Move left",
    "move_right": "Move right",
    "message": "Message",
    "folder": "Folder",
    "link": "Link",
    "chatbot": "Chatbot",
    "christianChatbot": "Christian chatbot",
    "questionForTheQuiz": "Question for the quiz",
    "cancellationConfirmation": "Cancellation confirmation",
    "areYouSureToUndotheChanges": "Are you sure to undo the changes?",
    "removalConfirmation": "Removal confirmation",
    "moveConfirmation": "Move confirmation",
    "areYouSureToPermanentlyDeletethisMessage": "Are you sure to permanently delete this message?",
    "areYouSureToPermanentlyDeletethisFolder": "Are you sure to permanently delete this folder?",
    "areYouSureToPermanentlyDeletethisChatbot": "Are you sure to permanently delete this chatbot?",
    "areYouSureToPermanentlyDeletethisSource": "Are you sure to permanently delete this source?",
    "areYouSureToPermanentlyDeletethisTag": "Are you sure to permanently delete this tag?",
    "areYouSureToPermanentlyDeletethisWord": "Are you sure to permanently delete this word?",
    "yourDevice": "your device",
    "existingImageOftheSite": "an existing image of the site",
    "error": "Error",
    "sorryThereIsAlreadyAnImageWithThatName": "Sorry, there is already an image with that name.",
    "cancel": "Cancel",
    "modify": "Modifier",
    "updateTheExpectedResult": "Update the expected result",
    "selectAllTests": "Select all tests",
    "testsResults": "Tests results",
    "testsNotOk": "Tests not ok",
    "tests": "Tests",
    "categories": "Categories",
    "sanityChecks": "Sanity checks",
    "thereIsnothingToNotify": "There is nothing to notify. :)",
    "tags": "Tags",
    "twoDots": ": ",
    "publicMsgs": "Public msgs",
    "hiddenMsgs": "Hidden msgs",
    "references": "References",
    "reference": "Reference",
    "factsForChatbotPlannification": "Facts for chatbot plannification",
    "linkFromCorpus_no": "No link from reference to this message",
    "linkFromCorpus_low": "Priority: Low",
    "linkFromCorpus_automatic": "Priority: Automatic",
    "linkFromCorpus_normal": "Priority: Normal",
    "linkFromCorpus_high": "Priority: High",
    "pressEscToClose": "Press Esc to close",
    "thankYouForYourVote": "Thank you for your vote",
    "thankYou": "Thank you",
    "history": "History",
    "chatHistory": "Chat history",
    "contentEditHistory": "Content edit history",
    "modificationType_add": "Adding",
    "modificationType_update": "Update",
    "modificationType_move": "Move",
    "modificationType_remove": "Removal",
    "modificationType_tests_update": "Tests update",
    "restore": "Restore",
    "view": "view",
    "views": "views",
    "glossary": "Glossary",
    "internalSearchFromThisDirectory": "Internal search from this directory",
    "useTheDefaultContent": "Use the default content",
    "infUseTheDefaultContent": "Use the default content",
    "fallbackEngine": "Fallback engine",
    "chatWithTheChatbot": "Chat with the chatbot",
    "bubbleBackgroundColor": "Bubble background color",
    "allowToJumpFromLocalMessageToDefaultRecommendations": "Allow to jump from local message to default recommendations",
    "useDefaultProactivity": "Use default proactivity",
    "showOption": "Show options",
    "sourcesNotToDisplay": "Sources not to display",
    "goals": "Goals",
    "toChangeTheSubject": "To change the subject",
    "liturgicalTime": "Liturgical time",
    "rightAnswer": "Right answer",
    "wrongAnswer": "Wrong answer",
    "suggestedResponses": "Suggested responses",
    "suggestedResponse": "Suggested response",
    "question": "Question"
};

const frenchStrings: { [name: string]: string } = {
    "categoriesFolderName": "categories",
    "reasonsToBelieveFolderName": "raisons-de-croire",
    "chatbotFolderName": "chatbot",
    "chatbotConfigFolderName": "chatbotConfig",
    "readingsOfTheDayFolderName": "lectures-du-jour",
    "gospelOfTheDayFolderName": "evangile-du-jour",
    "gospelFolderName": "evangile",
    "readingsFolderName": "lectures",
    "bibleFolderName": "bible",
    "oldTestamentFolderName": "ancien-testament",
    "psalmsFolderName": "Ps",
    "newTestamentFolderName": "nouveau-testament",
    "catechismOfTheCatholicChurchFolderName": "catechisme-de-l-eglise-catholique",
    "gospelsFolderName": "evangiles",
    "sourcesFolderName": "sources",
    "glossaryFolderName": "glossaire",
    "tagsFolderName": "tags",
    "factsFolderName": "faits",
    "aboutFolderName": "a-propos",
    "apiFolderName": "api",
    "searchFolderName": "rechercher",
    "christianMessageFolderName": "message-chretien",
    "cmsFolderName": "cms",
    "testsFolderName": "tests",
    "historyFolderName": "historique",
    "chatHistoryFolderName": "historique-du-chat",
    "contentEditHistoryFolderName": "historique-des-modifications-du-contenu",
    "resultsFolderName": "resultats",
    "notOkFolderName": "pas-ok",
    "textsOfThePopeFolderName": "textes-du-pape",
    "prologueFolderName": "prologue",
    "theProfessionOfFaithFolderName": "la-profession-de-foi",
    "theCelebrationOfChristianMisteryFolderName": "la-celebration-du-mystere-chretien",
    "lifeInChristFolderName": "la-vie-dans-le-christ",
    "christianPrayerFolderName": "la-priere-chretienne",
    "search": "Rechercher",
    "close": "Fermer",
    "verse": "Verset",
    "verses": "Versets",
    "listen": "Écouter",
    "stop": "Arrêter",
    "bible": "Bible",
    "catechismOfTheCatholicChurch": "Catéchisme de l'Église catholique",
    "site": "Site",
    "textsOfThePope": "Textes du pape",
    "theSocialDoctrineOfTheChurchUrl": "http://www.vatican.va/roman_curia/pontifical_councils/justpeace/documents/rc_pc_justpeace_doc_20060526_compendio-dott-soc_fr.html",
    "readings": "Lectures",
    "oldTestament": "Ancien testament",
    "psalms": "Psaumes",
    "newTestament": "Nouveau testament",
    "about": "A propos",
    "holyChatterSearch": "Recherche Holy Chatter",
    "readingsOfTheDay": "Lectures du jour",
    "gospelOfTheDay": "Évangile du jour",
    "sources": "Sources",
    "christianMessage": "Message chrétien",
    "writeASentence": "Écrire une phrase",
    "facebookPage": "Page Facebook",
    "youtubeChannel": "Chaîne YouTube",
    "accessTheApi": "Accéder à l'API",
    "speakNow": "Parlez maintenant.",
    "left_alt": "Précédant",
    "up_alt": "Parent",
    "down_alt": "Fils",
    "right_alt": "Suivant",
    "edit_alt": "Modifier",
    "emoji_alt": "Emoji",
    "gif_alt": "Gif",
    "html_alt": "Html",
    "delete_alt": "Supprimer",
    "test_alt": "Tester",
    "add_alt": "Ajouter",
    "bold_alt": "Gras",
    "italic_alt": "Italique",
    "see_alt": "Voir",
    "export_alt": "Importer / Exporter",
    "yes_alt": "Oui",
    "no_alt": "Non",
    "move_alt": "Déplacer",
    "search_alt": "Rechercher",
    "move_up": "Monter",
    "move_down": "Descendre",
    "ok_alt": "Marquer comme ok",
    "update_alt": "Mettre à jour",
    "firstChapter": "Premier chapitre",
    "lastChapter": "Dernier chapitre",
    "firstVerse": "Premier verset",
    "lastVerse": "Dernier verset",
    "move_left": "Déplacer vers la gauche",
    "move_right": "Déplacer vers la droite",
    "message": "Message",
    "folder": "Dossier",
    "link": "Lien",
    "chatbot": "Chatbot",
    "christianChatbot": "Chatbot chrétien",
    "questionForTheQuiz": "Question pour le quiz",
    "cancellationConfirmation": "Confirmation d'annulation",
    "areYouSureToUndotheChanges": "Êtes-vous sûr de vouloir annuler les modifications ?",
    "removalConfirmation": "Confirmation de suppression",
    "moveConfirmation": "Confirmation de déplacement",
    "areYouSureToPermanentlyDeletethisMessage": "Êtes-vous sûr de vouloir supprimer définitivement ce message ?",
    "areYouSureToPermanentlyDeletethisFolder": "Êtes-vous sûr de vouloir supprimer définitivement ce dossier ?",
    "areYouSureToPermanentlyDeletethisChatbot": "Êtes-vous sûr de vouloir supprimer définitivement ce chatbot ?",
    "areYouSureToPermanentlyDeletethisSource": "Êtes-vous sûr de vouloir supprimer définitivement cette source ?",
    "areYouSureToPermanentlyDeletethisTag": "Êtes-vous sûr de vouloir supprimer définitivement ce tag ?",
    "areYouSureToPermanentlyDeletethisWord": "Êtes-vous sûr de vouloir supprimer définitivement ce mot ?",
    "yourDevice": "votre appareil",
    "existingImageOftheSite": "une image existante du site",
    "error": "Erreur",
    "sorryThereIsAlreadyAnImageWithThatName": "Désolé, il y a déjà une image qui porte ce nom.",
    "cancel": "Annuler",
    "modify": "Modifier",
    "updateTheExpectedResult": "Mettre à jour le résultat attendu",
    "selectAllTests": "Sélectionner tous les tests",
    "testsResults": "Résultats des tests",
    "testsNotOk": "Tests pas ok",
    "tests": "Tests",
    "categories": "Catégories",
    "sanityChecks": "Contrôles de santé",
    "thereIsnothingToNotify": "Il n'y a rien a signaler. :)",
    "tags": "Tags",
    "twoDots": " : ",
    "publicMsgs": "Msgs publics",
    "hiddenMsgs": "Msgs cachées",
    "references": "Références",
    "reference": "Référence",
    "factsForChatbotPlannification": "Faits pour la planification des chatbots",
    "linkFromCorpus_no": "Pas de lien de la référence vers ce message",
    "linkFromCorpus_low": "Priorité : Basse",
    "linkFromCorpus_automatic": "Priorité: Automatique",
    "linkFromCorpus_normal": "Priorité : Normale",
    "linkFromCorpus_high": "Priorité : Haute",
    "pressEscToClose": "Appuyer sur Échap pour fermer",
    "thankYouForYourVote": "Merci pour votre vote qui a bien été pris en compte. Vous pouvez le changer à tout moment.",
    "thankYou": "Merci",
    "history": "Historique",
    "chatHistory": "Historique du chat",
    "contentEditHistory": "Historique des modifications du contenu",
    "modificationType_add": "Ajout",
    "modificationType_update": "Mise à jour",
    "modificationType_move": "Déplacement",
    "modificationType_remove": "Suppression",
    "modificationType_tests_update": "Mise à jour des tests",
    "restore": "Restaurer",
    "view": "vue",
    "views": "vues",
    "glossary": "Glossaire",
    "internalSearchFromThisDirectory": "Recherche interne à partir de ce dossier",
    "useTheDefaultContent": "Utilise le contenu par défaut",
    "infUseTheDefaultContent": "Utiliser le contenu par défaut",
    "fallbackEngine": "Solution de repli",
    "chatWithTheChatbot": "Discuter avec le chatbot",
    "bubbleBackgroundColor": "Couleur de fond des bulles",
    "allowToJumpFromLocalMessageToDefaultRecommendations": "Permettre de passer d'un message local aux recommandations par défaut",
    "useDefaultProactivity": "Utiliser la proactivité par défaut",
    "showOption": "Voir les options",
    "sourcesNotToDisplay": "Sources à ne pas afficher",
    "goals": "Objectifs",
    "toChangeTheSubject": "Pour changer le sujet",
    "liturgicalTime": "Temps liturgique",
    "rightAnswer": "Bonne réponse",
    "wrongAnswer": "Mauvaise réponse",
    "suggestedResponses": "Propositions de réponse",
    "suggestedResponse": "Proposition de réponse",
    "question": "Question"
};


function GetStrLocalized(language: string, textId: string, param1?: string, param2?: string) {
    if (param1 !== null && param2 !== null) {
        if (language === "fr") {
            if (textId === "moveTowards")
                return "Êtes-vous sûr de vouloir déplacer \"" + param1 + "\" vers \"" + param2 + "\" ?"
        }
        if (textId === "moveTowards")
            return "Are you sure you want to move \"" + param1 + "\" towards \"" + param2 + "\"?"
    }

    if (language === "fr") {
        return frenchStrings[textId]
    }
    return englishStrings[textId]
}


export default GetStrLocalized