import { minWindowSizeForNavBarAlawaysOpened } from "./OpenNav";


const CloseNav = () => {
	if (window.innerWidth >= minWindowSizeForNavBarAlawaysOpened)
		return

	const hcSidenavIdEltId = document.getElementById("hcSidenavId")
	if (hcSidenavIdEltId != null) {
		if (window.innerWidth <= 850) {
			hcSidenavIdEltId.style.width = "0px";
		} else {
			hcSidenavIdEltId.style.width = "60px";
		}
	}

	let hcSideBarTextElements = document.getElementsByClassName("hc-sidebar-text");
	Array.prototype.filter.call(hcSideBarTextElements, function (hcSideBarTextElement) {
		return hcSideBarTextElement.style.display = 'none';
	});
}

export default CloseNav