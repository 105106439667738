import { useEffect, useState } from "react"
import { Navigate, useLocation, useNavigate } from "react-router-dom"
import H1Title from '../components/title/H1Title'
import PageContent from '../components/util/PageContent'
import GetStrLocalized from '../datas/GetStrLocalized'
import { HcContext } from '../types/hccontext'
import HorizontalSpace from '../components/util/HorizontalSpace'
import Button from '../components/button/Button'
import { ButtonType } from '../props/buttondataprops'
import GetHtmlStrLocalized from '../datas/GetHtmlStrLocalized'
import { GlossaryWordInfoProps, createEmptyGlossaryWordInfoProps } from "../types/GlossaryWordEdition"
import GlossaryWordModifier from "../components/modifier/GlossaryWordModifier"
import { createErrorModalContent } from "../props/ModalContentProps"


interface GlossaryWordProps {
	language: string;
	backendUrl: string;
	hcContext: HcContext;
}


function GlossaryWord({ language, backendUrl, hcContext }: Readonly<GlossaryWordProps>) {

	useEffect(() => {
		hcContext.setDocumentTitle(GetStrLocalized(language, "glossary") + " - Holy Chatter");
	}, [hcContext, language]);

	const location = useLocation()
	const navigate = useNavigate()
	const [lastPath, setLastPath] = useState("")
	const [lastSearch, setLastSearch] = useState("")
	const [word, setWord] = useState<GlossaryWordInfoProps | undefined>(undefined)
	const [editMode, setEditMode] = useState(false)

	if (location.pathname !== lastPath || location.search !== lastSearch) {
		setLastPath(location.pathname)
		setLastSearch(location.search)
		const urlParams = new URLSearchParams(location.search);
		const isEditMode = urlParams.has('edit')
		setEditMode(isEditMode)

		const foldersArray = location.pathname.split('/');
		if (foldersArray.length > 3) {
			const wordFromUrl = foldersArray[3]
			fetch(backendUrl + "/glossary_word_json?l=" + language + "&w=" + wordFromUrl)
				.then(res => res.json())
				.then(jsonData => {
					if (jsonData.errorMessage) {
						hcContext.setInfo(createErrorModalContent(language, jsonData.errorMessage))
						return
					}
					setWord(jsonData)
				})
				.catch(rejected => {
					hcContext.setInfo(createErrorModalContent(language, rejected.message))
				});
		}
	}


	return (
		<PageContent>
			{
				editMode &&
				!hcContext.userAsRightsToModifyTheContent &&
				<Navigate to={lastPath} />
			}

			{
				editMode ?
					<GlossaryWordModifier language={language} hcContext={hcContext} inputWord={word || createEmptyGlossaryWordInfoProps()} lastPath={lastPath} />
					:
					<>
						<table width='100%'>
							<tbody>
								<tr>
									<td width={(30 + 15) * 3 + 45} style={{ paddingTop: 20 }} className="hc-not-very-short-screen">
										<HorizontalSpace width={45} />
									</td>
									<td>
										<H1Title>{word?.word}</H1Title>
									</td>
									{
										hcContext.userAsRightsToModifyTheContent ?
											<td width={(30 + 15) * 3 + 45} style={{ paddingTop: 20 }} className="hc-not-very-short-screen">
												<Button btnData={{ type: ButtonType.EDIT, onClick: () => navigate("?edit") }} language={language} isDarkTheme={hcContext.isDarkTheme} />
												<HorizontalSpace />
											</td>
											:
											<td width={(30 + 15) * 3 + 45} className="hc-not-very-short-screen"></td>
									}
								</tr>
							</tbody>
						</table>

						<br /><br />
						<b><GetHtmlStrLocalized language={language} textId="shortDefinition" /></b><br />
						{word?.shortDefinition}

						<br /><br />
						<b><GetHtmlStrLocalized language={language} textId="definition" /></b><br />
						<span dangerouslySetInnerHTML={{ __html: word ? word?.definition : "" }} />

						<br /><br />
						<b><GetHtmlStrLocalized language={language} textId="source" /></b><br />
						{word?.source}

						<br /><br />
						<b><GetHtmlStrLocalized language={language} textId="url" /></b><br />
						<a href={word?.url} target="_blank" rel="noreferrer">{word?.url}</a>

					</>
			}

		</PageContent>
	)
}

export default GlossaryWord;