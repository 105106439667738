import React, { useEffect } from 'react'
import { useState } from 'react'
import Modal, { ModalBody, ModalFooter, ModalHeader } from './Modal'
import CategoriesBigButtons, { CategoryProps } from '../bigButton/CategoriesBigButtons'
import GetHtmlStrLocalized from '../../datas/GetHtmlStrLocalized'
import { MessageProps } from '../../props/messageprops'
import BubblesFromUser from '../chatbot/BubblesFromUser'
import { HcContext } from '../../types/hccontext'


interface ArticleSelectorModalProps {
    language: string;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    backendUrl: string;
    articleSelected: (id: string) => void;
    hcContext: HcContext;
}


function ArticleSelectorModal({ language, show, setShow, backendUrl, articleSelected, hcContext }: Readonly<ArticleSelectorModalProps>) {
    const [currentFolderId, setCurrentFolderId] = useState<string>("")
    const [publicFolders, setPublicFolders] = useState<CategoryProps[]>([])
    const [hiddenFolders, setHiddenFolders] = useState<CategoryProps[]>([])
    const [publicArticles, setPublicArticles] = useState<CategoryProps[]>([])
    const [hiddenArticles, setHiddenArticles] = useState<CategoryProps[]>([])
    const [publicChatMsgs, setPublicChatMsgs] = useState<MessageProps[]>([])
    const [hiddenChatMsgs, setHiddenChatMsgs] = useState<MessageProps[]>([])

    useEffect(() => {
        if (show) {
            fetch(backendUrl + "/categories_page_json?l=" + language + "&id=" + currentFolderId)
                .then(res => res.json())
                .then(jsonData => {
                    let pf = Array.isArray(jsonData.bigButtontDatas) ? jsonData.bigButtontDatas.filter((item: CategoryProps) => item.type === "folder") : []
                    setPublicFolders(pf)
                    let hf = Array.isArray(jsonData.bigButtontDatas_hidden) ? jsonData.bigButtontDatas_hidden.filter((item: CategoryProps) => item.type === "folder") : []
                    setHiddenFolders(hf)
                    let pa = Array.isArray(jsonData.bigButtontDatas) ? jsonData.bigButtontDatas.filter((item: CategoryProps) => item.type === "message") : []
                    setPublicArticles(pa)
                    let ha = Array.isArray(jsonData.bigButtontDatas_hidden) ? jsonData.bigButtontDatas_hidden.filter((item: CategoryProps) => item.type === "message") : []
                    setHiddenArticles(ha)
                    setPublicChatMsgs(jsonData.chatMsgs)
                    setHiddenChatMsgs(jsonData.chatMsgs_hidden)
                })
                .catch(rejected => {
                    console.log(rejected);
                });
        } else {
            setCurrentFolderId("")
        }
    }, [show, currentFolderId]);


    function selectArticle(artcileId: string) {
        articleSelected(artcileId)
        setShow(false)
    }

    return (
        <Modal
            language={language}
            show={show}
            setShow={setShow}
            isDarkTheme={hcContext.isDarkTheme}
            display='flex'
            contentWidth="90%"
        >
            <ModalHeader>
                <h3><GetHtmlStrLocalized language={language} textId={"selectAMessage"} /></h3>
            </ModalHeader>
            <ModalBody>
                <CategoriesBigButtons categories={publicFolders} printNumber={false} onClick={(id) => setCurrentFolderId(id)} hcContext={hcContext} />
                <CategoriesBigButtons categories={publicArticles} printNumber={false} onClick={selectArticle} hcContext={hcContext} />
                <BubblesFromUser language={language} titleId="relatedQuestions" chatMsgs={publicChatMsgs} onClick={selectArticle} />
                {
                    (hiddenFolders.length > 0 || hiddenArticles.length > 0) &&
                    <>
                        <br />
                        <br />
                        <b><GetHtmlStrLocalized language={language} textId="privateSection" /></b>
                        <CategoriesBigButtons categories={hiddenFolders} printNumber={false} onClick={(id) => setCurrentFolderId(id)} hcContext={hcContext} />
                        <CategoriesBigButtons categories={hiddenArticles} printNumber={false} onClick={selectArticle} hcContext={hcContext} />
                        <BubblesFromUser language={language} titleId="relatedQuestions" chatMsgs={hiddenChatMsgs} onClick={selectArticle} />
                    </>
                }
            </ModalBody>
            <br />
            <ModalFooter>
                <button onClick={() => { setShow(false) }}>
                    <GetHtmlStrLocalized language={language} textId="close" />
                </button>
            </ModalFooter>
        </Modal>
    );
}

export default ArticleSelectorModal;