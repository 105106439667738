import React, { useState } from "react"
import { Link } from "react-router-dom";
import GetStrLocalized from "../../datas/GetStrLocalized";
import { SuggestionProps } from "../../props/suggestionprops";
import { ChatbotContext } from "../../types/chatbotcontext";
import { HcContext } from "../../types/hccontext";
import { ModelWithPath } from "../../types/modelwithpath";
import ChatbotContentWidget from "./ChatbotContentWidget";
import InputWithStyle from "../banner/InputWithStyle";



interface ChatbotWindowProps {
    language: string;
    hcContext: HcContext;
    sessionId: string;
    chatbotContext: ChatbotContext;
    modelWithPath: ModelWithPath;
}

function ChatbotWindow({ language, hcContext, sessionId, chatbotContext, modelWithPath }: Readonly<ChatbotWindowProps>) {

    const [writtenText, setWrittenText] = useState("")
    const [_, setMenuSuggestions] = useState<SuggestionProps[]>([])
    const [inputValue, setInputValue] = useState("")
    const videoWidthClassName = "hc-width-chatwindow-inside-bubble"

    function onNewText(newtext: string) {
        if (newtext === "")
            return;
        setWrittenText(newtext)
        setInputValue("")
    }

    function onTextValidated() {
        if (inputValue != null)
            onNewText(inputValue)
    }


    return (
        <>
            <div className="hccw-chat-popup-header hc-content-text" style={{ backgroundColor: 'green', borderRadius: '10px 10px 0px 0px' }}>
                <table style={{ margin: '10px' }}>
                    <tbody>
                        <tr>
                            <th>
                                <img src="/holychatter-chat-window/images/theophile.png" width="45" height="45" alt='logo' />
                            </th>
                            <th style={{ width: "100%", fontWeight: 'normal', paddingLeft: '15px', color: 'white' }}>
                                <b>Théophile</b><br /><small>Chatbot chrétien</small>
                            </th>
                            <th style={{ paddingBottom: '20px' }}>
                                <Link to={"/" + language + "/" + GetStrLocalized(language, "chatbotFolderName")}><img src="/holychatter-chat-window/images/fullscreen.png" width="20" height="20" alt='full screen' /></Link>
                            </th>
                            <th style={{ paddingLeft: '15px', paddingRight: '5px', paddingBottom: '20px' }}>
                                <img className="hc-cursor-pointer" src="/holychatter-chat-window/images/cross.png" width="20" height="20" onClick={() => chatbotContext.setIsWindowOpen(false)} alt='cross' />
                            </th>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="hccw-chat-popup-content hccw-chat-heart-bg">
                <ChatbotContentWidget language={language} hcContext={hcContext} sessionId={sessionId} chatbotContext={chatbotContext} writtenText={writtenText} setWrittenText={setWrittenText}
                    setMenuSuggestions={setMenuSuggestions} chatbotId={""} videoWidthClassName={videoWidthClassName} debugMode={hcContext.userAsRightsToModifyTheContent} />
            </div>
            <div className="hccw-chat-popup-bottom">

                <div style={{ position: 'absolute', top: '0px', left: '0px', right: "0px", bottom: '0px' }}>
                    <InputWithStyle onValidation={onTextValidated} value={inputValue}
                        placeholder={GetStrLocalized(language, "writeASentence")}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setInputValue(event.target.value)
                        }} isDarkTheme={hcContext.isDarkTheme} height={'50px'}
                        hasSearchButton={true} />
                </div>
            </div>
        </>
    )
}

export default ChatbotWindow



