import React, { useRef } from 'react'
import { Link } from "react-router-dom"
import image_house from '../../assets/resources/custom/navbar_icons/house.png'
import image_house_darkTheme from '../../assets/resources/custom/navbar_icons/house_darkTheme.png'
import image_chatbot from '../../assets/resources/custom/navbar_icons/chatbot.png'
import image_chatbot_darkTheme from '../../assets/resources/custom/navbar_icons/chatbot_darkTheme.png'
import image_bible from '../../assets/resources/custom/navbar_icons/bible.png'
import image_bible_darkTheme from '../../assets/resources/custom/navbar_icons/bible_darkTheme.png'
import image_sources from '../../assets/resources/custom/navbar_icons/sources.png'
import image_sources_darkTheme from '../../assets/resources/custom/navbar_icons/sources_darkTheme.png'
import image_englishFlag from '../../assets/resources/custom/navbar_icons/england.png'
import image_englishFlag_darkTheme from '../../assets/resources/custom/navbar_icons/england_darkTheme.png'
import image_frenchFlag from '../../assets/resources/custom/navbar_icons/france.png'
import image_profil from '../../assets/resources/custom/navbar_icons/profil.png'
import image_profil_darkTheme from '../../assets/resources/custom/navbar_icons/profil_darkTheme.png'
import image_manage from '../../assets/resources/custom/navbar_icons/manage.png'
import image_manage_darkTheme from '../../assets/resources/custom/navbar_icons/manage_darkTheme.png'
import GetHtmlStrLocalized from '../../datas/GetHtmlStrLocalized'
import GetStrLocalized from '../../datas/GetStrLocalized'
import OpenNav, { minWindowSizeForNavBarAlawaysOpened } from './OpenNav'
import CloseNav from './CloseNav'
import { HcContext } from '../../types/hccontext'

interface HCNavBarProps {
	language: string;
	setLanguage: React.Dispatch<React.SetStateAction<string>>;
	hcContext: HcContext;
}

const HCNavBar: React.FC<HCNavBarProps> = ({ language, setLanguage, hcContext }) => {

	const navDivRef = useRef<HTMLDivElement>(null);

	const otherLanguage = (language === "fr") ? "en" : "fr";
	let image_otherLanguageFlag;
	if (language === "fr") {
		image_otherLanguageFlag = hcContext.isDarkTheme ? image_englishFlag_darkTheme : image_englishFlag;
	} else {
		image_otherLanguageFlag = image_frenchFlag;
	}

	function openForVeryLargeScreens() {
		if (window.innerWidth >= minWindowSizeForNavBarAlawaysOpened)
			OpenNav()
		else
			CloseNav()
	}

	window.addEventListener("resize", openForVeryLargeScreens)
	window.addEventListener("load", openForVeryLargeScreens)

	return (
		<React.Fragment>
			<div ref={navDivRef} id='hcSidenavId' className={`hc-sidenav ${hcContext.getBackgroundColorClass()}`} onMouseOver={OpenNav} onMouseOut={CloseNav}>
				<Link to={"/" + language} style={{ color: hcContext.getTextColor() }} onClick={CloseNav}>
					<img style={{ marginLeft: 20 }} width='20px' src={hcContext.isDarkTheme ? image_house_darkTheme : image_house} alt="home" />
					<span className='hc-sidebar-text'><GetHtmlStrLocalized language={language} textId="home" /></span>
				</Link>
				<Link to={"/" + language + "/" + GetStrLocalized(language, "categoriesFolderName") + "/" + GetStrLocalized(language, "reasonsToBelieveFolderName")} style={{ color: hcContext.getTextColor() }} onClick={CloseNav}>
					<img style={{ marginLeft: 20 }} width='20px' src={hcContext.isDarkTheme ? image_house_darkTheme : image_house} alt="home" />
					<span className='hc-sidebar-text'><GetHtmlStrLocalized language={language} textId="reasonsToBelieve" /></span>
				</Link>
				<Link to={"/" + language + "/" + GetStrLocalized(language, "chatbotFolderName")} style={{ color: hcContext.getTextColor() }} onClick={CloseNav}>
					<img style={{ marginLeft: 20 }} width='20px' src={hcContext.isDarkTheme ? image_chatbot_darkTheme : image_chatbot} alt="chatbot" />
					<span className='hc-sidebar-text'><GetHtmlStrLocalized language={language} textId="chatbot" /></span>
				</Link>
				<Link to={"/" + language + "/" + GetStrLocalized(language, "readingsFolderName") + "/" + GetStrLocalized(language, "bibleFolderName") + "/" + GetStrLocalized(language, "gospelsFolderName")} style={{ color: hcContext.getTextColor() }} onClick={CloseNav}>
					<img style={{ marginLeft: 20 }} width='20px' src={hcContext.isDarkTheme ? image_bible_darkTheme : image_bible} alt="Bible" />
					<span className='hc-sidebar-text'><GetHtmlStrLocalized language={language} textId="gospels" /></span>
				</Link>
				<Link to={"/" + language + "/" + GetStrLocalized(language, "gospelOfTheDayFolderName")} style={{ color: hcContext.getTextColor() }} onClick={CloseNav}>
					<img style={{ marginLeft: 20 }} width='20px' src={hcContext.isDarkTheme ? image_bible_darkTheme : image_bible} alt="Bible" />
					<span className='hc-sidebar-text hc-sidebar-text-sub'><GetHtmlStrLocalized language={language} textId="gospelOfTheDay" /></span>
				</Link>
				<Link to={"/" + language + "/" + GetStrLocalized(language, "readingsFolderName")} style={{ color: hcContext.getTextColor() }} onClick={CloseNav}>
					<img style={{ marginLeft: 20 }} width='20px' src={hcContext.isDarkTheme ? image_bible_darkTheme : image_bible} alt="Bible" />
					<span className='hc-sidebar-text'><GetHtmlStrLocalized language={language} textId="readings" /></span>
				</Link>
				<Link to={"/" + language + "/" + GetStrLocalized(language, "readingsOfTheDayFolderName")} style={{ color: hcContext.getTextColor() }} onClick={CloseNav}>
					<img style={{ marginLeft: 20 }} width='20px' src={hcContext.isDarkTheme ? image_bible_darkTheme : image_bible} alt="Bible" />
					<span className='hc-sidebar-text hc-sidebar-text-sub'><GetHtmlStrLocalized language={language} textId="readingsOfTheDay" /></span>
				</Link>
				<Link to={"/" + language + "/" + GetStrLocalized(language, "sourcesFolderName")} style={{ color: hcContext.getTextColor() }} onClick={CloseNav}>
					<img style={{ marginLeft: 20 }} width='20px' src={hcContext.isDarkTheme ? image_sources_darkTheme : image_sources} alt="sources" />
					<span className='hc-sidebar-text'><GetHtmlStrLocalized language={language} textId="sources" /></span>
				</Link>
				<Link to={"/" + language + "/" + GetStrLocalized(language, "tagsFolderName")} style={{ color: hcContext.getTextColor() }} onClick={CloseNav}>
					<img style={{ marginLeft: 20 }} width='20px' src={hcContext.isDarkTheme ? image_sources_darkTheme : image_sources} alt="tags" />
					<span className='hc-sidebar-text'><GetHtmlStrLocalized language={language} textId="tags" /></span>
				</Link>
				<Link to={"/" + otherLanguage} style={{ color: hcContext.getTextColor() }} onClick={() => { CloseNav(); setLanguage(otherLanguage); }}>
					<img style={{ marginLeft: 20 }} width='20px' src={image_otherLanguageFlag} alt="flag" />
					<span className='hc-sidebar-text'><GetHtmlStrLocalized language={language} textId="goToTheOtherLanguageVersion" /></span>
				</Link>
				<Link to={"/" + language + "/" + GetStrLocalized(language, "aboutFolderName")} style={{ color: hcContext.getTextColor() }} onClick={CloseNav}>
					<img style={{ marginLeft: 20 }} width='20px' src={hcContext.isDarkTheme ? image_profil_darkTheme : image_profil} alt="profil" />
					<span className='hc-sidebar-text'><GetHtmlStrLocalized language={language} textId="about" /></span>
				</Link>
				{
					hcContext.userAsRightsToModifyTheContent &&
					<Link to={"/" + language + "/" + GetStrLocalized(language, "cmsFolderName")}  style={{ color: hcContext.getTextColor() }} onClick={CloseNav}>
						<img style={{ marginLeft: 20 }} width='20px' src={hcContext.isDarkTheme ? image_manage_darkTheme : image_manage} alt="edit" />
						<span className='hc-sidebar-text'><GetHtmlStrLocalized language={language} textId="cms" /></span>
					</Link>
				}
				<br /><br /><br /><br /><br /><br />
			</div>
			<br />
		</React.Fragment>
	)
}

export default HCNavBar
