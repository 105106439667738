import '../../assets/resources/custom/style/main-6.css'
import '../../assets/resources/custom/style/bootstrap_hc.css'
import { Link } from "react-router-dom";
import image_person from '../../assets/resources/custom/icons/person.png'
import image_facebook from '../../assets/resources/custom/images/facebook.png'
import image_youtube from '../../assets/resources/custom/images/youtube.png'
import image_magnifying_glass from '../../assets/resources/custom/images/magnifying_glass.png'
import GetStrLocalized from '../../datas/GetStrLocalized'
import React, { useEffect, useState } from 'react'
import OpenNav from '../navigation/OpenNav'
import CloseNav from '../navigation/CloseNav'
import { HcContext, SizeOfWindow } from '../../types/hccontext';
import { gapi } from 'gapi-script';
import LoginModal from '../modal/LoginModal';
import MainSearchInput from './MainSearchInput';


interface BannerProps {
	language: string;
	hcContext: HcContext;
	isInChatbotPage: boolean;
	searchValue: string;
}



const Banner: React.FC<BannerProps> = ({ language, hcContext, isInChatbotPage, searchValue }) => {

	const [showLoginModal, setShowLoginModal] = useState(false);

	useEffect(() => {
		// Usefull for google login
		function start() {
			gapi.auth2.init({
				clientId: process.env.REACT_PUBLIC_GOOGLE_CLIENT_ID,
				scope: 'email',
			});
		}
	}, []);

	function openOrCloseNav() {
		const hcSidenavIdEltId = document.getElementById("hcSidenavId");
		if (hcSidenavIdEltId != null) {
			if (hcSidenavIdEltId.style.width === "250px") {
				CloseNav();
			} else {
				OpenNav();
			}
		}
	}


	return (
		<div className={`${isInChatbotPage ? "hc-fixed-for-chatbot" : "hc-fixed-banner"} ${hcContext.getBackgroundColorClass()}`}>
			<span style={{ display: 'block', height: 13 }}></span>
			<table style={{ width: '100%' }}>
				<thead className="visually-hidden"><tr><th></th></tr></thead>
				<tbody>
					<tr>
						<td id='open-close-nav-bar-btn-id' onClick={openOrCloseNav} className='hc-top-bar-menu-button-css hc-not-selectable' style={{ color: hcContext.getTextColor(), paddingTop: 7, paddingLeft: 20, fontSize: 23, cursor: 'pointer' }}>&#9776;</td>
						<td className="hc-top-bar-sides-css">
							<Link style={{ outline: 0 }} to={"/" + language}>
								<img title="Holy Chatter" src="/upload/holy_chatter_logo.png" alt="Holy Chatter logo" className="hc-main-logo hc-not-selectable" />
							</Link>
						</td>

						{
							isInChatbotPage ?
								<td />
								:
								<React.Fragment>
									<td width="100%" className="hc-very-short-screen" />

									<td className="hc-not-very-short-screen">
										<MainSearchInput language={language} searchValue={searchValue} isDarkTheme={hcContext.isDarkTheme} />
									</td>


									<td style={{ paddingTop: 5 }} className="hc-top-bar-sides-css">

										<table>
											<tbody>
												<tr>
													<td><span className="hc-very-short-screen" title="Options">
														<div style={{ paddingRight: 15 }}>
															<Link to={"/" + language + "/" + GetStrLocalized(language, "searchFolderName")}>
																<img src={image_magnifying_glass} alt="search" height="30px" width="30px" />
															</Link>
														</div>
													</span></td>

													{
														hcContext.sizeOfWindow === SizeOfWindow.WIDE &&
														<>
															<td><span className="hc-not-very-short-screen" title={GetStrLocalized(language, "facebookPage")}>
																<span style={{ paddingRight: 15 }}>
																	<a href="https://www.facebook.com/profile.php?id=100087443046272">
																		<img src={image_facebook} alt="Facebook logo" height="30" width="30" />
																	</a>
																</span>
															</span></td>

															<td><span className="hc-not-very-short-screen" title={GetStrLocalized(language, "youtubeChannel")}>
																<span style={{ paddingRight: 15 }}>
																	<a href="https://www.youtube.com/c/HOLYChatterfr">
																		<img src={image_youtube} alt="YouTube logo" height="30" width="30" />
																	</a>
																</span>
															</span></td>
														</>
													}

													<td>
														<span className="hc-not-very-short-screen" title="API">
															<div style={{ marginTop: 7, paddingRight: 15 }}>
																<Link to={"/" + language + "/" + GetStrLocalized(language, "apiFolderName")} className="hc-api" title={GetStrLocalized(language, "accessTheApi")}>API</Link>
															</div>
														</span>
													</td>

													<td>
														<span title="Google authentification">
															<div style={{ paddingRight: 15 }}>
																{
																	hcContext.userIsIdentified &&
																		hcContext.user ?
																		<img src={hcContext.user.picture} onClick={() => hcContext.clearUser()} alt="disconnect" className='hc-cursor-pointer' height="30" width="30" style={{ borderRadius: '50%' }} />
																		:
																		<img src={image_person} referrerPolicy='no-referrer' onClick={() => setShowLoginModal(true)} alt="sign in" className='hc-cursor-pointer' height="30" width="30" style={{ borderRadius: '50%' }} />
																}

																{
																	showLoginModal &&
																	<LoginModal
																		language={language}
																		setShow={setShowLoginModal}
																		hcContext={hcContext}
																	/>
																}
															</div>
														</span>
													</td>

												</tr>
											</tbody>
										</table>

									</td>
								</React.Fragment>
						}

					</tr>
				</tbody>
			</table>
			<span style={{ display: 'block', height: 13 }}></span>
		</div>
	)

}

export default Banner
