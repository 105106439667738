import image_wait from '../assets/resources/custom/icons/wait.gif'
import React, { useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import H1Title from '../components/title/H1Title'
import PageContent from '../components/util/PageContent'
import GetStrLocalized from '../datas/GetStrLocalized'
import { HcContext, SizeOfWindow } from '../types/hccontext'
import HorizontalSpace from '../components/util/HorizontalSpace'
import Button from '../components/button/Button'
import { ButtonType } from '../props/buttondataprops'
import H2TitleId from '../components/title/H2TitleId'
import GetHtmlStrLocalized from "../datas/GetHtmlStrLocalized"
import Centered from "../components/util/Centered"
import AddOrModifyTagModal from "../components/modal/AddOrModifyTagModal"
import { getWaitPopupStyle } from "../components/util/GetWaitPopupStyle"
import YesNoModal from '../components/modal/YesNoModal'
import { createErrorModalContent } from '../props/ModalContentProps'

interface TagProps {
	name: string;
	nbOfMessages: number;
}

interface RequestProps {
	tags: TagProps[];
	tags_hidden: TagProps[];
}

interface TagsProps {
	language: string;
	setDocumentTitle: React.Dispatch<React.SetStateAction<string>>;
	backendUrl: string;
	hcContext: HcContext;
}


function Tags({ language, setDocumentTitle, backendUrl, hcContext }: Readonly<TagsProps>) {

	useEffect(() => {
		setDocumentTitle(GetStrLocalized(language, "tags") + " - Holy Chatter");
	}, [setDocumentTitle, language]);

	const location = useLocation()
	const [lastPath, setLastPath] = useState("")
	const [lastSearch, setLastSearch] = useState("")
	const [request, setRequest] = useState<RequestProps>({ tags: [], tags_hidden: [] })
	const [showModifyTagModal, setShowModifyTagModal] = useState(false)
	const [showDeleteModal, setShowDeleteModal] = useState(false)
	const [processingModifications, setProcessingModifications] = useState(false)
	const [selectedElt, setSelectedElt] = React.useState<string | undefined>()

	function refreshPage() {
		fetch(backendUrl + "/tags_page_json?l=" + language)
			.then(res => res.json())
			.then(jsonData => {
				if (jsonData.errorMessage) {
					hcContext.setInfo(createErrorModalContent(language, jsonData.errorMessage))
					return
				}
				setRequest(jsonData)
			})
			.catch(rejected => {
				hcContext.setInfo(createErrorModalContent(language, rejected.message))
			});
	}

	if (location.pathname !== lastPath || location.search !== lastSearch) {
		setLastPath(location.pathname)
		setLastSearch(location.search)
		refreshPage()
	}

	class RemoveTagPostContentType {
		language: string;
		userKey: string;
		id: string;

		constructor(
			language: string,
			userKey: string,
			id: string) {
			this.language = language;
			this.userKey = userKey;
			this.id = id;
		}
	}

	function removeTag() {
		if (hcContext.userToken && selectedElt !== undefined) {
			const postContent = new RemoveTagPostContentType(language, hcContext.userToken, selectedElt)
			fetch(hcContext.backendUrl + "/remove_tag", {
				method: 'POST',
				body: JSON.stringify(postContent),
				headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
			})
				.then(res => res.json())
				.then(jsonData => {
					if (jsonData.errorMessage) {
						hcContext.setInfo(createErrorModalContent(language, jsonData.errorMessage))
					} else {
						setSelectedElt(undefined)
						refreshPage()
					}
				})
				.catch(rejected => {
					hcContext.setInfo(createErrorModalContent(language, rejected.message))
				});
		}
	}

	function DisplayTagTitle() {
		return (
			<div>
				<table width="100%" style={{ marginTop: '20px', marginBottom: '20px' }}>
					<tbody>
						<tr>
							<td className='hc-content-text hc-content-text-paddings' width="40%">
								<b><GetHtmlStrLocalized language={language} textId="tag" /></b>
							</td>
							{
								hcContext.sizeOfWindow !== SizeOfWindow.VERY_SHORT &&
								<td className='hc-content-text hc-content-text-paddings' width="20%">
									<b><GetHtmlStrLocalized language={language} textId="numberOfMessages" /></b>
								</td>
							}
							<td className='hc-content-text hc-content-text-paddings' width="40%">
								<b><GetHtmlStrLocalized language={language} textId="link" /></b>
							</td>
						</tr>
					</tbody>
				</table>
				<hr style={{ height: 2, backgroundColor: '#333333', border: 'none' }} />
			</div>
		)
	}


	interface DisplayTagLineProps {
		tag: TagProps;
	}

	function DisplayTagLine({ tag }: DisplayTagLineProps) {
		const fontWeight = (hcContext.userAsRightsToModifyTheContent && selectedElt === tag.name) ? 'bold' : 'normal'

		return (
			<div style={{ fontWeight: 'bold' }}>
				<table width="100%" style={{ marginTop: '20px', marginBottom: '20px', cursor: hcContext.userAsRightsToModifyTheContent ? 'pointer' : 'default' }}
					onClick={() => {
						if (hcContext.userAsRightsToModifyTheContent)
							setSelectedElt(tag.name)
					}}>
					<tbody>
						<tr>
							<td className='hc-content-text hc-content-text-paddings' style={{ fontWeight: fontWeight }} width="40%">
								#{tag.name}
								{
									hcContext.sizeOfWindow === SizeOfWindow.VERY_SHORT &&
									<><br />({tag.nbOfMessages} messages)</>
								}
							</td>
							{
								hcContext.sizeOfWindow !== SizeOfWindow.VERY_SHORT &&
								<td className='hc-content-text hc-content-text-paddings' style={{ fontWeight: fontWeight }} width="20%">
									{tag.nbOfMessages}
								</td>
							}
							<td className='hc-content-text hc-content-text-paddings' style={{ fontWeight: fontWeight }} width="40%">
								<Link to={"/" + language + "/" + GetStrLocalized(language, "searchFolderName") + "/%23" + tag.name}>
									<GetHtmlStrLocalized language={language} textId="seeRandomMessagesWithThisTag" />
								</Link>
							</td>
						</tr>

					</tbody>
				</table>
				{
					hcContext.sizeOfWindow === SizeOfWindow.WIDE &&
					<hr style={{ height: 1, backgroundColor: 'grey', border: 'none' }} />
				}
			</div>
		)
	}


	return (
		<PageContent>
			{
				processingModifications &&
				<Centered>
					<div style={getWaitPopupStyle(true, 50)}>
						<img src={image_wait} alt="wait" height="100%" width="100%" />
					</div>
				</Centered>
			}

			<table width='100%'>
				<tbody>
					<tr>
						<td width={(30 + 15) * 3 + 45} style={{ paddingTop: 20 }} className="hc-not-very-short-screen">
							<HorizontalSpace width={45} />
						</td>
						<td>
							<H1Title><GetHtmlStrLocalized language={language} textId="tags" /></H1Title>
						</td>
						{
							hcContext.userAsRightsToModifyTheContent ?
								<td width={(30 + 15) * 3 + 45} style={{ paddingTop: 20 }} className="hc-not-very-short-screen">

									{
										selectedElt !== undefined &&
										<>
											<Button btnData={{ type: ButtonType.EDIT, onClick: () => setShowModifyTagModal(true) }} language={language} isDarkTheme={hcContext.isDarkTheme} />
											<HorizontalSpace />
											<Button btnData={{ type: ButtonType.DELETE, onClick: () => setShowDeleteModal(true) }} language={language} isDarkTheme={hcContext.isDarkTheme} />
											<HorizontalSpace />
										</>
									}

									<Button btnData={{ type: ButtonType.ADD, onClick: () => { setSelectedElt(undefined); setShowModifyTagModal(true); } }} language={language} isDarkTheme={hcContext.isDarkTheme} />
									<HorizontalSpace />
								</td>
								:
								<td width={(30 + 15) * 3 + 45} className="hc-not-very-short-screen"></td>
						}
					</tr>
				</tbody>
			</table>

			<br />
			<div className='hc-content-text-paddings'>
				<DisplayTagTitle />
				{request.tags.map((item, index) => { return <DisplayTagLine key={"tag-" + index} tag={item} /> })}
			</div>

			{
				hcContext.userAsRightsToModifyTheContent &&
				request.tags_hidden &&
				<>
					<br />
					<br />
					<H2TitleId language={language} titleId="privateSection" />
					<br />
					<div className='hc-content-text-paddings'>
						<DisplayTagTitle />
						{request.tags_hidden.map((item, index) => { return <DisplayTagLine key={"tag-hidden-" + index} tag={item} /> })}
					</div>
				</>
			}

			<AddOrModifyTagModal language={language} show={showModifyTagModal} setShow={setShowModifyTagModal}
				hcContext={hcContext}
				setProcessingModifications={setProcessingModifications}
				onModification={refreshPage}
				tag={selectedElt}
			/>

			<YesNoModal language={language} show={showDeleteModal} setShow={setShowDeleteModal}
			    isDarkTheme={hcContext.isDarkTheme}
				title={GetStrLocalized(language, "removalConfirmation")}
				text={GetStrLocalized(language, "areYouSureToPermanentlyDeletethisTag")}
				onYes={() => removeTag()} />
		</PageContent>
	)
}

export default Tags;