import * as React from "react";
import EditionLabel from "../title/EditionLabel";
import Button from "../button/Button";
import { ButtonType } from "../../props/buttondataprops";
import GetStrLocalized from "../../datas/GetStrLocalized";
import TagEditionModal from "../modal/TagEditionModal";
import { HcContext } from "../../types/hccontext";



interface TagsListViewerProps {
  language: string;
  tags: string[];
  setTags: (r: string[]) => any;
  allPossibleTags: string[];
  hcContext: HcContext;
}

export default function TagsListViewer(
  { language, tags, setTags, allPossibleTags, hcContext }: Readonly<TagsListViewerProps>
) {
  const [selectedElt, setSelectedElt] = React.useState<number | undefined>()
  const [showModal, setShowModal] = React.useState(false)
  const [addOrEdit, setAddOrEdit] = React.useState<boolean>(true)
  const [tagEdition, setTagEdition] = React.useState<string>("")

  function swapElts(firstIndex: number, secondIndex: number) {
    let eltsCopied = tags;
    const temp = eltsCopied[firstIndex];
    eltsCopied[firstIndex] = eltsCopied[secondIndex];
    eltsCopied[secondIndex] = temp;
    setTags(eltsCopied)
  }

  return (
    <>
      <table>
        <tbody>
          <tr>
            <td>
              {
                tags.length > 0 ?
                  <EditionLabel language={"fr"} textId="tags" />
                  :
                  <EditionLabel language={"fr"} textId="tags" color="red" />
              }

            </td>
            <td style={{ textAlign: 'center', width: '100%' }}></td>
            {
              selectedElt !== undefined &&
              <>
                {
                  tags.length > 1 &&
                  <>
                    <td style={{ paddingRight: '15px' }}><Button btnData={{
                      type: ButtonType.LEFT, onClick: () => {
                        if (selectedElt > 0) {
                          swapElts(selectedElt - 1, selectedElt)
                          setSelectedElt(selectedElt - 1)
                        }
                      }
                    }} language={language} alt={GetStrLocalized(language, "move_left")} isDarkTheme={hcContext.isDarkTheme} /></td>
                    <td style={{ paddingRight: '15px' }}><Button btnData={{
                      type: ButtonType.RIGHT, onClick: () => {
                        if (selectedElt < tags.length - 1) {
                          swapElts(selectedElt, selectedElt + 1)
                          setSelectedElt(selectedElt + 1)
                        }
                      }
                    }} language={language} alt={GetStrLocalized(language, "move_right")} isDarkTheme={hcContext.isDarkTheme} /></td>
                  </>
                }
                <td style={{ paddingRight: '15px' }}><Button btnData={{
                  type: ButtonType.EDIT, onClick: () => {
                    setAddOrEdit(false)
                    setTagEdition(tags[selectedElt])
                    setShowModal(true)
                  }
                }} language={language} isDarkTheme={hcContext.isDarkTheme} /></td>
                <td style={{ paddingRight: '15px' }}><Button btnData={{
                  type: ButtonType.DELETE, onClick: () => {
                    let refCopied = tags;
                    refCopied.splice(selectedElt, 1)
                    setTags(refCopied)
                    setSelectedElt(undefined)
                  }
                }} language={language} isDarkTheme={hcContext.isDarkTheme} /></td>
              </>
            }
            <td style={{ paddingRight: '15px' }}><Button btnData={{
              type: ButtonType.ADD, onClick: () => {
                setAddOrEdit(true)
                setTagEdition("")
                setShowModal(true)
              }
            }} language={language} isDarkTheme={hcContext.isDarkTheme} /></td>
          </tr>
        </tbody>
      </table>

      {
        tags.length > 0 &&
        <>
          {tags.map((item, index) => {
            return <span key={"tag-list-elt-" + index}><span style={{ listStyleType: "none", cursor: 'pointer' }} onClick={() => setSelectedElt(index)} >
              {
                selectedElt == index ?
                  <b>#{item}</b>
                  :
                  <>#{item}</>
              }
            </span>&nbsp;&nbsp;</span>
          })}
        </>
      }

      <TagEditionModal
        language={language}
        addOrEdit={addOrEdit}
        show={showModal}
        setShow={setShowModal}
        hcContext={hcContext}
        tagText={tagEdition}
        onTagValidated={(elt) => {
          if (addOrEdit) {
            setTags([...tags, elt])
            setSelectedElt(undefined)
          }
          else {
            let cpTriggers = tags
            cpTriggers[selectedElt!!] = elt
            setTags(cpTriggers)
          }
        }}
        currentTags={tags}
        allPossibleTags={allPossibleTags}
      />
    </>

  );
}