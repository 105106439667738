import { darkTextColor } from "../../types/hccontext";

interface BubbleToUserProps {
    children: React.ReactNode;
}

const BubbleToUser: React.FC<BubbleToUserProps> = ({ children }) => {

    return (
        <table>
            <thead className="visually-hidden"><tr><th></th></tr></thead>
            <tbody>
                <tr>
                    <td>
                        <div className='hc-logo-hc-triangle-box' style={{ paddingLeft: 14, paddingRight: 30 }}>
                            <div className='hc-bubble hc-answer-text' style={{ minHeight: 35, maxWidth: 710, padding: 10, backgroundColor: '#f1f0f0', color:darkTextColor }}>
                                <span className='hc-content-text'>
                                    <span className="hc-limit-content-width">{children}</span>
                                </span>
                            </div>
                        </div>
                    </td>
                    <td></td>
                </tr>
            </tbody>
        </table>
    )
}

export default BubbleToUser



