import React from 'react'
import image_add from '../../assets/resources/custom/icons/add.png'
import image_add_selected from '../../assets/resources/custom/icons/add_selected.png'
import image_add_selected_white from '../../assets/resources/custom/icons/add_selected_white.png'
import image_bold from '../../assets/resources/custom/icons/bold.png'
import image_bold_selected from '../../assets/resources/custom/icons/bold_selected.png'
import image_bold_selected_white from '../../assets/resources/custom/icons/bold_selected_white.png'
import image_code from '../../assets/resources/custom/icons/code.png'
import image_code_selected from '../../assets/resources/custom/icons/code_selected.png'
import image_code_selected_white from '../../assets/resources/custom/icons/code_selected_white.png'
import image_emoji from '../../assets/resources/custom/icons/emoji.png'
import image_emoji_selected from '../../assets/resources/custom/icons/emoji_selected.png'
import image_emoji_selected_white from '../../assets/resources/custom/icons/emoji_selected_white.png'
import image_gif from '../../assets/resources/custom/icons/gif.png'
import image_gif_selected from '../../assets/resources/custom/icons/gif_selected.png'
import image_gif_selected_white from '../../assets/resources/custom/icons/gif_selected_white.png'
import image_italic from '../../assets/resources/custom/icons/italic.png'
import image_italic_selected from '../../assets/resources/custom/icons/italic_selected.png'
import image_italic_selected_white from '../../assets/resources/custom/icons/italic_selected_white.png'
import image_arrow_left from '../../assets/resources/custom/icons/arrow_left.png'
import image_arrow_left_selected from '../../assets/resources/custom/icons/arrow_left_selected.png'
import image_arrow_left_selected_white from '../../assets/resources/custom/icons/arrow_left_selected_white.png'
import image_arrow_right from '../../assets/resources/custom/icons/arrow_right.png'
import image_arrow_right_selected_white from '../../assets/resources/custom/icons/arrow_right_selected_white.png'
import image_arrow_right_selected from '../../assets/resources/custom/icons/arrow_right_selected.png'
import image_arrow_down from '../../assets/resources/custom/icons/arrow_down.png'
import image_arrow_down_selected from '../../assets/resources/custom/icons/arrow_down_selected.png'
import image_arrow_down_selected_white from '../../assets/resources/custom/icons/arrow_down_selected_white.png'
import image_arrow_up from '../../assets/resources/custom/icons/arrow_up.png'
import image_arrow_up_selected from '../../assets/resources/custom/icons/arrow_up_selected.png'
import image_arrow_up_selected_white from '../../assets/resources/custom/icons/arrow_up_selected_white.png'
import image_edit from '../../assets/resources/custom/icons/edit.png'
import image_edit_selected from '../../assets/resources/custom/icons/edit_selected.png'
import image_edit_selected_white from '../../assets/resources/custom/icons/edit_selected_white.png'
import image_trash from '../../assets/resources/custom/icons/trash.png'
import image_trash_selected from '../../assets/resources/custom/icons/trash_selected.png'
import image_trash_selected_white from '../../assets/resources/custom/icons/trash_selected_white.png'
import image_check from '../../assets/resources/custom/icons/check.png'
import image_check_selected from '../../assets/resources/custom/icons/check_selected.png'
import image_check_selected_white from '../../assets/resources/custom/icons/check_selected_white.png'
import image_see from '../../assets/resources/custom/icons/see.png'
import image_see_selected from '../../assets/resources/custom/icons/see_selected.png'
import image_see_selected_white from '../../assets/resources/custom/icons/see_selected_white.png'
import image_export from '../../assets/resources/custom/icons/export.png'
import image_export_selected from '../../assets/resources/custom/icons/export_selected.png'
import image_export_selected_white from '../../assets/resources/custom/icons/export_selected_white.png'
import image_yes from '../../assets/resources/custom/icons/yes.png'
import image_yes_selected from '../../assets/resources/custom/icons/yes_selected.png'
import image_yes_selected_white from '../../assets/resources/custom/icons/yes_selected_white.png'
import image_no from '../../assets/resources/custom/icons/no.png'
import image_no_selected from '../../assets/resources/custom/icons/no_selected.png'
import image_no_selected_white from '../../assets/resources/custom/icons/no_selected_white.png'
import image_move from '../../assets/resources/custom/icons/move.png'
import image_move_selected from '../../assets/resources/custom/icons/move_selected.png'
import image_move_selected_white from '../../assets/resources/custom/icons/move_selected_white.png'
import image_search from '../../assets/resources/custom/icons/search.png'
import image_search_selected from '../../assets/resources/custom/icons/search_selected.png'
import image_search_selected_white from '../../assets/resources/custom/icons/search_selected_white.png'
import image_underline from '../../assets/resources/custom/icons/underline.png'
import image_underline_selected from '../../assets/resources/custom/icons/underline_selected.png'
import image_underline_selected_white from '../../assets/resources/custom/icons/underline_selected_white.png'
import image_update from '../../assets/resources/custom/icons/update.png'
import image_update_selected from '../../assets/resources/custom/icons/update_selected.png'
import image_update_selected_white from '../../assets/resources/custom/icons/update_selected_white.png'
import image_tests from '../../assets/resources/custom/icons/tests.png'
import image_tests_selected from '../../assets/resources/custom/icons/tests_selected.png'
import image_tests_selected_white from '../../assets/resources/custom/icons/tests_selected_white.png'
import GetStrLocalized from '../../datas/GetStrLocalized'
import { ButtonDataProps, ButtonType } from '../../props/buttondataprops'
import HorizontalSpace from '../util/HorizontalSpace'
import { useNavigate } from 'react-router-dom'


interface NavigationArrowProps {
	btnData: ButtonDataProps,
	language: string,
	alt?: string,
	altId?: string,
	isDarkTheme: boolean
}

function Button({ btnData, language, alt, altId, isDarkTheme }: Readonly<NavigationArrowProps>) {
	const navigate = useNavigate()

	function mouseOutImage(): string {
		switch (btnData.type) {
			case ButtonType.ADD: return image_add;
			case ButtonType.BOLD: return image_bold;
			case ButtonType.ITALIC: return image_italic;
			case ButtonType.LEFT: return image_arrow_left;
			case ButtonType.RIGHT: return image_arrow_right;
			case ButtonType.TOP: return image_arrow_up;
			case ButtonType.DOWN: return image_arrow_down;
			case ButtonType.EDIT: return image_edit;
			case ButtonType.EMOJI: return image_emoji;
			case ButtonType.GIF: return image_gif;
			case ButtonType.HTML: return image_code;
			case ButtonType.DELETE: return image_trash;
			case ButtonType.TEST: return image_tests;
			case ButtonType.SEE: return image_see;
			case ButtonType.EXPORT: return image_export;
			case ButtonType.YES: return image_yes;
			case ButtonType.NO: return image_no;
			case ButtonType.MOVE: return image_move;
			case ButtonType.OK: return image_check;
			case ButtonType.SEARCH: return image_search;
			case ButtonType.UNDERLINE: return image_underline;
			case ButtonType.UPDATE: return image_update;
			case ButtonType.EMPTY: return "";
		}
	}

	function mouseOverImage(): string {
		switch (btnData.type) {
			case ButtonType.ADD: return isDarkTheme ? image_add_selected_white : image_add_selected;
			case ButtonType.BOLD: return isDarkTheme ? image_bold_selected_white : image_bold_selected;
			case ButtonType.ITALIC: return isDarkTheme ? image_italic_selected_white : image_italic_selected;
			case ButtonType.LEFT: return isDarkTheme ? image_arrow_left_selected_white : image_arrow_left_selected;
			case ButtonType.RIGHT: return isDarkTheme ? image_arrow_right_selected_white : image_arrow_right_selected;
			case ButtonType.TOP: return isDarkTheme ? image_arrow_up_selected_white : image_arrow_up_selected;
			case ButtonType.DOWN: return isDarkTheme ? image_arrow_down_selected_white : image_arrow_down_selected;
			case ButtonType.EDIT: return isDarkTheme ? image_edit_selected_white : image_edit_selected;
			case ButtonType.EMOJI: return isDarkTheme ? image_emoji_selected_white : image_emoji_selected;
			case ButtonType.GIF: return isDarkTheme ? image_gif_selected_white : image_gif_selected;
			case ButtonType.HTML: return isDarkTheme ? image_code_selected_white : image_code_selected;
			case ButtonType.DELETE: return isDarkTheme ? image_trash_selected_white : image_trash_selected;
			case ButtonType.TEST: return isDarkTheme ? image_tests_selected_white : image_tests_selected;
			case ButtonType.SEE: return isDarkTheme ? image_see_selected_white : image_see_selected;
			case ButtonType.EXPORT: return isDarkTheme ? image_export_selected_white : image_export_selected;
			case ButtonType.YES: return isDarkTheme ? image_yes_selected_white : image_yes_selected;
			case ButtonType.NO: return isDarkTheme ? image_no_selected_white : image_no_selected;
			case ButtonType.MOVE: return isDarkTheme ? image_move_selected_white : image_move_selected;
			case ButtonType.OK: return isDarkTheme ? image_check_selected_white : image_check_selected;
			case ButtonType.SEARCH: return isDarkTheme ? image_search_selected_white : image_search_selected;
			case ButtonType.UNDERLINE: return isDarkTheme ? image_underline_selected_white : image_underline_selected;
			case ButtonType.UPDATE: return isDarkTheme ? image_update_selected_white : image_update_selected;
			case ButtonType.EMPTY: return "";
		}
	}

	function imageAltId(): string {
		switch (btnData.type) {
			case ButtonType.ADD: return "add_alt";
			case ButtonType.BOLD: return "bold_alt";
			case ButtonType.ITALIC: return "italic_alt";
			case ButtonType.LEFT: return "left_alt";
			case ButtonType.RIGHT: return "right_alt";
			case ButtonType.TOP: return "up_alt";
			case ButtonType.DOWN: return "down_alt";
			case ButtonType.EDIT: return "edit_alt";
			case ButtonType.EMOJI: return "emoji_alt";
			case ButtonType.GIF: return "gif_alt";
			case ButtonType.HTML: return "html_alt";
			case ButtonType.DELETE: return "delete_alt";
			case ButtonType.TEST: return "test_alt";
			case ButtonType.SEE: return "see_alt";
			case ButtonType.EXPORT: return "export_alt";
			case ButtonType.YES: return "yes_alt";
			case ButtonType.NO: return "no_alt";
			case ButtonType.MOVE: return "move_alt";
			case ButtonType.OK: return "ok_alt";
			case ButtonType.SEARCH: return "search_alt";
			case ButtonType.UNDERLINE: return "underline_alt";
			case ButtonType.UPDATE: return "update_alt";
			case ButtonType.EMPTY: return "";
		}
	}

	function onMouseOutCallback(e: React.MouseEvent<HTMLImageElement>) {
		(e.target as HTMLImageElement).src = mouseOutImage();
	}

	function onMouseOverCallback(e: React.MouseEvent<HTMLImageElement>) {
		(e.target as HTMLImageElement).src = mouseOverImage();
	}

	function onClick() {
		if (btnData.url !== undefined && btnData.url !== "")
			navigate(btnData.url!!)
		else
			btnData.onClick()
	}

	if (btnData.type === ButtonType.EMPTY) {
		return (
			<HorizontalSpace width={30} />
		)
	}

	const altMsg = alt !== undefined ? alt : GetStrLocalized(language, altId !== undefined ? altId : imageAltId())

	return (
		<img src={mouseOutImage()} onMouseOut={onMouseOutCallback} onMouseOver={onMouseOverCallback} onClick={onClick}
			height='30px' width='30px' alt={altMsg} title={altMsg} style={{ cursor: 'pointer' }}
			className="hc-not-selectable" />
	)
}

export default Button