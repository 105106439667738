
import React, { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom";
import { getCookie } from "typescript-cookie";
import GetStrLocalized from "../../datas/GetStrLocalized";
import { chatbotAlreadyStartedCookieName, ChatbotContext } from "../../types/chatbotcontext";
import { HcContext, SizeOfWindow } from "../../types/hccontext";

interface EmbeddedChatbotProps {
    language: string,
    hcContext: HcContext,
    chatbotContext: ChatbotContext;
    setIsChatbotWindowOpen: React.Dispatch<React.SetStateAction<boolean>>
}

function EmbeddedChatbot({ language, hcContext, chatbotContext, setIsChatbotWindowOpen }: Readonly<EmbeddedChatbotProps>) {

    const navigate = useNavigate();
    const chatbotBubbeRef = useRef<HTMLDivElement>(null);
    let alreadyOpen = false;
    let nbOfTimeOpen = 0;

    function CloseBubble() {
        if (chatbotBubbeRef.current) {
            chatbotBubbeRef.current.style.display = "none";
        }
    }

    function OpenBubble() {
        if (chatbotBubbeRef.current) {
            chatbotBubbeRef.current.style.display = "block";
            alreadyOpen = true;
        }
    }

    function OpenThenCloseBubble() {
        OpenBubble();
        nbOfTimeOpen = nbOfTimeOpen + 1;
        setTimeout(function () {
            nbOfTimeOpen = nbOfTimeOpen - 1;
            if (nbOfTimeOpen === 0) {
                CloseBubble();
            }
        }, 5000);
    }

    const [onTimeout, setOnTimeout] = useState(false);

    useEffect(() => {
        if (onTimeout && !chatbotContext.started) {
            if (matchMedia('only screen and (min-width: 851px)').matches && !alreadyOpen) {
                OpenThenCloseBubble();
            }
            chatbotContext.setHasAWaitingMessage(true)
        }
    }, [onTimeout]);


    function StartTimeoutToOpenBubble() {
        if ((!chatbotContext.started || matchMedia('only screen and (min-width: 851px)').matches) &&
            getCookie(chatbotAlreadyStartedCookieName) !== "true") {
            setTimeout(function () {
                setOnTimeout(true);
            }, 10000);
        }
    }

    function btnClick() {
        if (hcContext.sizeOfWindow === SizeOfWindow.VERY_SHORT)
            navigate('/' + language + '/' + GetStrLocalized(language, "chatbotFolderName"))
        else
            setIsChatbotWindowOpen(!chatbotContext.isWindowOpen)
    }

    return (
        <React.Fragment>
            <a onLoad={StartTimeoutToOpenBubble} onClick={btnClick} style={{ cursor: 'pointer' }}>
                <img title="Holy Chatter chatbot" style={{ position: 'fixed', bottom: '30px', right: '30px', height: '100px', width: '124px' }} src="/holychatter-chat-window/images/two_bubbles_button.png" alt="chat-button">
                </img>
            </a>
            {
                chatbotContext.hasAWaitingMessage &&
                <div className="hc-dot hc-content-text" style={{
                    position: 'fixed', bottom: '37px', right: '73px', zIndex: 10, backgroundColor: '#dc3545',
                    justifyContent: 'center', alignItems: 'center', color: 'white', textAlign: 'center'
                }}>
                    1
                </div>
            }

            <div style={{ display: 'none' }} ref={chatbotBubbeRef}>
                <img style={{ position: 'fixed', cursor: 'pointer', right: '30px', bottom: '227px', zIndex: 9 }} src="/holychatter-chat-window/images/cross_black.png" onClick={CloseBubble} width="20" height="20" alt='close-cross' />
                <div style={{ position: 'fixed', right: '30px', bottom: '120px', zIndex: 9, backgroundImage: "url(/holychatter-chat-window/images/bot_withouttriangle_bottom_right.png)", backgroundPosition: "right 130px bottom 0px", backgroundSize: "50px 24px", backgroundRepeat: "no-repeat", paddingBottom: "14px" }}>
                    <div style={{ borderRadius: '10px', fontSize: '15px', padding: '10px', backgroundColor: '#f1f0f0', minHeight: '50px', maxWidth: '730px', fontWeight: 'normal' }}>
                        Bonjour je suis le chatbot Théophile. <img src="/holychatter-chat-window/images/smile.png" width="20" height="20" alt='smile-smiley' /><br />
                        Si vous voulez discuter sur la foi chrétienne,<br />
                        vous pouvez cliquer sur le bouton ci-dessous.
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default EmbeddedChatbot



