import './InputWithStyle.scss';
import image_magnifying_glass from '../../assets/resources/custom/images/magnifying_glass_white.png'

interface InputWithStyleProps extends React.InputHTMLAttributes<HTMLInputElement> {
	placeholder?: string;
	onValidation?: () => void;
	value: string | undefined;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	isDarkTheme: boolean;
	height?: string;
	width?: string;
	hasSearchButton?: boolean;
	autoFocus?: boolean;
	title?: string;
}

function InputWithStyle({ placeholder = "", onValidation, value, onChange, isDarkTheme, height = '40px', width = '100%', hasSearchButton = false, autoFocus = false, title = '' }: Readonly<InputWithStyleProps>) {

	function onInputKeyPressed(e: React.KeyboardEvent<HTMLDivElement>) {
		if (e.keyCode === 13 && onValidation) {
			e.preventDefault();
			onValidation();
		}
	}

	const validationBtnSize = hasSearchButton && onValidation ? height : '0px'

	const containerStyle: React.CSSProperties = { height: height, width: width, position: 'relative' };
	const inputStyle: React.CSSProperties = {
		paddingLeft: 10, paddingRight: validationBtnSize
	};
	const buttonStyle: React.CSSProperties = { display: 'flex', justifyContent: 'center', alignItems: 'center', width: height };

	return (
		<span style={containerStyle} >
			<div style={containerStyle}>
				<input type="text" className={`input hc-input-search ${isDarkTheme ? 'hc-dark-theme' : 'hc-light-theme'}`} placeholder={placeholder}
					value={value} onChange={onChange} onKeyUp={onInputKeyPressed} style={inputStyle} autoFocus={autoFocus} title={title} />
				{
					hasSearchButton && onValidation &&
					<div className="hc-input-search-btn" style={buttonStyle}>
						<img src={image_magnifying_glass} alt="search" height="20px" width="20px" onClick={() => {
							onValidation()
						}} />
					</div>
				}
			</div>
		</span>
	)

}

export default InputWithStyle


