
export interface ChatbotEditionProps {
  nb: number | null;
  name: string;
  isHidden: boolean;
  logoImage: string;
  userBackgroundBubbleColor: string;
  useDefaultContent: boolean;
  allowToJumpFromLocalMessageToDefaultRecommendations: boolean;
  useDefaultProactivity: boolean;
  showOptionMenu: boolean;
  url: string;
  fallbackEngine: string;
  sourcesNotToDisplay: string[];
  goals: string[];
  changeSubjectInfos: string[];
}


export class ChatbotEdition {
  value: ChatbotEditionProps;
  setNb: React.Dispatch<React.SetStateAction<number | null>>;
  setName: React.Dispatch<React.SetStateAction<string>>;
  setIsHidden: React.Dispatch<React.SetStateAction<boolean>>;
  setLogoImage: React.Dispatch<React.SetStateAction<string>>;
  setUserBackgroundBubbleColor: React.Dispatch<React.SetStateAction<string>>;
  setUseDefaultContent: React.Dispatch<React.SetStateAction<boolean>>;
  setAllowToJumpFromLocalMessageToDefaultRecommendations: React.Dispatch<React.SetStateAction<boolean>>;
  setUseDefaultProactivity: React.Dispatch<React.SetStateAction<boolean>>;
  setShowOptionMenu: React.Dispatch<React.SetStateAction<boolean>>;
  setUrl: React.Dispatch<React.SetStateAction<string>>;
  setFallbackEngine: React.Dispatch<React.SetStateAction<string>>;
  private readonly setSourcesNotToDisplay: React.Dispatch<React.SetStateAction<string[]>>;
  private readonly setSourcesNotToDisplayStr: React.Dispatch<React.SetStateAction<string>>;
  private readonly setGoals: React.Dispatch<React.SetStateAction<string[]>>;
  private readonly setGoalsStr: React.Dispatch<React.SetStateAction<string>>;
  private readonly setChangeSubjectInfos: React.Dispatch<React.SetStateAction<string[]>>;
  private readonly setChangeSubjectInfosStr: React.Dispatch<React.SetStateAction<string>>;

  constructor(
    value: ChatbotEditionProps,
    setNb: React.Dispatch<React.SetStateAction<number | null>>,
    setName: React.Dispatch<React.SetStateAction<string>>,
    setIsHidden: React.Dispatch<React.SetStateAction<boolean>>,
    setLogoImage: React.Dispatch<React.SetStateAction<string>>,
    setUserBackgroundBubbleColor: React.Dispatch<React.SetStateAction<string>>,
    setUseDefaultContent: React.Dispatch<React.SetStateAction<boolean>>,
    setAllowToJumpFromLocalMessageToDefaultRecommendations: React.Dispatch<React.SetStateAction<boolean>>,
    setUseDefaultProactivity: React.Dispatch<React.SetStateAction<boolean>>,
    setShowOptionMenu: React.Dispatch<React.SetStateAction<boolean>>,
    setUrl: React.Dispatch<React.SetStateAction<string>>,
    setFallbackEngine: React.Dispatch<React.SetStateAction<string>>,
    setSourcesNotToDisplay: React.Dispatch<React.SetStateAction<string[]>>,
    setSourcesNotToDisplayStr: React.Dispatch<React.SetStateAction<string>>,
    setGoals: React.Dispatch<React.SetStateAction<string[]>>,
    setGoalsStr: React.Dispatch<React.SetStateAction<string>>,
    setChangeSubjectInfos: React.Dispatch<React.SetStateAction<string[]>>,
    setChangeSubjectInfosStr: React.Dispatch<React.SetStateAction<string>>
  ) {
    this.value = value;
    this.setNb = setNb;
    this.setName = setName;
    this.setIsHidden = setIsHidden;
    this.setLogoImage = setLogoImage;
    this.setUserBackgroundBubbleColor = setUserBackgroundBubbleColor;
    this.setUseDefaultContent = setUseDefaultContent;
    this.setAllowToJumpFromLocalMessageToDefaultRecommendations = setAllowToJumpFromLocalMessageToDefaultRecommendations;
    this.setUseDefaultProactivity = setUseDefaultProactivity;
    this.setShowOptionMenu = setShowOptionMenu;
    this.setUrl = setUrl;
    this.setFallbackEngine = setFallbackEngine;
    this.setSourcesNotToDisplay = setSourcesNotToDisplay;
    this.setSourcesNotToDisplayStr = setSourcesNotToDisplayStr;
    this.setGoals = setGoals;
    this.setGoalsStr = setGoalsStr;
    this.setChangeSubjectInfos = setChangeSubjectInfos;
    this.setChangeSubjectInfosStr = setChangeSubjectInfosStr;
  }

  set(value: ChatbotEditionProps) {
    this.setNb(value.nb)
    this.setName(value.name)
    this.setIsHidden(value.isHidden)
    this.setLogoImage(value.logoImage)
    this.setUserBackgroundBubbleColor(value.userBackgroundBubbleColor)
    this.setUseDefaultContent(value.useDefaultContent)
    this.setAllowToJumpFromLocalMessageToDefaultRecommendations(value.allowToJumpFromLocalMessageToDefaultRecommendations)
    this.setUseDefaultProactivity(value.useDefaultProactivity)
    this.setShowOptionMenu(value.showOptionMenu)
    this.setUrl(value.url)
    this.setFallbackEngine(value.fallbackEngine)
    this.setSourcesNotToDisplayFn([ ...value.sourcesNotToDisplay])
    this.setGoalsFn([ ...value.goals])
    this.setChangeSubjectInfosFn([ ...value.changeSubjectInfos ])
  }

  setSourcesNotToDisplayFn(elts: string[]) {
    this.setSourcesNotToDisplay(elts);
    this.setSourcesNotToDisplayStr(JSON.stringify(elts))
  }

  setGoalsFn(elts: string[]) {
    this.setGoals(elts);
    this.setGoalsStr(JSON.stringify(elts))
  }

  setChangeSubjectInfosFn(elts: string[]) {
    this.setChangeSubjectInfos(elts);
    this.setChangeSubjectInfosStr(JSON.stringify(elts))
  }
}
