
interface HorizontalSpaceProps {
    width?: number;
};


function HorizontalSpace({width = 15}: Readonly<HorizontalSpaceProps>) {

    return (
        <span style={{color: 'red', marginRight: width, display: 'inline-block'}}></span>
    )
}

export default HorizontalSpace;