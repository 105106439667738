import './stars.scss';
import { StarsStats, starsStatsToValue, valueToStarImage } from './starsUtil';


interface StarsProps {
	starsStats: StarsStats;
	size: string;
}

function Stars({ starsStats, size }: Readonly<StarsProps>) {

	const val = starsStatsToValue(starsStats)

	return (
		<span className={`stars-list-inline stars-list-size-${size}`}>
			<img src={valueToStarImage(0, val)} alt={"Star 1"} className={`star-item star-item-size-${size}`} />
			<img src={valueToStarImage(1, val)} alt={"Star 2"} className={`star-item star-item-size-${size}`} />
			<img src={valueToStarImage(2, val)} alt={"Star 3"} className={`star-item star-item-size-${size}`} />
			<img src={valueToStarImage(3, val)} alt={"Star 4"} className={`star-item star-item-size-${size}`} />
			<img src={valueToStarImage(4, val)} alt={"Star 5"} className={`star-item star-item-size-${size}`} />
		</span>
	)
}

export default Stars