import { Link } from "react-router-dom";
import { PlannerFact } from "../../props/FactsProps";
import { DisplayPannerFact } from "./DisplayPlannerFact";
import GetStrLocalized from "../../datas/GetStrLocalized";


interface DisplayPannerFactWithLinkProps {
	fact: PlannerFact;
	language: string;
}

export function DisplayPannerFactWithLink({ fact, language }: Readonly<DisplayPannerFactWithLinkProps>) {
	const hasArguments = fact.arguments.length > 0
	const hasValue = fact.value !== ""
	const factUrlPage = fact.name + ((hasArguments || hasValue) ? "?" : "") +
		(hasArguments ? "arguments" : "") + // TODO: write the arguments
		(hasValue ? "value=" + fact.value : "")

	return (
		<Link to={"/" + language + "/" + GetStrLocalized(language, "factsFolderName") + "/" + factUrlPage}><DisplayPannerFact fact={fact} /></Link>
	)
}
