import * as React from "react";
import EditionLabel from "../title/EditionLabel";
import Button from "../button/Button";
import { ButtonType } from "../../props/buttondataprops";
import DisplayArticleTest, { ArticleTestProps } from "../../types/ArticleTests";
import TestEditionModal from "../modal/TestEditionModal";
import { HcContext } from "../../types/hccontext";
import { createErrorModalContent } from "../../props/ModalContentProps";



interface TestsListViewerProps {
  language: string;
  tests: ArticleTestProps[];
  setTests: (r: ArticleTestProps[]) => any;
  articleId?: string;
  hcContext: HcContext;
  nbOfTestsTotal?: number;
  maxNbTestsToDisplay: number;
  setProcessingModifications: (v: boolean) => any;
}

class ModifyTestsPostContentType {
  language: string;
  userKey: string;
  tests: ArticleTestProps[];

  constructor(
    language: string,
    userKey: string,
    tests: ArticleTestProps[]) {
    this.language = language
    this.userKey = userKey
    this.tests = tests
  }
}

export default function TestsListViewer(
  { language, tests, setTests, articleId, hcContext, nbOfTestsTotal, maxNbTestsToDisplay, setProcessingModifications }: Readonly<TestsListViewerProps>
) {
  const [selectedElts, setSelectedElts] = React.useState<number[]>([])
  const [showModal, setShowModal] = React.useState(false)
  const [addOrEdit, setAddOrEdit] = React.useState<boolean>(true)
  const [mutipleSelectionMode, setMutipleSelectionMode] = React.useState<boolean>(false)
  const [testEdition, setTestEdition] = React.useState<ArticleTestProps>()

  function addOrRemoveSelectedEltWithCheckbox(addOrRemove: boolean, index: number) {
    if (addOrRemove) {
      if (!selectedElts.includes(index)) {
        if (!mutipleSelectionMode)
          setSelectedElts([index])
        else
          setSelectedElts([...selectedElts, index])
      }
      setMutipleSelectionMode(true)
    } else {
      const eltIndex = selectedElts.indexOf(index)
      let eltsCopied = selectedElts;
      eltsCopied.splice(eltIndex, 1)
      setSelectedElts(eltsCopied)
      setMutipleSelectionMode(eltsCopied.length > 0)
    }
    setTests([...tests]) // To force refresh of tests display
  }

  function addOrRemoveSelectedEltWithBackgroundClick(index: number) {
    if (mutipleSelectionMode)
      return
    if (!selectedElts.includes(index)) {
      setSelectedElts([index])
    } else {
      setSelectedElts([])
    }
  }

  function sendModifiedTests(modifiedTests: ArticleTestProps[]) {
    if (!hcContext.userToken)
      return
    setProcessingModifications(true)
    hcContext.setBlackCoverOfContent(true)
    const postContent = new ModifyTestsPostContentType(language, hcContext.userToken, modifiedTests)
    let postContentStr = JSON.stringify(postContent)
    fetch(hcContext.backendUrl + "/modify_tests", {
      method: 'POST',
      body: postContentStr,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
    })
      .then(res => res.json())
      .then(jsonData => {
        setProcessingModifications(false)
        hcContext.setBlackCoverOfContent(false)
        if (jsonData.errorMessage)
          hcContext.setInfo(createErrorModalContent(language, jsonData.errorMessage))
      })
      .catch(rejected => {
        setProcessingModifications(false)
        hcContext.setBlackCoverOfContent(false)
        hcContext.setInfo(createErrorModalContent(language, rejected.message))
      });
  }

  return (
    <>
      <table>
        <tbody>
          <tr>
            <td>
              <EditionLabel language={"fr"} textId="tests" />
              {
                (tests && nbOfTestsTotal !== undefined) ?
                  <>&nbsp;&nbsp;&nbsp;({tests.length}&nbsp;/&nbsp;{nbOfTestsTotal})</>
                  :
                  <>
                    {
                      tests &&
                      tests.length > 0 &&
                      <>&nbsp;&nbsp;&nbsp;({tests.length})</>
                    }
                  </>
              }
            </td>
            <td style={{ textAlign: 'center', width: '100%' }}></td>
            {
              articleId === undefined && // If we are not in an article page (so we are in the tests page)
              selectedElts.length >= 1 &&
              <td style={{ paddingRight: '15px' }}><Button btnData={{
                type: ButtonType.UPDATE, onClick: () => {
                  sendModifiedTests(selectedElts.map(id => tests[id]))
                  var cpTests = tests; // Remove from display updated tests
                  selectedElts.sort(function (a, b) { return b - a; }) // Sort descending order (before removal)
                    .forEach(sElt => cpTests.splice(sElt, 1)) // Removal
                  setTests(cpTests)
                  setSelectedElts([])
                  setMutipleSelectionMode(false)
                }
              }} language={language} altId="updateTheExpectedResult" isDarkTheme={hcContext.isDarkTheme} /></td>
            }
            {
              selectedElts.length === 1 &&
              <>
                <td style={{ paddingRight: '15px' }}><Button btnData={{
                  type: ButtonType.EDIT, onClick: () => {
                    setAddOrEdit(false)
                    setTestEdition(tests[selectedElts[0]])
                    setShowModal(true)
                  }
                }} language={language} isDarkTheme={hcContext.isDarkTheme} /></td>
                {
                  articleId !== undefined &&
                  <td style={{ paddingRight: '15px' }}><Button btnData={{
                    type: ButtonType.DELETE, onClick: () => {
                      var refCopied = tests;
                      refCopied.splice(selectedElts[0], 1)
                      setTests(refCopied)
                      setSelectedElts([])
                      setMutipleSelectionMode(false)
                    }
                  }} language={language} isDarkTheme={hcContext.isDarkTheme} /></td>
                }
              </>
            }
            {
              articleId !== undefined &&
              <td style={{ paddingRight: '15px' }}><Button btnData={{
                type: ButtonType.ADD, onClick: () => {
                  setAddOrEdit(true)
                  setTestEdition({
                    articleId: articleId, input: "", output: "", outputReferences: "",
                    isOk: "true", expected: "", expectedReferences: ""
                  })
                  setShowModal(true)
                }
              }} language={language} isDarkTheme={hcContext.isDarkTheme} /></td>
            }
            {
              articleId === undefined && // If we are not in an article page (so we are in the tests page)
              tests.length >= 1 &&
              <td style={{ paddingRight: '15px' }}><Button btnData={{
                type: ButtonType.OK, onClick: () => {
                  var newselectedElts: number[] = []
                  tests.forEach((elt, index) => newselectedElts = [...newselectedElts, index])
                  setSelectedElts(newselectedElts)
                  setMutipleSelectionMode(true)
                  setTests([...tests]) // To force refresh of tests display
                }
              }} language={language} altId="selectAllTests" isDarkTheme={hcContext.isDarkTheme} /></td>
            }
          </tr>
        </tbody>
      </table>

      {
        tests.length > 0 &&
        <>
          {tests.slice(0, maxNbTestsToDisplay).map((item, index) => {
            return <span key={"test-list-elt-" + index}><br /><span style={{ listStyleType: "none", cursor: 'pointer' }} onClick={() => addOrRemoveSelectedEltWithBackgroundClick(index)} >
              {
                selectedElts.includes(index) ?
                  <div style={{ backgroundColor: 'grey' }} ><DisplayArticleTest test={item} language={language}
                    isChecked={mutipleSelectionMode} setIsChecked={(b: boolean) => addOrRemoveSelectedEltWithCheckbox(b, index)} /></div>
                  :
                  <div><DisplayArticleTest test={item} language={language}
                    isChecked={false} setIsChecked={(b: boolean) => addOrRemoveSelectedEltWithCheckbox(b, index)} /></div>
              }
            </span><br /><br /></span>
          })}
        </>
      }


      <TestEditionModal
        language={language}
        addOrEdit={addOrEdit}
        show={showModal}
        setShow={setShowModal}
        articleId={testEdition?.articleId ? testEdition?.articleId : ""}
        inputTest={testEdition?.input ? testEdition?.input : ""}
        hcContext={hcContext}
        onTestValidated={(elt) => {
          if (addOrEdit) { // Add the test
            setTests([...tests, elt])
          } else if (selectedElts.length === 1) {
            if (articleId === undefined) { // If we are not in an article page (so we are in the tests page)
              sendModifiedTests([elt]) // We ask the backend api to update the test
              let cpTests = tests; // Remove from display test set with a new reference
              cpTests.splice(selectedElts[0], 1)
              setTests(cpTests)
            } else { // Update the test
              let cpTests = tests
              cpTests[selectedElts[0]] = elt
              setTests(cpTests)
            }
          }
          setSelectedElts([])
          setMutipleSelectionMode(false)
        }}
      />

    </>

  );
}