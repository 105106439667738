import React, { ChangeEvent, useEffect } from 'react'
import { useState } from 'react'
import GetHtmlStrLocalized from '../../datas/GetHtmlStrLocalized'
import Modal, { ModalBody, ModalFooter, ModalHeader } from './Modal'
import Select from 'react-select';
import { InsideBookRangesProps, ReferenceEdition, ReferenceEditionProps, createEmptyInsideBookRangesProps } from '../../props/ReferenceEditionProps';
import GetStrLocalized from '../../datas/GetStrLocalized';
import { BibleBookProps } from '../../props/BibleBookProps';
import { HcInput } from '../input/HcInput';
import { HcContext, SelectEltProps } from '../../types/hccontext';
import { ReferenceProps } from '../../props/referenceprops';
import DisplayAReference from '../util/DisplayAReference';
import Button from '../button/Button';
import { ButtonType } from '../../props/buttondataprops';
import VerticalDisplay from '../util/VerticalDisplay';
import { createErrorModalContent } from '../../props/ModalContentProps';
import InputWithStyle from '../banner/InputWithStyle';


interface ReferenceEditionModalProps {
    language: string;
    addOrEdit: boolean;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    refEdition: ReferenceEdition;
    onReferenceValidated: (r: ReferenceEditionProps) => any;
    bibleBooks: BibleBookProps[];
    linksFromCorpus: SelectEltProps[];
    hcContext: HcContext;
    articleId: string;
}


class ReferencePostContentType {
    language: string;
    articleId: string;
    bookScope: string;
    book: string;
    insideBookRanges: InsideBookRangesProps[];

    constructor(
        language: string,
        articleId: string,
        bookScope: string,
        book: string,
        insideBookRanges: InsideBookRangesProps[]) {
        this.language = language
        this.articleId = articleId
        this.bookScope = bookScope
        this.book = book
        this.insideBookRanges = insideBookRanges
    }
}

function ReferenceEditionModal({ language, addOrEdit, show, setShow, refEdition, onReferenceValidated, bibleBooks, linksFromCorpus,
    hcContext, articleId }: Readonly<ReferenceEditionModalProps>) {
    const typesOfRef = [{ value: "bible", label: "bible" }, { value: "ccc", label: "catechismOfTheCatholicChurch" }, { value: "site", label: "site" }]
    const [bookSelectionValue, setBookSelectionValue] = useState<SelectEltProps | null>(typesOfRef[0]);
    const [bibleBookSelected, setBibleBookSelected] = useState<BibleBookProps | null>(null);
    const [linksFromCorpusSelected, setLinksFromCorpusSelected] = useState<SelectEltProps | null>(linksFromCorpus[0]);
    const [insideBookRanges, setInsideBookRanges] = useState<InsideBookRangesProps[]>([])
    const [url, setUrl] = useState<string>("");
    const [referenceEdition, setReferenceEdition] = useState<string>("")
    const [referenceContent, setReferenceContent] = useState<ReferenceProps | undefined>(undefined)
    const [proposeToAddNewIbr, setProposeToAddNewIbr] = useState<boolean>(false)
    const [proposeToRemoveLastIbr, setProposeToRemoveLastIbr] = useState<boolean>(false)
    const [firstNumber, setFirstNumber] = useState<number | undefined>(undefined)
    const [lastNumber, setLastNumber] = useState<number | undefined>(undefined)
    const [afterShow, setAfterShow] = useState<boolean>(false)


    useEffect(() => {
        if (show) {
            setReferenceEdition("")
            setReferenceContent(undefined)
            typesOfRef.forEach((elt) => {
                if (elt.value === refEdition.value.type || elt.value === refEdition.value.bookScope)
                    setBookSelectionValue(elt);
            })
            setBibleBookSelected(null)
            bibleBooks.forEach((elt) => {
                if (elt.name === refEdition.value.book)
                    setBibleBookSelected(elt)
            })
            linksFromCorpus.forEach((elt) => {
                if (elt.value === refEdition.value.linkFromCorpus)
                    setLinksFromCorpusSelected(elt);
            })
            if (refEdition.value.bookScope === "bible") {
                setInsideBookRanges(refEdition.value.insideBookRanges)
                setFirstNumber(undefined)
                setLastNumber(undefined)
            } else if (refEdition.value.bookScope === "ccc") {
                setInsideBookRanges([createEmptyInsideBookRangesProps()])
                if (refEdition.value.insideBookRanges.length >= 1) {
                    setFirstNumber(Number(refEdition.value.insideBookRanges[0].verseBegin))
                    setLastNumber(Number(refEdition.value.insideBookRanges[0].verseEnd))
                } else {
                    setFirstNumber(undefined)
                    setLastNumber(undefined)
                }
            } else {
                setInsideBookRanges([createEmptyInsideBookRangesProps()])
                setFirstNumber(undefined)
                setLastNumber(undefined)
            }
            setUrl(refEdition.value.url)
            setAfterShow(true)
        } else {
            setAfterShow(false)
        }
    }, [show]);

    function parseReference() {
        fetch(hcContext.backendUrl + "/parse_bible_reference?l=" + language + "&reference=" + referenceEdition)
            .then(res => res.json())
            .then(jsonData => {
                if (jsonData.errorMessage) {
                    hcContext.setInfo(createErrorModalContent(language, jsonData.errorMessage))
                    return
                }
                if (jsonData.insideBookRanges === "")
                    jsonData.insideBookRanges = []
                setBibleBookSelected(jsonData.bibleBook)
                setInsideBookRanges(jsonData.insideBookRanges)
            })
            .catch(rejected => {
                console.log(rejected);
            });
    }

    useEffect(() => {
        if (afterShow) {
            const bookScope = bookSelectionValue?.value
            if (bookScope === null || bookScope === undefined || bookScope === "site")
                return
            if (bibleBookSelected === null)
                return

            let localInsideBookRanges = insideBookRanges
            if (bookScope === "ccc") {
                localInsideBookRanges = [{
                    chapter: "",
                    chapterEnd: "",
                    verseBegin: firstNumber ? firstNumber.toString() : "",
                    verseEnd: lastNumber ? lastNumber.toString() : "",
                }]
            }

            const postContent = new ReferencePostContentType(language, articleId, bookScope, bibleBookSelected.name, localInsideBookRanges)
            fetch(hcContext.backendUrl + "/reference_content", {
                method: 'POST',
                body: JSON.stringify(postContent),
                headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
            }).then(res => res.json())
                .then(jsonData => {
                    setReferenceContent(jsonData.ref)
                    setReferenceEdition(jsonData.ref.reference)
                })
                .catch(rejected => {
                    console.log(rejected);
                });
        }
    }, [afterShow, bibleBookSelected, insideBookRanges, bookSelectionValue, firstNumber, lastNumber]);


    useEffect(() => {
        if (insideBookRanges.length === 1) {
            setProposeToAddNewIbr(insideBookRanges[0].chapter !== "" && insideBookRanges[0].verseBegin !== "")
            setProposeToRemoveLastIbr(false)
        } else if (insideBookRanges.length > 1) {
            setProposeToAddNewIbr(insideBookRanges[insideBookRanges.length - 1].verseBegin !== "")
            setProposeToRemoveLastIbr(true)
        } else {
            setProposeToAddNewIbr(false)
            setProposeToRemoveLastIbr(false)
        }
    }, [insideBookRanges]);


    return (
        <Modal
            language={language}
            show={show}
            setShow={setShow}
            isDarkTheme={hcContext.isDarkTheme}
            display='flex'
            contentWidth="90%"
        >
            <ModalHeader>
                <h3><GetHtmlStrLocalized language={language} textId={addOrEdit ? "addAReference" : "modifyAReference"} /></h3>
            </ModalHeader>
            <ModalBody>

                <b><GetHtmlStrLocalized language={language} textId="typeOfReference" /></b>
                <Select
                    value={bookSelectionValue}
                    onChange={(elt: SelectEltProps | null) => {
                        setBookSelectionValue(elt);
                    }}
                    getOptionValue={(elt: SelectEltProps) => elt.value}
                    getOptionLabel={(elt: SelectEltProps) => GetStrLocalized(language, elt.label)}
                    options={typesOfRef}
                    styles={hcContext.getStylesForSelect<SelectEltProps>()}
                />
                <br />

                {
                    bookSelectionValue?.value === "bible" &&
                    <>
                        <VerticalDisplay
                            elts={[
                                {
                                    component: <>
                                        <b><GetHtmlStrLocalized language={language} textId="reference" /></b>
                                        <InputWithStyle value={referenceEdition}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                setReferenceEdition(event.target.value)
                                            }} isDarkTheme={hcContext.isDarkTheme} height={hcContext.defaultLineEditHeight()} autoFocus={true}
                                            title={GetStrLocalized(language, 'reference')} />
                                        {
                                            referenceEdition !== referenceContent?.reference &&
                                            <button onClick={() => {
                                                parseReference()
                                            }}>
                                                <GetHtmlStrLocalized language={language} textId="validate" />
                                            </button>
                                        }
                                    </>,
                                    width: '50%',
                                    paddingRight: '50px'
                                },
                                {
                                    component: <>
                                        <b><GetHtmlStrLocalized language={language} textId="book" /></b>
                                        <Select
                                            value={bibleBookSelected}
                                            onChange={(elt: BibleBookProps | null) => {
                                                setBibleBookSelected(elt)
                                            }}
                                            getOptionValue={(elt: BibleBookProps) => elt.name}
                                            getOptionLabel={(elt: BibleBookProps) => elt.prettyPrinted}
                                            options={bibleBooks}
                                            styles={hcContext.getStylesForSelect<BibleBookProps>()}
                                        />
                                        <br />

                                        <b><GetHtmlStrLocalized language={language} textId="chapetersAndVerses" /></b>
                                        {
                                            insideBookRanges.map((ibr, ibrIndex) => {
                                                return (
                                                    <div key={"ibr-" + ibrIndex} style={{ fontSize: '20px' }}>
                                                        <HcInput width='35px' title={GetStrLocalized(language, 'firstChapter')} value={ibr.chapter} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                            insideBookRanges[ibrIndex].chapter = event.target.value
                                                            setInsideBookRanges([...insideBookRanges])
                                                        }} />&nbsp;,&nbsp;
                                                        <HcInput width='35px' title={GetStrLocalized(language, 'firstVerse')} value={ibr.verseBegin} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                            insideBookRanges[ibrIndex].verseBegin = event.target.value
                                                            setInsideBookRanges([...insideBookRanges])
                                                        }} />&nbsp;,&nbsp;
                                                        <HcInput width='35px' title={GetStrLocalized(language, 'lastChapter')} value={ibr.chapterEnd} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                            insideBookRanges[ibrIndex].chapterEnd = event.target.value
                                                            setInsideBookRanges([...insideBookRanges])
                                                        }} />&nbsp;,&nbsp;
                                                        <HcInput width='35px' title={GetStrLocalized(language, 'lastVerse')} value={ibr.verseEnd} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                            insideBookRanges[ibrIndex].verseEnd = event.target.value
                                                            setInsideBookRanges([...insideBookRanges])
                                                        }} />
                                                    </div>
                                                )
                                            })
                                        }
                                        {
                                            proposeToAddNewIbr &&
                                            <Button btnData={{
                                                type: ButtonType.ADD, onClick: () => {
                                                    setInsideBookRanges([...insideBookRanges, createEmptyInsideBookRangesProps()])
                                                }
                                            }} language={language} isDarkTheme={hcContext.isDarkTheme} />
                                        }
                                        {
                                            proposeToRemoveLastIbr &&
                                            <Button btnData={{
                                                type: ButtonType.DELETE, onClick: () => {
                                                    insideBookRanges.splice(insideBookRanges.length - 1, 1)
                                                    setInsideBookRanges([...insideBookRanges])
                                                }
                                            }} language={language} isDarkTheme={hcContext.isDarkTheme} />
                                        }
                                    </>,
                                    width: '50%'
                                }
                            ]}
                        />
                        <br />
                        <br />

                        <b><GetHtmlStrLocalized language={language} textId="priorityFromAnExcerptFromTheBible" /></b>
                        <Select
                            value={linksFromCorpusSelected}
                            onChange={(elt: SelectEltProps | null) => {
                                setLinksFromCorpusSelected(elt)
                            }}
                            getOptionValue={(elt: SelectEltProps) => elt.value}
                            getOptionLabel={(elt: SelectEltProps) => elt.label}
                            options={linksFromCorpus}
                            styles={hcContext.getStylesForSelect<SelectEltProps>()}
                        />
                    </>
                }

                {
                    bookSelectionValue?.value === "ccc" &&
                    <>
                        <b><GetHtmlStrLocalized language={language} textId="firstNumber" /></b>
                        <HcInput value={firstNumber ? firstNumber.toString() : ""} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setFirstNumber(Number(event.target.value))
                        }} />
                        <br />
                        <br />

                        <b><GetHtmlStrLocalized language={language} textId="lastNumber" /></b>
                        <HcInput value={lastNumber ? lastNumber.toString() : ""} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setLastNumber(Number(event.target.value))
                        }} />
                    </>
                }

                {
                    bookSelectionValue?.value === "site" &&
                    <>
                        <b><GetHtmlStrLocalized language={language} textId="url" /></b>
                        <HcInput value={url || ""} onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setUrl(e.target.value);
                        }} />
                        <br />
                    </>
                }

                {
                    bookSelectionValue?.value !== "site" &&
                    bookSelectionValue !== null &&
                    referenceContent &&
                    <>
                        <br />
                        <br />
                        <DisplayAReference language={language} type={bookSelectionValue.value} reference={referenceContent} allowToDisplayLinkFromCorpus={true} hcContext={hcContext} />
                    </>
                }
            </ModalBody>
            <br />
            <ModalFooter>
                <button onClick={() => {
                    if (bookSelectionValue?.value === "bible") {
                        onReferenceValidated({
                            type: "book_scope",

                            bookScope: "bible",
                            book: bibleBookSelected !== null ? bibleBookSelected.name : "",
                            insideBookRanges: insideBookRanges,
                            linkFromCorpus: linksFromCorpusSelected !== null ? linksFromCorpusSelected.value : "",

                            url: "",
                            str: referenceContent?.reference ? referenceContent?.reference : ""
                        })
                    }
                    else if (bookSelectionValue?.value === "ccc") {
                        onReferenceValidated({
                            type: "book_scope",

                            bookScope: "ccc",
                            book: "",
                            insideBookRanges: [{
                                chapter: "",
                                chapterEnd: "",
                                verseBegin: (firstNumber && !Number.isNaN(firstNumber)) ? firstNumber.toString() : "",
                                verseEnd: (lastNumber && !Number.isNaN(lastNumber)) ? lastNumber.toString() : ""
                            }],
                            linkFromCorpus: "",

                            url: "",
                            str: referenceContent?.reference ? referenceContent?.reference : ""
                        })
                    }
                    else if (bookSelectionValue?.value === "site") {
                        onReferenceValidated({
                            type: "site",
                            bookScope: "",
                            book: "",
                            insideBookRanges: [],
                            linkFromCorpus: "",

                            url: url || "",
                            str: url || ""
                        })
                    }
                    setShow(false)
                }}>
                    <GetHtmlStrLocalized language={language} textId="validate" />
                </button>
            </ModalFooter>
        </Modal>
    );
}

export default ReferenceEditionModal;