import React from 'react'
import ScrollToTop from './ScrollToTop';
import HorizontalSpace from './HorizontalSpace';


interface PageContentProps {
    children: React.ReactNode;
}


const PageContent: React.FC<PageContentProps> = ({ children }) => {

    return (
        <>
            <ScrollToTop />
            <table style={{ width: '100%' }}>
                <thead className="visually-hidden"><tr><th></th></tr></thead>
                <tbody>
                    <tr>
                        <td className='hc-blank-for-menu'>
                        </td>
                        <td>
                            <div className='hc-main'>
                                <br />
                                {children}
                                <div className="hc-content-text" style={{ textAlign: 'center', margin: '0 auto', marginTop: 50, marginBottom: 25 }}>
                                    holychatter@gmail.com
                                </div>
                                <br />
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    )
}

export default PageContent