import { Link } from "react-router-dom"
import GetStrLocalized from '../../datas/GetStrLocalized'
import GetHtmlStrLocalized from "../../datas/GetHtmlStrLocalized"
import ReadingContent from "./ReadingContent"
import { ReferenceProps } from "../../props/referenceprops"
import { HcContext } from "../../types/hccontext"


interface DisplayAReferenceProps {
    language: string;
    type: string;
    reference: ReferenceProps;
    allowToDisplayLinkFromCorpus : boolean;
    hcContext: HcContext;
}

function DisplayAReference({ language, type, reference, allowToDisplayLinkFromCorpus, hcContext }: Readonly<DisplayAReferenceProps>) {

    return (
        <>
            <div className='hc-content-text'>
                <b>
                    <GetHtmlStrLocalized language={language} textId={type === "bible" ? "reference" : "catechismOfTheCatholicChurchNum"} />&nbsp;
                    <Link to={"/" + language + "/" + GetStrLocalized(language, "readingsFolderName") + "/" + GetStrLocalized(language, type === "bible" ? "bibleFolderName" : "catechismOfTheCatholicChurchFolderName") + "/" + reference.referenceUrlChapterName}>{reference.reference}</Link>
                </b>
            </div>
            <br />
            <br />
            {
                reference.content.length === 0 ?
                    <div className='hc-content-text'>
                        <Link to={"/" + language + "/" + GetStrLocalized(language, "readingsFolderName") + "/" + GetStrLocalized(language, type === "bible" ? "bibleFolderName" : "catechismOfTheCatholicChurchFolderName") + "/" + reference.referenceUrlChapterName}>Lire le chapitre</Link>
                    </div>
                    :
                    <ReadingContent language={language} type={type} content={reference.content} allowToDisplayLinkFromCorpus={allowToDisplayLinkFromCorpus} hcContext={hcContext} />
            }
        </>
    )
}

export default DisplayAReference