import React, { useEffect } from 'react'
import { useState } from 'react'
import Modal, { ModalBody, ModalFooter, ModalHeader } from './Modal'
import GetHtmlStrLocalized from '../../datas/GetHtmlStrLocalized';
import GetStrLocalized from '../../datas/GetStrLocalized';
import InformationModal from './InformationModal';
import { HcContext } from '../../types/hccontext';


interface SelectSourceImageModalProps {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    language: string;
    onImageSelected: (s: string) => any;
    hcContext: HcContext;
}


function SelectSourceImageModal({ show, setShow, language, onImageSelected, hcContext }: Readonly<SelectSourceImageModalProps>) {

    const [showImageAlreadyExitModal, setShowImageAlreadyExitModal] = useState(false)

    // Upload image states
    const [imageUpload, setImageUpload] = useState<File>()

    const uploadFile = async () => {
        if (!imageUpload)
            return
        try {
            const formData = new FormData();
            formData.append('file', imageUpload);
            formData.append('destinationPath', "sources/");

            const response = await fetch(hcContext.backendUrlNodeJS + '/upload', {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                console.log('File uploaded successfully on NodedJS backend');
                onImageSelected(imageUpload.name)
                setShow(false)
            } else if (response.status === 409) {
                setShowImageAlreadyExitModal(true)
            } else {
                console.error('Error uploading file on NodedJS backend');
            }
        } catch (error) {
            console.error('Error uploading file on NodedJS backend', error);
        }
    };

    const [preview, setPreview] = useState<string | undefined>()
    // create a preview as a side effect, whenever selected file is changed
    useEffect(() => {
        if (!imageUpload) {
            setPreview(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(imageUpload)
        setPreview(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [imageUpload])


    return (
        <>
            <Modal
                language={language}
                show={show}
                setShow={setShow}
                isDarkTheme={hcContext.isDarkTheme}
                display='flex'
                contentWidth="90%"
            >
                <ModalHeader>
                    <h3><GetHtmlStrLocalized language={language} textId="selectAnImage" /></h3>
                </ModalHeader>
                <ModalBody>

                    <br />
                    <input type="file" onChange={(event) => {
                        if (event.target.files !== null &&
                            event.target.files?.length > 0) {
                            setImageUpload(event.target.files[0])
                        }
                    }} />
                    {
                        imageUpload &&
                        <img className={"hc-big-button-source-width hc-big-button-source-height hc-not-selectable"}
                            style={{ objectFit: 'cover', borderRadius: 10, borderWidth: 0 }} src={preview} alt="view uploaded" />
                    }

                </ModalBody>
                {
                    imageUpload &&
                    <ModalFooter>
                        <button onClick={uploadFile}>
                            <GetHtmlStrLocalized language={language} textId="validate" />
                        </button>
                    </ModalFooter>
                }
            </Modal>

            <InformationModal language={language} show={showImageAlreadyExitModal} setShow={setShowImageAlreadyExitModal}
                isDarkTheme={hcContext.isDarkTheme}
                title={GetStrLocalized(language, "error")} text={GetStrLocalized(language, "sorryThereIsAlreadyAnImageWithThatName")} />
        </>
    );
}

export default SelectSourceImageModal;