import GetStrLocalized from '../../datas/GetStrLocalized'
import SearchInput from "./SearchInput";


interface MainSearchInputProps {
	language: string;
	searchValue: string;
	isDarkTheme: boolean;
}

function MainSearchInput({ language, searchValue, isDarkTheme }: Readonly<MainSearchInputProps>) {
	return <SearchInput placeholder={GetStrLocalized(language, "search")} searchValue={searchValue} searchPath={'/' + language + '/' + GetStrLocalized(language, "searchFolderName") + '/'}
		isDarkTheme={isDarkTheme} />
}

export default MainSearchInput
