import React from 'react'
import GetHtmlStrLocalized from '../../datas/GetHtmlStrLocalized'
import Modal, { ModalBody, ModalFooter, ModalHeader } from './Modal'
import HorizontalSpace from '../util/HorizontalSpace';


interface YesNoModalProps {
    language: string;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    isDarkTheme: boolean;
    title: string;
    text: string;
    onYes: () => any;
}


function YesNoModal({ language, show, setShow, isDarkTheme, title, text, onYes }: Readonly<YesNoModalProps>) {
    return (
        <Modal
            language={language}
            show={show}
            setShow={setShow}
            isDarkTheme={isDarkTheme}
        >
            <ModalHeader>
                <h3>{title}</h3>
            </ModalHeader>
            <ModalBody>
                {text}
            </ModalBody>
            <br />
            <ModalFooter>
                <button onClick={() => {
                    onYes()
                    setShow(false)
                }}>
                    <GetHtmlStrLocalized language={language} textId="yes" />
                </button>
                <HorizontalSpace />
                <button onClick={() => {
                    setShow(false)
                }}>
                    <GetHtmlStrLocalized language={language} textId="no" />
                </button>
            </ModalFooter>
        </Modal>
    );
}

export default YesNoModal;