
import React from 'react'
import { Navigate } from 'react-router-dom';

interface NavigateTowardDefaultLanguageProps {
	language: string;
	setLanguage: React.Dispatch<React.SetStateAction<string>>;
}

function NavigateTowardDefaultLanguage({ language, setLanguage }: Readonly<NavigateTowardDefaultLanguageProps>) {

    setLanguage(language);
    return <Navigate to={"/" + language} />
}

export default NavigateTowardDefaultLanguage
