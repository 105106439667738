import '../assets/resources/custom/style/chapters.css'
import React, { useEffect, useState } from "react"
import GetStrLocalized from '../datas/GetStrLocalized'
import H1TitleBreadcrumb from '../components/title/H1TitleBreadcrumb'
import PageContent from '../components/util/PageContent'
import BigButtonWithTextAtBottom from "../components/bigButton/BigButtonWithTextAtBottom"
import { useLocation } from 'react-router-dom'
import Chapters from '../components/bigButton/Chapters'
import ReadingContent from '../components/util/ReadingContent'
import Videos from '../components/video/Videos'
import NavigationArrows from '../components/navigation/NavigationArrows'
import CategoriesBigButtons from '../components/bigButton/CategoriesBigButtons'
import { FolderProps } from '../props/folderprops'
import HorizontalSpace from '../components/util/HorizontalSpace'
import Centered from '../components/util/Centered'
import H2TitleId from '../components/title/H2TitleId'
import { HcContext } from '../types/hccontext'
import { createErrorModalContent } from '../props/ModalContentProps'


interface BibleProps {
	language: string;
	backendUrl: string;
	hcContext: HcContext;
};


function Bible({ language, backendUrl, hcContext }: Readonly<BibleProps>) {

	const location = useLocation();
	const [lastPath, setLastPath] = useState("")

	const TypeOfRequest_NONE = 0
	const TypeOfRequest_NEW_TESTAMENT = 2
	const TypeOfRequest_OLD_TESTAMENT = 1
	const TypeOfRequest_BIBLE_CONTENT = 3
	const [typeOfPage, setTypeOfPage] = useState(TypeOfRequest_NONE)

	const [parentFolders, setParentFolders] = useState<FolderProps[]>([])

	const [testamentRequest, setTestamentRequest] = useState({ categories: [], videoIds: [] })
	const [contentRequest, setContentRequest] = useState({ breadcrumb: [], title: "", typeOfPage: "", arrows: [], categories: [], videoIds: [], content: [] })

	const readingPartOfBreadcrumb = {
		path: GetStrLocalized(language, "readingsFolderName"),
		name: GetStrLocalized(language, "readings")
	}
	const biblePartOfBreadcrumb = {
		path: GetStrLocalized(language, "readingsFolderName") + "/" + GetStrLocalized(language, "bibleFolderName"),
		name: GetStrLocalized(language, "bible")
	}

	useEffect(() => {
		hcContext.setDocumentTitle(GetStrLocalized(language, "bible") + " - Holy Chatter");
	}, [hcContext, language]);
	let typeOfPageLocal = TypeOfRequest_NONE

	if (location.pathname !== lastPath) {
		setLastPath(location.pathname);
		const foldersArray = location.pathname.split('/');
		let bibleSubFolder = ""
		if (foldersArray.length > 4) {
			bibleSubFolder = foldersArray[4]
			if (bibleSubFolder === GetStrLocalized(language, "oldTestamentFolderName")) {
				typeOfPageLocal = TypeOfRequest_OLD_TESTAMENT
			} else if (bibleSubFolder === GetStrLocalized(language, "newTestamentFolderName")) {
				typeOfPageLocal = TypeOfRequest_NEW_TESTAMENT
			} else {
				typeOfPageLocal = TypeOfRequest_BIBLE_CONTENT
			}
		}

		setTypeOfPage(typeOfPageLocal)
		if (typeOfPageLocal === TypeOfRequest_OLD_TESTAMENT ||
			typeOfPageLocal === TypeOfRequest_NEW_TESTAMENT) {
			setParentFolders([
				readingPartOfBreadcrumb,
				biblePartOfBreadcrumb
			])
			fetch(backendUrl + "/testament_to_categories_json?l=" + language + "&tt=" +
				(typeOfPageLocal === TypeOfRequest_OLD_TESTAMENT ? "o" : "n"))
				.then(res => res.json())
				.then(jsonData => {
					if (jsonData.errorMessage) {
						hcContext.setInfo(createErrorModalContent(language, jsonData.errorMessage))
						return
					}
					setTestamentRequest(jsonData);
					hcContext.setDocumentTitle(GetStrLocalized(language, typeOfPageLocal === TypeOfRequest_OLD_TESTAMENT ? "oldTestament" : "newTestament") + " - " + GetStrLocalized(language, "bible"));
				})
				.catch(rejected => {
					hcContext.setInfo(createErrorModalContent(language, rejected.message))
				});
		} else if (typeOfPageLocal === TypeOfRequest_BIBLE_CONTENT) {
			fetch(backendUrl + "/bible_content_json?l=" + language + "&ref=" + bibleSubFolder)
				.then(res => res.json())
				.then(jsonData => {
					if (jsonData.errorMessage) {
						hcContext.setInfo(createErrorModalContent(language, jsonData.errorMessage))
						return
					}
					setContentRequest(jsonData)
					hcContext.setDocumentTitle(jsonData.title + " - " + GetStrLocalized(language, "bible"));
					setParentFolders([
						readingPartOfBreadcrumb,
						biblePartOfBreadcrumb,
						...jsonData.breadcrumb
					])
				})
				.catch(rejected => {
					hcContext.setInfo(createErrorModalContent(language, rejected.message))
				});
		} else {
			setParentFolders([readingPartOfBreadcrumb]);
		}
	}

	function BibleIntegral() {
		return (
			<>
				<H2TitleId language={language} titleId="integral" />
				<div className='hc-categories-left-margin'>
					<BigButtonWithTextAtBottom link={GetStrLocalized(language, "oldTestamentFolderName")} image="/buttons/old_testament.jpg" duration="" title={GetStrLocalized(language, "oldTestament")} tags="" beginOfClassName="hc-big-button-normal" hcContext={hcContext} />
					<BigButtonWithTextAtBottom link={GetStrLocalized(language, "psalmsFolderName")} image="/buttons/psalms.jpg" duration="" title={GetStrLocalized(language, "psalms")} tags="" beginOfClassName="hc-big-button-normal" hcContext={hcContext} />
					<BigButtonWithTextAtBottom link={GetStrLocalized(language, "newTestamentFolderName")} image="/buttons/new_testament.jpg" duration="" title={GetStrLocalized(language, "newTestament")} tags="" beginOfClassName="hc-big-button-normal" hcContext={hcContext} />
				</div>
			</>
		)
	}

	function BibleFromDayToDay() {
		return (
			<>
				<H2TitleId language={language} titleId="fromDayToDay" />
				<div className='hc-categories-left-margin'>
					<BigButtonWithTextAtBottom link={"/" + language + "/" + GetStrLocalized(language, "readingsOfTheDayFolderName")} image="/buttons/lecture_messe.webp" duration="" title={GetStrLocalized(language, "readingsOfTheDay")} tags="" beginOfClassName="hc-big-button-normal" hcContext={hcContext} />
					<BigButtonWithTextAtBottom link={"/" + language + "/" + GetStrLocalized(language, "gospelOfTheDayFolderName")} image="/buttons/lecture_evangile.webp" duration="" title={GetStrLocalized(language, "gospelOfTheDay")} tags="" beginOfClassName="hc-big-button-normal" hcContext={hcContext} />
				</div>
			</>
		)
	}

	return (
		<PageContent>

			{
				typeOfPage === TypeOfRequest_NONE &&
				<React.Fragment>
					<H1TitleBreadcrumb language={language} parentFolders={parentFolders}>{GetStrLocalized(language, "bible")}</H1TitleBreadcrumb>
					<br /><br />

					<div className='hc-not-very-short-screen'>
						<table width="100%">
							<tbody>
								<tr>
									<td width="50%"><BibleIntegral /></td>
									<td width="50%"><BibleFromDayToDay /></td>
								</tr>
							</tbody>
						</table>
					</div>

					<div className='hc-very-short-screen'>
						<BibleIntegral />
						<BibleFromDayToDay />
					</div>

					<div>
						{
							language === "fr" &&
							<span>
								<br />
								<span className='hc-categories-left-margin hc-content-text' >
									Aide pour la lecture : <a href="/resources/documents/Lire la bible en 1 an.pdf">Lire la bible en 1 an</a>
								</span>
							</span>
						}
						<br /><br />
						<Videos videoIds={[language === "fr" ? "pYYIYRhlVoI" : "ak06MSETeo4"]} />
						<br /><br />

					</div>
				</React.Fragment>
			}

			{
				(typeOfPage === TypeOfRequest_OLD_TESTAMENT || typeOfPage === TypeOfRequest_NEW_TESTAMENT) &&
				<React.Fragment>
					<H1TitleBreadcrumb language={language} parentFolders={parentFolders}>{GetStrLocalized(language, typeOfPage === TypeOfRequest_OLD_TESTAMENT ? "oldTestament" : "newTestament")}</H1TitleBreadcrumb>
					<br /><br />

					<CategoriesBigButtons categories={testamentRequest.categories} hcContext={hcContext} />
					<Videos videoIds={testamentRequest.videoIds} />
				</React.Fragment>
			}

			{
				typeOfPage === TypeOfRequest_BIBLE_CONTENT &&
				<React.Fragment>

					<div className='hc-not-very-short-screen'>
						<table width='100%'>
							<tbody>
								<tr>
									<td width={45 + (30 + 15) * 3} style={{ paddingTop: 20 }}>
										<HorizontalSpace width={45} />
										<NavigationArrows btnDatas={contentRequest.arrows} language={language} isDarkTheme={hcContext.isDarkTheme} />
									</td>
									<td>
										<H1TitleBreadcrumb language={language} parentFolders={parentFolders}>{contentRequest.title}</H1TitleBreadcrumb>
									</td>
									<td width={45 + (30 + 15) * 3}>
									</td>
								</tr>
							</tbody>
						</table>
					</div>

					<div className='hc-very-short-screen'>
						<H1TitleBreadcrumb language={language} parentFolders={parentFolders}>{contentRequest.title}</H1TitleBreadcrumb>
					</div>
					<br />
					<div className='hc-very-short-screen'>
						<Centered>
							<NavigationArrows btnDatas={contentRequest.arrows} language={language} isDarkTheme={hcContext.isDarkTheme} />
						</Centered>
					</div>
					<br />

					{
						contentRequest.typeOfPage === "book" ?
							<Chapters chapters={contentRequest.categories} hcContext={hcContext} />
							:
							<CategoriesBigButtons categories={contentRequest.categories} hcContext={hcContext} />
					}

					<Videos videoIds={contentRequest.videoIds} />

					{
						contentRequest.typeOfPage === "chapter" &&
						<React.Fragment>
							<br />
							<div style={{ marginLeft: 40 }}>
								<ReadingContent language={language} type="bible" content={contentRequest.content} allowToDisplayLinkFromCorpus={hcContext.userAsRightsToModifyTheContent} hcContext={hcContext} />
							</div>
						</React.Fragment>
					}

					<br />
					<Centered>
						<NavigationArrows btnDatas={contentRequest.arrows} language={language} isDarkTheme={hcContext.isDarkTheme} />
					</Centered>
					<br />
				</React.Fragment>
			}


		</PageContent>
	)
}

export default Bible;