import image_ok from '../../assets/resources/custom/icons/ok.png'
import image_ko from '../../assets/resources/custom/icons/ko.png'
import EditionLabel from "../title/EditionLabel";
import Button from "../button/Button";
import { ButtonType } from "../../props/buttondataprops";
import GetStrLocalized from "../../datas/GetStrLocalized";
import Modal, { ModalBody, ModalFooter, ModalHeader } from "../modal/Modal";
import GetHtmlStrLocalized from "../../datas/GetHtmlStrLocalized";
import { SuggestedAnswerProps } from "../../types/QuizEdition";
import { ChangeEvent, useState } from 'react';
import InputWithStyle from '../banner/InputWithStyle';
import { HcContext } from '../../types/hccontext';


interface QuizAnswersListViewerProps {
  language: string;
  answers: SuggestedAnswerProps[];
  setAnswers: (r: SuggestedAnswerProps[]) => any;
  hcContext: HcContext;
}

export default function QuizAnswersListViewer(
  { language, answers, setAnswers, hcContext }: Readonly<QuizAnswersListViewerProps>
) {
  const [selectedElt, setSelectedElt] = useState<number | undefined>()
  const [showModal, setShowModal] = useState(false)
  const [addOrEdit, setAddOrEdit] = useState<boolean>(true)
  const [answEdition, setAnswEdition] = useState<SuggestedAnswerProps>({ text: "", isRightAnswer: false })

  function swapElts(firstIndex: number, secondIndex: number) {
    let eltsCopied = answers;
    const temp = eltsCopied[firstIndex];
    eltsCopied[firstIndex] = eltsCopied[secondIndex];
    eltsCopied[secondIndex] = temp;
    setAnswers(eltsCopied)
  }

  return (
    <>
      <table style={{ width: '100%' }}>
        <tbody>
          <tr>
            <td style={{ width: '300px' }}>
              <EditionLabel language={"fr"} textId='suggestedResponses' />
            </td>
            <td style={{ textAlign: 'center' }}></td>
            {
              selectedElt !== undefined &&
              <>
                {
                  answers.length > 1 &&
                  <>
                    <td style={{ paddingRight: '15px', width: '45px' }}><Button btnData={{
                      type: ButtonType.TOP, onClick: () => {
                        if (selectedElt > 0) {
                          swapElts(selectedElt - 1, selectedElt)
                          setSelectedElt(selectedElt - 1)
                        }
                      }
                    }} language={language} alt={GetStrLocalized(language, "move_up")} isDarkTheme={hcContext.isDarkTheme} /></td>
                    <td style={{ paddingRight: '15px', width: '45px' }}><Button btnData={{
                      type: ButtonType.DOWN, onClick: () => {
                        if (selectedElt < answers.length - 1) {
                          swapElts(selectedElt, selectedElt + 1)
                          setSelectedElt(selectedElt + 1)
                        }
                      }
                    }} language={language} alt={GetStrLocalized(language, "move_down")} isDarkTheme={hcContext.isDarkTheme} /></td>
                  </>
                }
                <td style={{ paddingRight: '15px', width: '45px' }}><Button btnData={{
                  type: ButtonType.EDIT, onClick: () => {
                    setAddOrEdit(false)
                    setAnswEdition(answers[selectedElt])
                    setShowModal(true)
                  }
                }} language={language} isDarkTheme={hcContext.isDarkTheme} /></td>
                <td style={{ paddingRight: '15px', width: '45px' }}><Button btnData={{
                  type: ButtonType.DELETE, onClick: () => {
                    let refCopied = answers;
                    refCopied.splice(selectedElt, 1)
                    setAnswers(refCopied)
                    setSelectedElt(undefined)
                  }
                }} language={language} isDarkTheme={hcContext.isDarkTheme} /></td>
              </>
            }
            <td style={{ paddingRight: '15px', width: '45px' }}><Button btnData={{
              type: ButtonType.ADD, onClick: () => {
                setAddOrEdit(true)
                setAnswEdition({ text: "", isRightAnswer: false })
                setShowModal(true)
              }
            }} language={language} isDarkTheme={hcContext.isDarkTheme} /></td>
          </tr>
        </tbody>
      </table>

      {
        answers.length > 0 &&
        <>
          {answers.map((item, index) => {
            return <div key={"answer-list-elt-" + index}><span style={{ listStyleType: "none", cursor: 'pointer' }} onClick={() => setSelectedElt(index)} >
              {item.isRightAnswer ?
                <img src={image_ok} alt="ok" />
                :
                <img src={image_ko} alt="ko" />
              }&nbsp;&nbsp;&nbsp;
              {
                selectedElt == index ?
                  <b>{item.text}</b>
                  :
                  <>{item.text}</>
              }
            </span></div>
          })}
        </>
      }


      <Modal
        language={language}
        show={showModal}
        setShow={setShowModal}
        isDarkTheme={hcContext.isDarkTheme}
      >
        <ModalHeader>
          <h3><GetHtmlStrLocalized language={language} textId={"suggestedResponse"} /></h3>
        </ModalHeader>
        <ModalBody>
          <input type="checkbox" checked={answEdition.isRightAnswer} onChange={(event: ChangeEvent<HTMLInputElement>) => {
            setAnswEdition({ text: answEdition.text, isRightAnswer: event.target.checked })
          }
          } />
          <GetHtmlStrLocalized language={language} textId="rightAnswer" />
          <br />
          <br />

          <InputWithStyle value={answEdition.text}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setAnswEdition({ text: event.target.value, isRightAnswer: answEdition.isRightAnswer })
            }} isDarkTheme={hcContext.isDarkTheme} height={hcContext.defaultLineEditHeight()} autoFocus={true} />
        </ModalBody>
        <br />
        <ModalFooter>
          <button onClick={() => {
            if (addOrEdit) {
              setAnswers([...answers, answEdition])
              setSelectedElt(undefined)
            }
            else {
              let cpTriggers = answers
              cpTriggers[selectedElt!!] = answEdition
              setAnswers(cpTriggers)
            }
            setShowModal(false)
          }}>
            <GetHtmlStrLocalized language={language} textId="validate" />
          </button>
        </ModalFooter>
      </Modal>
    </>

  );
}