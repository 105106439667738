import React from 'react'
import Modal, { ModalBody, ModalHeader } from './Modal'
import SourceModifier from '../modifier/SourceModifier';
import { HcContext } from '../../types/hccontext';
import GetHtmlStrLocalized from '../../datas/GetHtmlStrLocalized';


interface YesNoModalProps {
    language: string;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    hcContext: HcContext;
    onNewPossibleSource: (name: string, id: string) => any;
}


function AddSourceModal({ language, show, setShow, hcContext, onNewPossibleSource }: Readonly<YesNoModalProps>) {
    return (
        <Modal
            language={language}
            show={show}
            setShow={setShow}
            isDarkTheme={hcContext.isDarkTheme}
            display='flex'
            contentWidth="90%"
        >
            <ModalHeader>
                <h3><GetHtmlStrLocalized language={language} textId="addASource" /></h3>
            </ModalHeader>
            <ModalBody>
                <SourceModifier language={language} hcContext={hcContext} sourceId="" displayValidationButtonsOnTop={false}
                    onModified={(name, id) => {
                        onNewPossibleSource(name, id)
                        setShow(false)
                    }
                    } />
            </ModalBody>
        </Modal>
    );
}

export default AddSourceModal;