import React, { ReactElement } from 'react';

interface VerticalDisplayEltProps {
    component: ReactElement;
    width?: string;
    paddingRight?: string;
}

interface VerticalDisplayProps {
    elts: VerticalDisplayEltProps[];
}

const VerticalDisplay: React.FC<VerticalDisplayProps> = ({ elts }) => {
    return (
        <table style={{ width: "100%" }}>
            <tbody>
                <tr>
                    {elts.map((elt, index) => (
                        <td key={"vertical-display-" + index} style={{ width: elt.width, paddingRight: elt.paddingRight }}>
                            {elt.component}
                        </td>
                    ))}
                </tr>
            </tbody>
        </table>
    );
};

export default VerticalDisplay;