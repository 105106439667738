import React, { useEffect, useState } from "react"
import { Navigate, useLocation } from "react-router-dom"
import H1Title from '../components/title/H1Title'
import PageContent from '../components/util/PageContent'
import GetStrLocalized from '../datas/GetStrLocalized'
import { HcContext, SizeOfWindow } from '../types/hccontext'
import HorizontalSpace from '../components/util/HorizontalSpace'
import Button from '../components/button/Button'
import { ButtonType } from '../props/buttondataprops'
import GetHtmlStrLocalized from "../datas/GetHtmlStrLocalized"
import { FactDescAndFollowingFacts, PlannerFact } from '../props/FactsProps'
import { DisplayPannerFactWithLink } from '../components/fact/DisplayPlannerFactWithLink'
import { DisplayPreconditionFacts } from '../components/fact/DisplayPreconditionFacts'
import AddFactModal from '../components/modal/AddFactModal'
import { createErrorModalContent } from "../props/ModalContentProps"


interface FactNotLinkedProps {
	fact: PlannerFact;
}

interface ListOfFactsProps {
	facts: FactDescAndFollowingFacts[];
	factsNotRelated: FactDescAndFollowingFacts[];
	factsNotLinked: FactNotLinkedProps[];
}



interface FactsProps {
	language: string;
	setDocumentTitle: React.Dispatch<React.SetStateAction<string>>;
	backendUrl: string;
	hcContext: HcContext;
}


function Facts({ language, setDocumentTitle, backendUrl, hcContext }: Readonly<FactsProps>) {

	useEffect(() => {
		setDocumentTitle(GetStrLocalized(language, "factsForChatbotPlannification") + " - Holy Chatter");
	}, [setDocumentTitle, language]);

	const location = useLocation()
	const [lastPath, setLastPath] = useState("")
	const [lastSearch, setLastSearch] = useState("")
	const [request, setRequest] = useState<ListOfFactsProps>({ facts: [], factsNotRelated: [], factsNotLinked: [] })
	const [showMAddFactModal, setShowMAddFactModal] = useState(false)

	function refreshPage() {
		fetch(backendUrl + "/facts_json?l=" + language + "&f=aller_au_ciel")
			.then(res => res.json())
			.then(jsonData => {
				if (jsonData.errorMessage) {
					hcContext.setInfo(createErrorModalContent(language, jsonData.errorMessage))
					return
				}
				setRequest(jsonData)
			})
			.catch(rejected => {
				hcContext.setInfo(createErrorModalContent(language, rejected.message))
			});
	}

	if (location.pathname !== lastPath || location.search !== lastSearch) {
		setLastPath(location.pathname)
		setLastSearch(location.search)
		refreshPage()
	}

	function DisplayFactTitle() {
		return (
			<div>
				<table width="100%" style={{ marginTop: '20px', marginBottom: '20px' }}>
					<thead className="visually-hidden"><tr><th></th></tr></thead>
					<tbody>
						<tr>
							<td className='hc-content-text hc-content-text-paddings' width="34%">
								<b><GetHtmlStrLocalized language={language} textId="fact" /></b>
							</td>
							<td className='hc-content-text hc-content-text-paddings' width="33%">
								<b><GetHtmlStrLocalized language={language} textId="numberOfMessages" /></b>
							</td>
							<td className='hc-content-text hc-content-text-paddings' width="33%">
								<b><GetHtmlStrLocalized language={language} textId="link" /></b>
							</td>
						</tr>
					</tbody>
				</table>
				<hr style={{ height: 2, backgroundColor: '#333333', border: 'none' }} />
			</div>
		)
	}


	interface DisplayFactLineProps {
		fact: FactDescAndFollowingFacts;
	}

	function DisplayFactLine({ fact }: Readonly<DisplayFactLineProps>) {
		return (
			<div style={{ fontWeight: 'bold' }}>
				<table width="100%" style={{ marginTop: '20px', marginBottom: '20px' }}>
					<thead className="visually-hidden"><tr><th></th></tr></thead>
					<tbody>
						<tr>
							<td className='hc-content-text hc-content-text-paddings' style={{ paddingLeft: '10px', paddingRight: '10px' }} width="34%">
								<DisplayPreconditionFacts preconditionFacts={fact.preconditionFacts} language={language} />
							</td>
							<td className='hc-content-text hc-content-text-paddings' style={{ paddingLeft: '10px', paddingRight: '10px' }} width="33%">
								<DisplayPannerFactWithLink fact={fact.fact} language={language} />
							</td>
							<td className='hc-content-text hc-content-text-paddings' style={{ paddingLeft: '10px', paddingRight: '10px' }} width="33%">
								{
									fact.followingFacts.length > 0 &&
									fact.followingFacts.map((item, index) => (
										<span key={"following-fact-" + index}> {index > 0 && <br />} <DisplayPannerFactWithLink fact={item} language={language} /></span>
									))
								}
							</td>
						</tr>

					</tbody>
				</table>
				{
					hcContext.sizeOfWindow === SizeOfWindow.WIDE &&
					<hr style={{ height: 1, backgroundColor: 'grey', border: 'none' }} />
				}
			</div>
		)
	}


	function DisplayFactNotLinkedTitle() {
		return (
			<div>
				<table width="100%" style={{ marginTop: '20px', marginBottom: '20px' }}>
					<thead className="visually-hidden"><tr><th></th></tr></thead>
					<tbody>
						<tr>
							<td className='hc-content-text hc-content-text-paddings' width="100%">
								<b><GetHtmlStrLocalized language={language} textId="fact" /></b>
							</td>
						</tr>
					</tbody>
				</table>
				<hr style={{ height: 2, backgroundColor: '#333333', border: 'none' }} />
			</div>
		)
	}


	interface DisplayFactNotLinkedLineProps {
		fact: FactNotLinkedProps;
	}

	function DisplayFactNotLinkedLine({ fact }: Readonly<DisplayFactNotLinkedLineProps>) {
		return (
			<div style={{ fontWeight: 'bold' }}>
				<table width="100%" style={{ marginTop: '20px', marginBottom: '20px' }}>
					<thead className="visually-hidden"><tr><th></th></tr></thead>
					<tbody>
						<tr>
							<td className='hc-content-text hc-content-text-paddings' width="40%">
								<DisplayPannerFactWithLink fact={fact.fact} language={language} />
							</td>
						</tr>
					</tbody>
				</table>
				{
					hcContext.sizeOfWindow === SizeOfWindow.WIDE &&
					<hr style={{ height: 1, backgroundColor: 'grey', border: 'none' }} />
				}
			</div>
		)
	}


	return (
		<PageContent>
			{
				!hcContext.userAsRightsToModifyTheContent &&
				<Navigate to={"/" + language} />
			}
			<table width='100%'>
				<thead className="visually-hidden"><tr><th></th></tr></thead>
				<tbody>
					<tr>
						<td width={(30 + 15) * 3 + 45} style={{ paddingTop: 20 }} className="hc-not-very-short-screen">
							<HorizontalSpace width={45} />
						</td>
						<td>
							<H1Title><GetHtmlStrLocalized language={language} textId="factsForChatbotPlannification" /></H1Title>
						</td>
						<td width={(30 + 15) * 3 + 45} style={{ paddingTop: 20 }} className="hc-not-very-short-screen">
							<Button btnData={{ type: ButtonType.ADD, onClick: () => setShowMAddFactModal(true) }} language={language} isDarkTheme={hcContext.isDarkTheme} />
							<HorizontalSpace />
						</td>
					</tr>
				</tbody>
			</table>

			<br />
			<div className='hc-content-text-paddings'>
				<DisplayFactTitle />
				{
					request.facts.length > 0 &&
					request.facts.map((item, index) => { return <DisplayFactLine key={"fact-" + index} fact={item} /> })
				}
			</div>

			<br />
			<br />
			<br />
			<div className='hc-content-text-paddings'>
				<DisplayFactTitle />
				{
					request.factsNotRelated.length > 0 &&
					request.factsNotRelated.map((item, index) => { return <DisplayFactLine key={"factNotRelated-" + index} fact={item} /> })
				}
			</div>

			<br />
			<br />
			<br />
			<div className='hc-content-text-paddings'>
				<DisplayFactNotLinkedTitle />
				{
					request.factsNotLinked.length > 0 &&
					request.factsNotLinked.map((item, index) => { return <DisplayFactNotLinkedLine key={"factNotLinked-" + index} fact={item} /> })
				}
			</div>


			<AddFactModal hcContext={hcContext} language={language} show={showMAddFactModal} setShow={setShowMAddFactModal} onModification={refreshPage} />

		</PageContent>
	)
}

export default Facts;