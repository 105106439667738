import React from "react";
import { GlossaryWordInfoProps } from "../../types/GlossaryWordEdition";
import { HcContext } from "../../types/hccontext";

interface ClickableTextProps {
    hcContext: HcContext;
    text: string;
    wordsFromDictionary: GlossaryWordInfoProps[];
}


function TextWithLinks({ hcContext, text, wordsFromDictionary }: Readonly<ClickableTextProps>) {
    if (wordsFromDictionary.length === 0)
        return <>{text}</>

    const renderTextWithClickables = () => {
        let currentIndex = 0;

        // Map through the words in the dictionary and replace them with clickable spans
        const elements = wordsFromDictionary.map((wordData) => {
            const startIndex = text.indexOf(wordData.word, currentIndex);
            const endIndex = startIndex + wordData.word.length;
            const nonClickableText = text.substring(currentIndex, startIndex);
            currentIndex = endIndex;

            return (
                <React.Fragment key={startIndex}>
                    {nonClickableText}
                    <span
                        className="hc-clickable"
                        onClick={() => hcContext.setInfo({title: wordData.word, content: wordData.shortDefinition})}
                    >
                        {wordData.word}
                    </span>
                </React.Fragment>
            );
        });

        // Include any remaining non-clickable text after the last clickable word
        elements.push(<span key={currentIndex}>{text.substring(currentIndex)}</span>)

        return <>{elements}</>
    };

    return <>{renderTextWithClickables()}</>
}

export default TextWithLinks;