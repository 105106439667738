import '../assets/resources/custom/style/main-6.css'
import '../assets/resources/custom/style/bootstrap_hc.css'
import '../assets/resources/custom/style/dialog_max_height.css'
import '../assets/resources/custom/style/categories-1.css'
import '../assets/resources/custom/style/video_size-0.css'
import image_wait from '../assets/resources/custom/icons/wait.gif'
import React, { useEffect, useState } from "react"
import { useLocation, Link, Navigate, useNavigate } from "react-router-dom"
import H1TitleBreadcrumb from '../components/title/H1TitleBreadcrumb'
import PageContent from '../components/util/PageContent'
import GetHtmlStrLocalized from '../datas/GetHtmlStrLocalized'
import BigButtonWithTextAtRight from '../components/bigButton/BigButtonWithTextAtRight'
import Refs from '../components/util/Refs'
import SourceLink from '../components/source/SourceLink'
import { SourceProps } from '../props/sourceprops'
import { BigButtonProps } from '../props/bigbuttonprops'
import { FolderProps } from '../props/folderprops'
import { ReferenceProps } from '../props/referenceprops'
import { getCookie, setCookie } from 'typescript-cookie'
import Button from '../components/button/Button'
import { ButtonDataProps, ButtonType } from '../props/buttondataprops'
import HorizontalSpace from '../components/util/HorizontalSpace'
import { HcContext, SizeOfWindow } from '../types/hccontext'
import NavigationArrows from '../components/navigation/NavigationArrows'
import Centered from '../components/util/Centered'
import GetStrLocalized from '../datas/GetStrLocalized'
import ArticleModifier from '../components/modifier/ArticleModifier'
import MoveDestinationPathSelectorModal from '../components/modal/MoveDestinationPathSelectorModal'
import { getWaitPopupStyle } from '../components/util/GetWaitPopupStyle'
import { moveItem } from '../util/moveItem'
import TestArticleModifier from '../components/modifier/TestArticleModifier'
import LoginModal from '../components/modal/LoginModal'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../components/modal/Modal'
import StarsSelector from '../components/star/StarsSelector'
import { StarsStats } from '../components/star/starsUtil'
import StarsDynamic from '../components/star/StarsDynamic'
import AlignRight from '../components/util/AlignRight'
import ThankYouModal from '../components/modal/ThankYouModal'
import Stars from '../components/star/Stars'
import GetInnerHtmlStrLocalized from '../datas/GetInnerHtmlStrLocalized'
import { nbToViewPrint } from '../util/string'
import { createErrorModalContent } from '../props/ModalContentProps'


export class ArticleGetContentType {
	language: string;
	articleId: string;
	userToken: string;
	messageIds: string[];
	allRecommendationIds: string[];

	constructor(
		language: string,
		articleId: string,
		userToken: string | undefined,
		messageIds: string[],
		allRecommendationIds: string[]) {
		this.language = language;
		this.articleId = articleId;
		this.userToken = userToken ?? "";
		this.messageIds = messageIds;
		this.allRecommendationIds = allRecommendationIds;
	}
}


interface ArticleViewProps {
	name: string;
	parentFolders: FolderProps[];
	html: string;
	youtubeId: string;
	tags: string[];
	references: SourceProps[],
	fileType: string;
	source: SourceProps,
	duration: string;
	uploadDate: string;
	chatbotId: string;
	description: string;
	rightRecommendationsHtmlForLongSreens: BigButtonProps[];
	bibleRefs: ReferenceProps[];
	cccRefs: ReferenceProps[];
	arrows: ButtonDataProps[];
	userVote: number;
}


function textToHtml(text: string): string {
	return text.replace(/\n/g, '<br>');
}

interface ArticleVoteProps {
	language: string;
	starsStats: StarsStats;
	userVote: number | undefined;
	size: string;
	onVoteBtnClick: () => any;
};

function ArticleVote({ language, starsStats, userVote, size, onVoteBtnClick }: Readonly<ArticleVoteProps>) {
	return (
		<div style={{ display: 'flex' }}>
			<StarsDynamic starsStats={starsStats} size={size} />
			<span className='hc-no-line-break' style={{ paddingTop: '7px', marginLeft: '5px' }}>({starsStats.numberOfVotes} vote{starsStats.numberOfVotes > 1 && "s"})</span>

			{
				(userVote !== undefined && userVote !== 0)
					?
					<button style={{ marginLeft: '20px', color: '#2980B9', border: '2px solid #2980B9', backgroundColor: 'white' }} onClick={onVoteBtnClick}>
						<GetInnerHtmlStrLocalized language={language} textId="modifyMyVote" />
						<br />
						<Stars starsStats={{ totalScore: userVote, numberOfVotes: 1 }} size="small" />
					</button>
					:
					<button style={{ marginLeft: '20px' }} onClick={onVoteBtnClick}>
						<GetHtmlStrLocalized language={language} textId="vote" />
					</button>
			}
		</div>
	)
}


interface ArticleProps {
	language: string;
	hcContext: HcContext;
};

function Article({ language, hcContext }: Readonly<ArticleProps>) {

	const location = useLocation()
	const navigate = useNavigate()
	const [pageFound, setPageFound] = useState(true)
	const [lastPath, setLastPath] = useState("")
	const [lastSearch, setLastSearch] = useState("")
	const [articleId, setArticleId] = useState("")
	const [editMode, setEditMode] = useState(false)
	const [testMode, setTestMode] = useState(false)
	const [processingModifications, setProcessingModifications] = useState(false)
	const [articleView, setArticleView] = useState<ArticleViewProps>({
		name: "", parentFolders: [], html: "", youtubeId: "", tags: [], references: [],
		fileType: "", source: { iconPath: "", path: "", name: "", url: "" },
		duration: "", uploadDate: "", chatbotId: "", description: "",
		rightRecommendationsHtmlForLongSreens: [], bibleRefs: [], cccRefs: [],
		arrows: [], userVote: 0
	})
	const [showMoveModal, setShowMoveModal] = useState(false)
	const [showLoginForStarVoteModal, setShowLoginForStarVoteModal] = useState(false)
	const [showStarVoteModal, setShowStarVoteModal] = useState(false)
	const [starsStats, setStarsStats] = useState<StarsStats>({ totalScore: 0, numberOfVotes: 0 })
	const [thankYouMessage, setThankYouMessage] = useState<string>("")
	const [showThankYouMessage, setShowThankYouMessage] = useState<boolean>(false)
	const [userVote, setUserVote] = useState<number | undefined>(undefined)
	const [viewCount, setViewCount] = useState<number | undefined>(undefined)
	const [pageId, setPageId] = useState<string>("")
	const messageIdsCookie = getCookie('messageIds')
	const allRecommendationIdsCookie = getCookie('allRecommendationIds')

	function fetchContent(msgId: string) {
		const newPageId = msgId + hcContext.user?.email
		if (pageId === newPageId)
			return
		setPageId(newPageId)

		const messageIdsCookieVector = messageIdsCookie?.split("|")
		const allRecommendationIdsCookieVector = allRecommendationIdsCookie?.split("|")
		const postContent = new ArticleGetContentType(language, msgId, hcContext.userToken,
			messageIdsCookieVector ?? [],
			allRecommendationIdsCookieVector ?? [])
		fetch(hcContext.backendUrl + "/article_page", {
			method: 'POST',
			body: JSON.stringify(postContent),
			headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
		})
			.then(res => res.json())
			.then(jsonData => {
				if (jsonData.errorMessage) {
					setPageFound(false)
					hcContext.setInfo(createErrorModalContent(language, jsonData.errorMessage))
					return
				}
				setPageFound(true)
				setArticleView(jsonData)
				setStarsStats(jsonData.stars)
				setUserVote(Number(jsonData.userVote))
				hcContext.setDocumentTitle(jsonData.name + " - " + jsonData.source.name)
				setCookie('messageIds', jsonData.messageIds, { path: '/' + language })
				setCookie('allRecommendationIds', jsonData.allRecommendationIds, { path: '/' + language })
				if (jsonData.youtubeId !== "")
					fetchViewCount(msgId, jsonData.youtubeId)
				setViewCount(Number(jsonData.viewCount))
			})
			.catch(rejected => {
				setPageFound(false)
				hcContext.setInfo(createErrorModalContent(language, rejected.message))
			});
	}

	function fetchViewCount(msgId: string, youtubeId: string) {
		fetch(hcContext.backendUrl + "/refresh_youtube_view_count?l=" + language + "&messageId=" + msgId +
			"&youtubeId=" + youtubeId)
			.then(res => res.json())
			.then(jsonData => {
				setViewCount(Number(jsonData.res))
			})
			.catch(rejected => {
				console.error("Fetch view count error: " + rejected);
			});
	}

	if (location.pathname !== lastPath || location.search !== lastSearch) {
		setLastPath(location.pathname)
		setLastSearch(location.search)
		const urlParams = new URLSearchParams(location.search);
		const isEditMode = urlParams.has('edit')
		setEditMode(isEditMode)
		const isTestMode = urlParams.has('test')
		setTestMode(isTestMode)

		const foldersArray = location.pathname.split('/');
		if (foldersArray.length > 2) {
			const articleIdFromPath = foldersArray[2]
			setArticleId(articleIdFromPath)
			if (!isEditMode && !isTestMode)
				fetchContent(articleIdFromPath)
		}
	}

	useEffect(() => {
		fetchContent(articleId)
	}, [hcContext.userIsIdentified])

	function addAutoStart(string: string) {
		// For embedded videos
		const videoControls = ' controls>'
		const videoControlsIndex = string.indexOf(videoControls);
		if (videoControlsIndex !== -1) {
			return string.substring(0, videoControlsIndex + videoControls.length - 1) + " autoplay" +
				string.substring(videoControlsIndex + videoControls.length - 1, string.length);
		}
		// For YouTube videos
		const relZero = 'rel=0'
		const relZeroIndex = string.indexOf(relZero);
		if (relZeroIndex !== -1) {
			return string.substring(0, relZeroIndex + relZero.length) + "&autoplay=1" +
				string.substring(relZeroIndex + relZero.length, string.length);
		}
		// For vimeo videos
		const hParam = '?h='
		const hParamIndex = string.indexOf(hParam);
		if (hParamIndex !== -1) {
			return string.substring(0, hParamIndex + 1) + "autoplay=1&" +
				string.substring(hParamIndex + 1, string.length);
		}
		return string
	}

	function onVoteBtnClick() {
		if (hcContext.user === undefined) {
			setShowLoginForStarVoteModal(true)
		} else {
			setShowStarVoteModal(true)
		}
	}

	function createStarVotePostContentType(
		language: string,
		userToken: string,
		msgId: string,
		vote: number
	) {
		return {
			language,
			userToken,
			msgId,
			vote,
		};
	}

	function sendStarVote(vote: number) {
		if (!hcContext.userToken)
			return
		const postContent = createStarVotePostContentType(language, hcContext.userToken, articleId, vote);
		fetch(hcContext.backendUrl + "/add_star_vote", {
			method: 'POST',
			body: JSON.stringify(postContent),
			headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
		})
			.then(res => res.json())
			.then(jsonData => {
				if (jsonData.errorMessage) {
					hcContext.setInfo(createErrorModalContent(language, jsonData.errorMessage))
					return
				}
				setStarsStats(jsonData)
				setUserVote(vote)
			})
			.catch(rejected => {
				hcContext.setInfo(createErrorModalContent(language, rejected.message))
			});
	}


	return (
		<PageContent>

			{
				(editMode || testMode) &&
				!hcContext.userAsRightsToModifyTheContent &&
				<Navigate to={lastPath} />
			}

			{
				processingModifications &&
				<Centered>
					<div style={getWaitPopupStyle(true, 50)}>
						<img src={image_wait} alt="wait" height="100%" width="100%" />
					</div>
				</Centered>
			}

			{
				editMode ?
					<ArticleModifier language={language} hcContext={hcContext} parentFolders={articleView.parentFolders} articleId={articleId} lastPath={lastPath} />
					:
					<>
						{
							testMode ?
								<TestArticleModifier language={language} hcContext={hcContext} title={articleView.name} articleId={articleId} lastPath={lastPath} />
								:
								<>
									{
										pageFound ?
											<>
												<table width='100%'>
													<tbody>
														<tr>
															<td width={(30 + 15) * 3 + 45} style={{ paddingTop: 20 }} className="hc-not-very-short-screen">
																<HorizontalSpace width={45} />
																<NavigationArrows btnDatas={articleView.arrows} language={language} isDarkTheme={hcContext.isDarkTheme} />
															</td>
															<td>
																<H1TitleBreadcrumb language={language} parentFolders={articleView.parentFolders}>
																	{articleView.name}
																</H1TitleBreadcrumb>
															</td>
															{
																hcContext.userAsRightsToModifyTheContent ?
																	<td width={(30 + 15) * 3 + 45} style={{ paddingTop: 20 }} className="hc-not-very-short-screen">
																		<Button btnData={{
																			type: ButtonType.EDIT, onClick: () => {
																				setPageId("")
																				navigate("?edit")
																			}
																		}} language={language} isDarkTheme={hcContext.isDarkTheme} />
																		<HorizontalSpace />
																		<Button btnData={{ type: ButtonType.MOVE, onClick: () => setShowMoveModal(true) }} language={language} isDarkTheme={hcContext.isDarkTheme} />
																		<HorizontalSpace />
																		<Button btnData={{ type: ButtonType.TEST, onClick: () => navigate("?test") }} language={language} isDarkTheme={hcContext.isDarkTheme} />
																		<HorizontalSpace />
																	</td>
																	:
																	<td width={(30 + 15) * 3 + 45} className="hc-not-very-short-screen"></td>
															}
														</tr>
													</tbody>
												</table>

												<div className="hc-very-short-screen">
													<br />
													<Centered>
														<HorizontalSpace width={45} />
														<NavigationArrows btnDatas={articleView.arrows} language={language} isDarkTheme={hcContext.isDarkTheme} />
													</Centered>
													<br />
												</div>

												<div style={{ marginLeft: 20, marginRight: 20 }}>
													<table style={{ width: '100%' }}>
														<thead className="visually-hidden">
															<tr>
																<th>Left side</th>
																<th>Right side</th>
															</tr>
														</thead>
														<tbody>
															<tr>
																<td className="hc-message-viewer-width">
																	<div style={{ paddingLeft: 5, paddingRight: 15, paddingTop: 10 }}>
																		<div style={{ borderRadius: 10, overflow: 'hidden' }}>
																			<span dangerouslySetInnerHTML={{ __html: addAutoStart(articleView.html) }} />
																		</div>

																		<table style={{ width: '100%' }}>
																			<thead className="visually-hidden">
																				<tr>
																					<th></th>
																				</tr>
																			</thead>
																			<tbody>
																				<tr>
																					<td style={{ width: '99%' }}>
																						<p style={{ paddingTop: '5px', paddingBottom: '5px' }}>
																							{
																								articleView.tags.length > 0 &&
																								articleView.tags.map((tagItem, tagIndex) => {
																									return (
																										<React.Fragment key={"tag-" + tagIndex}>
																											<Link to={"/" + language + "/" + GetStrLocalized(language, "searchFolderName") + "/%23" + tagItem}>
																												#{tagItem}
																											</Link>&nbsp;&nbsp;&nbsp;
																										</React.Fragment>)
																								})
																							}
																						</p>

																						<p>
																							{
																								articleView.references?.length > 0 &&
																								articleView.references.map((item, index) => (
																									<React.Fragment key={"references-" + item.url + "-" + index}>
																										{
																											item.iconPath &&
																											<img src={item.iconPath} alt="icon of the reference" className="hc-source-icon" />
																										}
																										<a href={item.url} >{item.url}</a>
																										<br />
																									</React.Fragment>
																								))
																							}

																							{
																								articleView.fileType !== "html" &&
																								<br />
																							}
																							<SourceLink source={articleView.source} />
																							{
																								articleView.fileType !== "html" &&
																								<br />
																							}
																						</p>
																						{
																							hcContext.sizeOfWindow === SizeOfWindow.VERY_SHORT &&
																							<AlignRight>
																								<div>
																									<ArticleVote language={language} starsStats={starsStats} userVote={userVote} size="normalSmallScreen" onVoteBtnClick={onVoteBtnClick} />
																								</div>
																							</AlignRight>
																						}
																						<br />
																						{nbToViewPrint(viewCount, language)}&nbsp;&nbsp;{articleView.uploadDate}
																						<br />
																						{/*
																							<Link className='hc-text-align-center' to={"/" + language + "/chatbot/" + articleView.chatbotId + "?messageId=" + articleId}>
																								<GetHtmlStrLocalized language={language} textId="chatFromThisMessage" />
																							</Link>
																					     */}
																						<br />
																					</td>

																					<td style={{ width: '1%', paddingTop: '10px' }}>
																						{
																							hcContext.sizeOfWindow !== SizeOfWindow.VERY_SHORT &&
																							<ArticleVote language={language} starsStats={starsStats} userVote={userVote} size="normal" onVoteBtnClick={onVoteBtnClick} />
																						}
																					</td>
																				</tr>
																			</tbody>
																		</table>

																		{articleView.description &&
																			<span>
																				<br />
																				<div className='hc-content-text' style={{ marginLeft: 10, paddingLeft: 20, paddingRight: 5 }} dangerouslySetInnerHTML={{ __html: textToHtml(articleView.description) }} />
																			</span>
																		}

																	</div>
																</td>
																<td className="hc-reference-viewer-width">
																	<span id="right-recommendations-for-long-screens">


																		<div className='hc-long-screen hc-small-margin' style={{ paddingLeft: 15, paddingRight: 5 }}>
																			{
																				articleView.rightRecommendationsHtmlForLongSreens?.map((item, index) => (
																					<React.Fragment key={"right-recommendations-" + index} >
																						<div className="hc-inline-flex" >
																							<BigButtonWithTextAtRight allowPreview={false} language={language} item={item} allowToDisplayLinkFromCorpus={hcContext.userAsRightsToModifyTheContent} hcContext={hcContext} />
																						</div>
																						<br />
																						<br />
																						<br />
																					</React.Fragment>
																				))
																			}
																		</div>
																	</span>
																</td>
															</tr>
														</tbody>
													</table>
												</div>
												<br />
												<br />
												<Refs language={language} type="bible" refs={articleView.bibleRefs} allowToDisplayLinkFromCorpus={hcContext.userAsRightsToModifyTheContent} hcContext={hcContext} />
												<br />
												<Refs language={language} type="ccc" refs={articleView.cccRefs} allowToDisplayLinkFromCorpus={hcContext.userAsRightsToModifyTheContent} hcContext={hcContext} />
											</>
											:
											<>
												<br />
												<br />
												<div className='hc-content-text' style={{ textAlign: 'center', margin: '0 auto' }}>
													<GetHtmlStrLocalized language={language} textId="sorryThisPageIsNotAvailable" />
												</div>
											</>
									}
								</>
						}
					</>



			}


			{
				showLoginForStarVoteModal &&
				<LoginModal
					language={language}
					setShow={setShowLoginForStarVoteModal}
					hcContext={hcContext}
					onLoggedIn={() => setShowStarVoteModal(true)}
				/>
			}

			{
				showStarVoteModal &&
				<Modal
					language={language}
					show={true}
					setShow={setShowStarVoteModal}
					isDarkTheme={hcContext.isDarkTheme}
				>
					<ModalHeader>
						<h3><GetHtmlStrLocalized language={language} textId="vote" /></h3>
					</ModalHeader>
					<ModalBody>
						<GetHtmlStrLocalized language={language} textId="selectStarDesc" />
						<br />
						<br />

						<StarsSelector initialScore={userVote} size="big" onChoosenValue={(val) => {
							sendStarVote(val)
							setShowStarVoteModal(false)

							// Show thank you modal
							setThankYouMessage(GetStrLocalized(language, "thankYouForYourVote"))
							setShowThankYouMessage(true)
						}} />
						<br />
					</ModalBody>
					<br />
					<ModalFooter>
						<button onClick={() => setShowStarVoteModal(false)}>
							<GetHtmlStrLocalized language={language} textId="close" />
						</button>
					</ModalFooter>
				</Modal>
			}

			{
				showThankYouMessage &&
				<ThankYouModal language={language} setShow={setShowThankYouMessage} title={GetStrLocalized(language, "thankYou")}
					text={thankYouMessage} isDarkTheme={hcContext.isDarkTheme} />
			}

			<MoveDestinationPathSelectorModal language={language} show={showMoveModal} setShow={setShowMoveModal}
				backendUrl={hcContext.backendUrl} itemName={articleView.name}
				onPathChoosen={(path) => moveItem(articleView.parentFolders, path, articleView.name, hcContext, language, setProcessingModifications, navigate)}
				hcContext={hcContext}
			/>
		</PageContent>
	)
}

export default Article
