import * as React from "react";
import EditionLabel from "../title/EditionLabel";
import Button from "../button/Button";
import { ButtonType } from "../../props/buttondataprops";
import GetStrLocalized from "../../datas/GetStrLocalized";
import { RecommendationsInfosProps } from "../../types/ArticleEdition";
import ArticleSelectorModal from "../modal/ArticleSelectorModal";
import TextEditionModal from "../modal/TextEditionModal";
import Recommendation from "../chatbot/Recommendation";
import GetHtmlStrLocalized from "../../datas/GetHtmlStrLocalized";
import { HcContext } from "../../types/hccontext";


interface RecommendationsListViewerProps {
  language: string;
  backendUrl: string;
  recommendationsInfos: RecommendationsInfosProps;
  setRecommendationsInfos: (r: RecommendationsInfosProps) => any;
  hcContext: HcContext;
}

export default function RecommendationsListViewer(
  { language, backendUrl, recommendationsInfos, setRecommendationsInfos, hcContext }: Readonly<RecommendationsListViewerProps>
) {
  const [selectedElt, setSelectedElt] = React.useState<number | undefined>()
  const [showModal, setShowModal] = React.useState(false)
  const [showTriggerModal, setShowTriggerModal] = React.useState(false)
  const [msgId, setMsgId] = React.useState<string>("")
  const [trigger, setTrigger] = React.useState<string>("")

  function swapElts(firstIndex: number, secondIndex: number) {
    const recommendationsInfosCopied = recommendationsInfos;
    const temp = recommendationsInfosCopied.recommendations[firstIndex];
    recommendationsInfosCopied.recommendations[firstIndex] = recommendationsInfosCopied.recommendations[secondIndex];
    recommendationsInfosCopied.recommendations[secondIndex] = temp;
    setRecommendationsInfos(recommendationsInfosCopied)
  }

  function messageIdToModalWithDefaultTrigger(pMsgId: string) {
    fetch(backendUrl + "/message_id_to_trigger?l=" + language + "&id=" + pMsgId)
      .then(res => res.json())
      .then(jsonData => {
        setMsgId(pMsgId)
        setTrigger(jsonData.trigger)
        setShowTriggerModal(true)
      })
      .catch(rejected => {
        console.log(rejected);
      });
  }

  return (
    <>
      <table>
        <thead className="visually-hidden">
          <tr>
            <th>Label</th>
            <th>Content</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <EditionLabel language={"fr"} textId="recommendations" />
            </td>
            <td style={{ textAlign: 'center', width: '100%' }}></td>
            <td style={{ paddingRight: '15px' }}><Button btnData={{
              type: ButtonType.ADD, onClick: () => {
                setSelectedElt(undefined)
                setShowModal(true)
              }
            }} language={language} isDarkTheme={hcContext.isDarkTheme} /></td>
          </tr>
        </tbody>
      </table>

      {
        recommendationsInfos.recommendations &&
        recommendationsInfos.recommendations.length > 0 &&
        <>
          {recommendationsInfos.recommendations.map((item, index) => {
            return <div key={"rec-list-elt-" + index}>
              <Recommendation isPrimary={true} onClick={() => window.open('/' + language + '/' + item.outMessageId, '_blank', 'rel=noopener noreferrer')}>
                {item.text}
              </Recommendation>

              {
                index > 0 &&
                <>
                  &nbsp;&nbsp;&nbsp;
                  <Button btnData={{ type: ButtonType.TOP, onClick: () => swapElts(index - 1, index) }}
                    language={language} alt={GetStrLocalized(language, "move_up")} isDarkTheme={hcContext.isDarkTheme} />
                </>
              }
              {
                index < recommendationsInfos.recommendations.length - 1 &&
                <>
                  &nbsp;&nbsp;&nbsp;
                  <Button btnData={{ type: ButtonType.DOWN, onClick: () => swapElts(index, index + 1) }}
                    language={language} alt={GetStrLocalized(language, "move_down")} isDarkTheme={hcContext.isDarkTheme} />
                </>
              }
              &nbsp;&nbsp;&nbsp;
              <Button btnData={{
                type: ButtonType.EDIT, onClick: () => {
                  setSelectedElt(index)
                  const rec = recommendationsInfos.recommendations[index]
                  setMsgId(rec.outMessageId)
                  setTrigger(rec.text)
                  setShowTriggerModal(true)
                }
              }} language={language} isDarkTheme={hcContext.isDarkTheme} />
              &nbsp;&nbsp;&nbsp;
              <Button btnData={{
                type: ButtonType.DELETE, onClick: () => {
                  const recommendationsInfosCopied = recommendationsInfos;
                  recommendationsInfosCopied.recommendations.splice(index, 1)
                  setRecommendationsInfos(recommendationsInfosCopied)
                }
              }} language={language} isDarkTheme={hcContext.isDarkTheme} />
            </div>
          })}

          <br />
          <input type="checkbox" checked={recommendationsInfos.replanIfNoRecommendationHasBeenTriggered === "true"} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            recommendationsInfos.replanIfNoRecommendationHasBeenTriggered = event.target.checked ? "true" : "false"
            setRecommendationsInfos(recommendationsInfos)
          }
          } />
          &nbsp;<GetHtmlStrLocalized language={language} textId="replanIfNoRecommendationHasBeenTriggered" />

          <br />
          <input type="checkbox" checked={recommendationsInfos.skipRecommendationToMessagesAlreadySaid === "true"} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            recommendationsInfos.skipRecommendationToMessagesAlreadySaid = event.target.checked ? "true" : "false"
            setRecommendationsInfos(recommendationsInfos)
          }
          } />
          &nbsp;<GetHtmlStrLocalized language={language} textId="skipRecommendationsAlreadySaid" />
        </>
      }

      {
        /*
  // Commented because allow to repeat seems useless
              <Select
        value={{ value: recommendationsInfos.allowToRepeat, label: recommendationsInfos.allowToRepeat }}
        onChange={(option: SelectEltProps | null) => {
          if (option !== null) {
            recommendationsInfos.allowToRepeat = option.value
            setRecommendationsInfos(recommendationsInfos)
          }
        }}
        getOptionLabel={(elt: SelectEltProps) => elt.label}
        getOptionValue={(elt: SelectEltProps) => elt.value}
        options={allPossibleAllowToRepeat}
        isClearable={false}
        backspaceRemovesValue={true}
      />
      */
      }

      <ArticleSelectorModal show={showModal} setShow={setShowModal}
        language={language} backendUrl={backendUrl} articleSelected={(id) => messageIdToModalWithDefaultTrigger(id)} hcContext={hcContext} />

      <TextEditionModal show={showTriggerModal} setShow={setShowTriggerModal} isDarkTheme={hcContext.isDarkTheme}
        language={language}
        titleId={selectedElt === undefined ? "addATrigger" : "modifyATest"}
        text={trigger}
        onTextValidated={(trigger) => {
          const recommendationsInfosCopied = recommendationsInfos;
          if (selectedElt !== undefined)
            recommendationsInfosCopied.recommendations[selectedElt] = { text: trigger, outMessageId: msgId }
          else
            recommendationsInfosCopied.recommendations = [...recommendationsInfos.recommendations, { text: trigger, outMessageId: msgId }]
          setRecommendationsInfos(recommendationsInfosCopied)
        }}
      />

    </>

  );
}