import GetHtmlStrLocalized from "../../datas/GetHtmlStrLocalized";

interface SubEditionLabelProps {
    paddingTop?: number;
    language: string;
    textId: string;
}

function SubEditionLabel({ paddingTop = 17, language, textId }: Readonly<SubEditionLabelProps>) {
    return (
        <span style={{ paddingTop, fontSize: 15, verticalAlign: 'middle' }}>
            <GetHtmlStrLocalized language={language} textId={textId} />
        </span>
    )
}

export default SubEditionLabel