import { SelectEltProps } from "./hccontext";


export interface SuggestedAnswerProps {
  text: string;
  isRightAnswer: boolean;
}


export interface QuizEditionProps {
  nb: number | null;
  name: string;
  question: string;
  source: SelectEltProps | null;
  sourceUrl: string;
  suggestedAnswers: SuggestedAnswerProps[];
  rightAnswer: string;
  wrongAnswer: string;
  liturgicalTime: string;
}

export class QuizEdition {
  value: QuizEditionProps;
  setNb: React.Dispatch<React.SetStateAction<number | null>>;
  setName: React.Dispatch<React.SetStateAction<string>>;
  setQuestion: React.Dispatch<React.SetStateAction<string>>;
  setSource: React.Dispatch<React.SetStateAction<SelectEltProps | null>>;
  setSourceUrl: React.Dispatch<React.SetStateAction<string>>;
  private readonly setSuggestedAnswers: React.Dispatch<React.SetStateAction<SuggestedAnswerProps[]>>;
  private readonly setSuggestedAnswersStr: React.Dispatch<React.SetStateAction<string>>;
  setRightAnswer: React.Dispatch<React.SetStateAction<string>>;
  setWrongAnswer: React.Dispatch<React.SetStateAction<string>>;
  setLiturgicalTime: React.Dispatch<React.SetStateAction<string>>;

  constructor(
    value: QuizEditionProps,
    setNb: React.Dispatch<React.SetStateAction<number | null>>,
    setName: React.Dispatch<React.SetStateAction<string>>,
    setQuestion: React.Dispatch<React.SetStateAction<string>>,
    setSource: React.Dispatch<React.SetStateAction<SelectEltProps | null>>,
    setSourceUrl: React.Dispatch<React.SetStateAction<string>>,
    setSuggestedAnswers: React.Dispatch<React.SetStateAction<SuggestedAnswerProps[]>>,
    setSuggestedAnswersStr: React.Dispatch<React.SetStateAction<string>>,
    setRightAnswer: React.Dispatch<React.SetStateAction<string>>,
    setWrongAnswer: React.Dispatch<React.SetStateAction<string>>,
    setLiturgicalTime: React.Dispatch<React.SetStateAction<string>>,
  ) {
    this.value = value;
    this.setNb = setNb;
    this.setName = setName;
    this.setQuestion = setQuestion;
    this.setSource = setSource;
    this.setSourceUrl = setSourceUrl;
    this.setSuggestedAnswers = setSuggestedAnswers;
    this.setSuggestedAnswersStr = setSuggestedAnswersStr;
    this.setRightAnswer = setRightAnswer;
    this.setWrongAnswer = setWrongAnswer;
    this.setLiturgicalTime = setLiturgicalTime;
  }

  set(value: QuizEditionProps) {
    this.setNb(value.nb)
    this.setName(value.name)
    this.setQuestion(value.question)
    this.setSource(value.source)
    this.setSourceUrl(value.sourceUrl)
    this.setSuggestedAnswersFn([ ...value.suggestedAnswers ])
    this.setRightAnswer(value.rightAnswer)
    this.setWrongAnswer(value.wrongAnswer)
    this.setLiturgicalTime(value.liturgicalTime)
  }

  setSuggestedAnswersFn(suggestedAnswers: SuggestedAnswerProps[]) {
    this.setSuggestedAnswers(suggestedAnswers);
    this.setSuggestedAnswersStr(JSON.stringify(suggestedAnswers))
  }
}
