
interface ContentWrapperForChatProps {
    children: React.ReactNode;
}


const ContentWrapperForChat: React.FC<ContentWrapperForChatProps> = ({ children }) => {

    return (
        <table width="100%">
            <thead className="visually-hidden"><tr><th></th></tr></thead>
            <tbody>
                <tr>
                    <td width="4%"></td>
                    <td width="96%" style={{ paddingLeft: 9, paddingBottom: 5 }}>
                        <div className="hc-width-chat-recommendation-max-width">
                            {children}
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    )
}

export default ContentWrapperForChat



