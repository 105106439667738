import React from 'react'
import GetStrLocalized from './GetStrLocalized';

const TranslationsEn: { [name: string]: string } = {
    "home": "Home",
    "categories": "Categories",
    "categoriesFolderName": "categories",
    "integral": "Integral",
    "fromDayToDay": "From day to day",
    "reasonsToBelieve": "Reasons to believe",
    "chatbot": "Chatbot",
    "readingsOfTheDay": "Readings of the day",
    "gospelOfTheDay": "Gospel of the day",
    "readings": "Readings",
    "bible": "Bible",
    "gospels": "Gospels",
    "sources": "Sources",
    "goToTheOtherLanguageVersion": "Version française",
    "about": "About",
    "recipientsOfTheSite": "Recipients of the site",
    "readyToChat": "READY TO CHAT?",
    "christianMessage": "Christian message",
    "validate": "Validate",
    "close": "Close",
    "relatedQuestions": "Related questions",
    "chatFromThisMessage": "Chat from this message",
    "biblicalReferences": "Biblical references",
    "referencesToTheCatechismOfTheCatholicChurch": "References to the Catechism of the Catholic Church",
    "catechismOfTheCatholicChurchNum": "Catechism of the Catholic Church n°",
    "site": "Site",
    "sites": "Sites",
    "oldTestament": "Old testament",
    "newTestament": "New testament",
    "prologue": "Prologue",
    "theProfessionOfFaith": "The Profession of Faith",
    "theCelebrationOfChristianMistery": "The Celebration of the Christian Mystery",
    "lifeInChrist": "Life in Christ",
    "christianPrayer": "Christian Prayer",
    "plan": "Plan",
    "examples": "Examples",
    "encyclicLetterPatrisCorde": "Apostolic letter Patris corde",
    "encyclicalFratelliTutti": "Encyclical Fratelli Tutti",
    "encyclicalLaudatoSi": "Encyclical Laudato si'",
    "readThePopesWritings": "Read the Pope's writings",
    "theSocialDoctrineOfTheChurch": "The Social Doctrine of the Church",
    "search": "Search",
    "helloTogetherLetsDiscoverTheChristianFaith": "Hello, together let's discover the Christian faith!",
    "novelties": "Novelties",
    "chatNovelties": "Chat novelties",
    "currentyResearching": "Currently researching...",
    "sorryWeDidNotFindAnyResults": "Sorry, we did not find any results",
    "sorryThisPageIsNotAvailable": "Sorry, this page is not available",
    "chargingTheMic": "CHARGING THE MIC...",
    "link": "Link",
    "signin": "Sign in",
    "seeAllMessages": "See all messages",
    "searchInThisSource": "Search in this source",
    "number": "Number",
    "name": "Name",
    "message": "Message",
    "triggers": "Triggers",
    "thumbnail": "Thumbnail",
    "duration": "Duration",
    "source": "Source",
    "modify": "Modify",
    "references": "References",
    "addAReference": "Add a reference",
    "modifyAReference": "Modify a reference",
    "typeOfReference": "Type of reference",
    "book": "Livre",
    "chapetersAndVerses": "Chapters et verses",
    "firstNumber": "First number",
    "lastNumber": "Last number",
    "url": "Url",
    "priorityFromAnExcerptFromTheBible": "Priority from an excerpt from the Bible",
    "tags": "Tags",
    "tests": "Tests",
    "tested": "Tested",
    "addATag": "Add a tag",
    "modifyATag": "Modify a tag",
    "addAFact": "Add a fact",
    "yes": "Yes",
    "no": "No",
    "addATrigger": "Add a trigger",
    "modifyATrigger": "Modify a trigger",
    "addADescription": "Add a description",
    "modifyADescription": "Modify a description",
    "addATest": "Add a test",
    "modifyATest": "Modify a test",
    "addASite": "Add a site",
    "modifyASite": "Modify a site",
    "addAnResource": "Add a resource",
    "image": "Picture",
    "select": "Select",
    "selectAnImage": "Select an image",
    "chooseFrom": "Choose from",
    "ok": "Ok",
    "privateSection": "Private section",
    "veryAdvancedActions": "Very advanced actions",
    "description": "Description",
    "descriptions": "Descriptions",
    "title": "Title",
    "isHidden": "Is hidden",
    "moveHere": "Move here",
    "cms": "CMS",
    "seeTheAnswer": "See the answer",
    "isTheAnswerCorrect": "Is the answer correct?",
    "theExpectedAnswerIsOk": "The expected answer is ok.",
    "theExpectedAnswerIsNotOk": "The expected answer is not ok.",
    "actualResult": "Actual result:",
    "differencesWithPreviouslySavedResult": "Differences With previously saved result:",
    "testsResults": "Tests results",
    "testsNotOk": "Tests not ok",
    "regenerateTheSitemapXml": "Regenerate the sitemap.xml",
    "sanityChecks": "Sanity checks",
    "generateSentencesForOnsemTests": "Generate sentences for onsem tests",
    "addASource": "Add a source",
    "addASourceInTheListOfPossibleSources": "Add a source in the list of possible sources",
    "seeRandomMessagesWithThisTag": "See random messages with this tag",
    "tag": "Tag",
    "numberOfMessages": "Number of messages",
    "numberOfVideos": "Number of videos",
    "numberOfAnswersToQuestions": "Number of answers to questions",
    "numberOfExternalAPIs": "Number of external APIs",
    "numberOfReferences": "Number of references",
    "messages": "Messages",
    "inNumbers": "In numbers",
    "numberOfTextualAnswers": "Number of related questions",
    "numberOfBibleReferences": "Number of Bible references",
    "numberOfCccReferences": "Number of Catechism of the Catholic Church references",
    "numberOfExternalReferencesReferences": "Number of external website references",
    "numberOfSources": "Number of sources",
    "hiddenForChatbotOnly": "Hidden, for chatbot only",
    "fact": "Fact",
    "factsForChatbotPlannification": "Facts for chatbot plannification",
    "condition": "Condition",
    "vocalTriggers": "Vocal triggers",
    "preferInContext": "Prefer in context",
    "precondition": "Precondition",
    "possibleEffect": "Possible effect",
    "effect": "Effect",
    "goalsToAdd": "Goals to add",
    "continuations": "Continuations",
    "commentaries": "Commentaries",
    "addAnEmoji": "Add a emoji",
    "addAnGif": "Add a gif",
    "vote": "Vote",
    "vote_noun": "Vote",
    "selectStarDesc": "Click on the star corresponding to the rating you want to give",
    "action": "Action",
    "author": "Author",
    "date": "Date",
    "contentEditHistory": "Content edit history",
    "type": "Type",
    "pointsToADeadLink": "Points to a dead link",
    "glossary": "Glossary",
    "word": "Word",
    "shortDefinition": "Short definition",
    "definition": "Definition",
    "urlInTheSource": "Url in the source",
    "selectAMessage": "Select a message",
    "followedByTheMessage": "Followed by the message",
    "recommendations": "Recommendations",
    "replanIfNoRecommendationHasBeenTriggered": "Replan if no recommendation has been triggered",
    "skipRecommendationsAlreadySaid": "Skip recommendation already said",
    "planning": "Planning",
    "logo": "Logo",
    "openSlack": "Open slack",
    "writeCccWithoutHtml": "Write ccc without html",
    "updateAutomatically": "Update automatically",
    "maxTimeForAutomaticUpdate": "Maximum video time for automatic update (in s)"
};

const TranslationsFr: { [name: string]: string } = {
    "home": "Accueil",
    "categories": "Catégories",
    "integral": "Intégral",
    "fromDayToDay": "Au jour le jour",
    "categoriesFolderName": "categories",
    "reasonsToBelieve": "Raisons de croire",
    "chatbot": "Chatbot",
    "readingsOfTheDay": "Lectures du jour",
    "gospelOfTheDay": "Évangile du jour",
    "readings": "Lectures",
    "bible": "Bible",
    "gospels": "Évangiles",
    "sources": "Sources",
    "goToTheOtherLanguageVersion": "Switch to English",
    "about": "A propos",
    "recipientsOfTheSite": "Destinataires du site",
    "readyToChat": "PRÊT À CHATTER ?",
    "christianMessage": "Message chrétien",
    "validate": "Valider",
    "close": "Fermer",
    "relatedQuestions": "Questions liées",
    "chatFromThisMessage": "Discuter à partir de ce message",
    "biblicalReferences": "Références bibliques",
    "referencesToTheCatechismOfTheCatholicChurch": "Références au Catéchisme de l'Église catholique",
    "catechismOfTheCatholicChurchNum": "Catéchisme de l'Église catholique n°",
    "site": "Site",
    "sites": "Sites",
    "oldTestament": "Ancien testament",
    "newTestament": "Nouveau testament",
    "prologue": "Prologue",
    "theProfessionOfFaith": "La profession de foi",
    "theCelebrationOfChristianMistery": "La célébration du mystère chrétien",
    "lifeInChrist": "La vie dans le Christ",
    "christianPrayer": "La prière chrétienne",
    "plan": "Plan",
    "examples": "Exemples",
    "encyclicLetterPatrisCorde": "Lettre apostolique Patris corde (Avec un Cœur de Père)",
    "encyclicalFratelliTutti": "Encyclique Fratelli Tutti",
    "encyclicalLaudatoSi": "Encyclique Laudato si'",
    "readThePopesWritings": "Lire les écrits du Pape",
    "theSocialDoctrineOfTheChurch": "La doctrine sociale de l'église",
    "search": "Rechercher",
    "helloTogetherLetsDiscoverTheChristianFaith": "Bonjour, ensemble découvrons la foi chrétienne !",
    "novelties": "Nouveautés",
    "chatNovelties": "Nouveautés pour le chat",
    "currentyResearching": "Recherche en cours...",
    "sorryWeDidNotFindAnyResults": "Désolé, nous n'avons trouvé aucun résultat",
    "sorryThisPageIsNotAvailable": "Désolé, cette page n'est pas disponible",
    "chargingTheMic": "CHARGEMENT DU MICRO...",
    "link": "Lien",
    "signin": "S'identifier",
    "seeAllMessages": "Voir tous les messages",
    "searchInThisSource": "Rechercher dans cette source",
    "number": "Numéro",
    "name": "Nom",
    "message": "Message",
    "triggers": "Déclencheurs",
    "thumbnail": "Thumbnail",
    "duration": "Durée",
    "source": "Source",
    "modify": "Modifier",
    "references": "Références",
    "addAReference": "Ajouter une référence",
    "modifyAReference": "Modifer une référence",
    "typeOfReference": "Type de référence",
    "book": "Livre",
    "chapetersAndVerses": "Chapitres et versets",
    "firstNumber": "Premier numéro",
    "lastNumber": "Dernier numéro",
    "url": "Url",
    "priorityFromAnExcerptFromTheBible": "Priorité depuis un passage de la Bible",
    "tags": "Tags",
    "tests": "Tests",
    "tested": "Testé",
    "addATag": "Ajouter un tag",
    "modifyATag": "Modifer un tag",
    "addAFact": "Ajouter un fait",
    "yes": "Oui",
    "no": "Non",
    "addATrigger": "Ajouter un déclencheur",
    "modifyATrigger": "Modifer un déclencheur",
    "addADescription": "Ajouter une description",
    "modifyADescription": "Modifer une description",
    "addATest": "Ajouter un test",
    "modifyATest": "Modifer un test",
    "addASite": "Ajouter un site",
    "modifyASite": "Modifer un site",
    "addAnResource": "Ajouter une ressource",
    "image": "Image",
    "select": "Sélectionner",
    "selectAnImage": "Sélectionner une image",
    "chooseFrom": "Choisir depuis",
    "ok": "Ok",
    "privateSection": "Section privée",
    "veryAdvancedActions": "Actions très avancées",
    "description": "Description",
    "descriptions": "Descriptions",
    "title": "Titre",
    "isHidden": "Est caché",
    "moveHere": "Déplacer ici",
    "cms": "CMS",
    "seeTheAnswer": "Voir la réponse",
    "isTheAnswerCorrect": "Est-ce que la réponse est correcte ?",
    "theExpectedAnswerIsOk": "La réponse attendue est ok.",
    "theExpectedAnswerIsNotOk": "La réponse attendue n'est pas ok.",
    "actualResult": "Résultat actuel:",
    "differencesWithPreviouslySavedResult": "Différences avec le résultat précédemment enregistré:",
    "testsResults": "Résultats des tests",
    "testsNotOk": "Tests pas ok",
    "regenerateTheSitemapXml": "Regénérer le sitemap.xml",
    "sanityChecks": "Contrôles de santé",
    "generateSentencesForOnsemTests": "Générer des phrases pour des tests de onsem",
    "addASource": "Ajouter une source",
    "addASourceInTheListOfPossibleSources": "Ajouter une source dans la liste des sources possibles",
    "seeRandomMessagesWithThisTag": "Voir des messages au hasard avec ce tag",
    "tag": "Tag",
    "numberOfMessages": "Nombre de messages",
    "numberOfVideos": "Nombre de vidéos",
    "numberOfAnswersToQuestions": "Nombre de réponses aux questions",
    "numberOfExternalAPIs": "Nombre de d'API externes",
    "numberOfReferences": "Nombre de références",
    "messages": "Messages",
    "inNumbers": "En chiffres",
    "numberOfTextualAnswers": "Nombre de questions liées",
    "numberOfBibleReferences": "Nombre de références à la Bible",
    "numberOfCccReferences": "Nombre de références au Catéchisme de l'Église catholique",
    "numberOfExternalReferencesReferences": "Nombre de références à des sites externes",
    "numberOfSources": "Nombre de sources",
    "hiddenForChatbotOnly": "Caché, pour le chatbot uniquement",
    "fact": "Fait",
    "factsForChatbotPlannification": "Faits pour la planification des chatbots",
    "condition": "Condition",
    "vocalTriggers": "Déclencheurs vocaux",
    "preferInContext": "Privilégier dans le contexte",
    "precondition": "Précondition",
    "possibleEffect": "Effet possible",
    "effect": "Effet",
    "goalsToAdd": "Objectifs à ajouter",
    "continuations": "Continuations",
    "commentaries": "Commentaires",
    "addAnEmoji": "Ajouter un emoji",
    "addAnGif": "Ajouter un gif",
    "vote": "Voter",
    "vote_noun": "Vote",
    "selectStarDesc": "Cliquez sur l'étoile correspondant à la note que vous souhaitez attribuer",
    "action": "Action",
    "author": "Auteur",
    "date": "Date",
    "contentEditHistory": "Historique des modifications du contenu",
    "type": "Type",
    "pointsToADeadLink": "Pointe vers un lien mort",
    "glossary": "Glossaire",
    "word": "Mot",
    "shortDefinition": "Courte définition",
    "definition": "Définition",
    "urlInTheSource": "Url dans la source",
    "selectAMessage": "Sélectionner un message",
    "followedByTheMessage": "Suivi par le message",
    "recommendations": "Recommandations",
    "replanIfNoRecommendationHasBeenTriggered": "Replanifier si aucune recommendation n'a été triggée",
    "skipRecommendationsAlreadySaid": "Sauter les recommandations déjà dites",
    "planning": "Planification",
    "logo": "Logo",
    "openSlack": "Ouvrir slack",
    "writeCccWithoutHtml": "Ecrire ccc sans html",
    "updateAutomatically": "Mise à jour automatique",
    "maxTimeForAutomaticUpdate": "Maximum temps des vidéos pour l'update automatique (in s)"
};


interface GetHtmlStrLocalizedProps {
    language: string;
    textId: string;
    param1?: string;
}

const GetHtmlStrLocalized: React.FC<GetHtmlStrLocalizedProps> = ({ language, textId, param1 = null }) => {
    if (param1 !== null) {
        if (language === "fr") {
            if (textId === "moveTowards")
                return <>Déplacer "{param1}" vers...</>
        }
        if (textId === "moveTowards")
            return <>Move "{param1}" towards...</>
    }

    let value = ""
    if (language === "fr")
        value = TranslationsFr[textId];
    else
        value = TranslationsEn[textId];
    if (value === undefined)
        value = GetStrLocalized(language, textId);
    return <>{value}</>
}


export default GetHtmlStrLocalized