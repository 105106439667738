import React from 'react'
import BigButtonWithTextAtBottom from './BigButtonWithTextAtBottom'
import { BigButtonProps } from '../../props/bigbuttonprops';
import { HcContext } from '../../types/hccontext';

interface BigButtonsWithTextAtBottomProps {
	videos: BigButtonProps[];
	hcContext: HcContext;
}

function BigButtonsWithTextAtBottom({videos, hcContext}: Readonly<BigButtonsWithTextAtBottomProps>) {

	return (
		<React.Fragment>
			{
				videos?.map((videoItem) => (
					<BigButtonWithTextAtBottom 
						key={videoItem.link} 
						link={videoItem.link} 
						image={videoItem.image} 
						duration={videoItem.duration} 
						title={videoItem.title} 
						tags={videoItem.subTitle} 
						descriptionImage={videoItem.sourceImage} 
						description={[videoItem.sourceName]} 
						beginOfClassName="hc-big-button-normal" 
						stars={videoItem.stars} 
						hcContext={hcContext} 
					/>
				))
			}
		</React.Fragment>
	)
}

export default BigButtonsWithTextAtBottom