import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getCookie } from 'typescript-cookie'
import CategoriesBigButtons, { CategoryProps } from "../components/bigButton/CategoriesBigButtons";
import H1TitleId from "../components/title/H1TitleId";
import H2TitleId from "../components/title/H2TitleId";
import Centered from "../components/util/Centered";
import PageContent from "../components/util/PageContent";
import { HcContext, SizeOfWindow } from "../types/hccontext";
import GetStrLocalized from "../datas/GetStrLocalized";
import { createErrorModalContent } from "../props/ModalContentProps";


interface HomeProps {
	language: string;
	hcContext: HcContext;
};


export class HomeGetContentType {
	language: string;
	messageIds: string[];
	allRecommendationIds: string[];

	constructor(
		language: string,
		messageIds: string[],
		allRecommendationIds: string[]) {
		this.language = language;
		this.messageIds = messageIds;
		this.allRecommendationIds = allRecommendationIds;
	}
}



function Home({ language, hcContext }: Readonly<HomeProps>) {

	useEffect(() => {
		hcContext.setDocumentTitle("Holy Chatter");
	}, [hcContext]);

	const location = useLocation();
	const [smallNbOfVideoForShortScreen, setSmallNbOfVideoForShortScreen] = useState(true)
	const [lastPath, setLastPath] = useState("")
	const [request, setRequest] = useState({ recommendations: [], categories: [], recentVideos: [], recentChatMessages: [] })
	const messageIdsCookie = getCookie('messageIds')
	const allRecommendationIdsCookie = getCookie('allRecommendationIds')
	let categories: CategoryProps[] = [
		{
			nb: undefined, type: "", id: "", link: "/fr/chatbot", image: "/buttons/christian_chatbot.jpg",
			duration: "", title: "Chatbot chrétien", subTitle: "", sourceName: "", sourceImage: "", stars: undefined
		},
		{
			nb: undefined, type: "", id: "", link: GetStrLocalized("fr", "readingsFolderName") + "/" + GetStrLocalized("fr", "bibleFolderName"), image: "/buttons/bible.jpg",
			duration: "", title: "Bible", subTitle: "", sourceName: "", sourceImage: "", stars: undefined
		},
		{
			nb: undefined, type: "", id: "", link: "/fr/categories/raisons-de-croire", image: "/buttons/penser.jpg",
			duration: "", title: "Raisons de croire", subTitle: "", sourceName: "", sourceImage: "", stars: undefined
		},
		{
			nb: undefined, type: "", id: "", link: "/fr/categories/temoignages", image: "/buttons/prises_de_parole.jpeg",
			duration: "", title: "Témoignages", subTitle: "", sourceName: "", sourceImage: "", stars: undefined
		},
		{
			nb: undefined, type: "", id: "", link: "/fr/categories/decouverte", image: "/buttons/discover.jpg",
			duration: "", title: "Découverte", subTitle: "", sourceName: "", sourceImage: "", stars: undefined
		},
		{
			nb: undefined, type: "", id: "", link: "/fr/categories/solidarite-3", image: "/buttons/solidarite.jpg",
			duration: "", title: "Solidarité", subTitle: "", sourceName: "", sourceImage: "", stars: undefined
		},
		{
			nb: undefined, type: "", id: "", link: "/fr/categories/enseignements", image: "/buttons/teachings.jpeg",
			duration: "", title: "Enseignements", subTitle: "", sourceName: "", sourceImage: "", stars: undefined
		},
		{
			nb: undefined, type: "", id: "", link: "/fr/categories/eglise", image: "/buttons/church.jpg",
			duration: "", title: "Eglise", subTitle: "", sourceName: "", sourceImage: "", stars: undefined
		},
		{
			nb: undefined, type: "", id: "", link: "/fr/categories/prieres", image: "/buttons/prier.jpg",
			duration: "", title: "Prières", subTitle: "", sourceName: "", sourceImage: "", stars: undefined
		},
		{
			nb: undefined, type: "", id: "", link: "/fr/categories/personnes-memorables", image: "/buttons/person.jpg",
			duration: "", title: "Personnes mémorables", subTitle: "", sourceName: "", sourceImage: "", stars: undefined
		},
		{
			nb: undefined, type: "", id: "", link: "/fr/categories/humour", image: "/buttons/laugh.png",
			duration: "", title: "Humour", subTitle: "", sourceName: "", sourceImage: "", stars: undefined
		},
		{
			nb: undefined, type: "", id: "", link: "/fr/categories/dieu", image: "/buttons/god.jpg",
			duration: "", title: "Dieu", subTitle: "", sourceName: "", sourceImage: "", stars: undefined
		}
	]

	if (language === "en") {
		categories = [
			{
				nb: undefined, type: "", id: "", link: "/en/categories/reasons-to-believe", image: "/buttons/penser.jpg",
				duration: "", title: "Reasons to believe", subTitle: "", sourceName: "", sourceImage: "", stars: undefined
			},
			{
				nb: undefined, type: "", id: "", link: "/en/categories/testimonies", image: "/buttons/prises_de_parole.jpeg",
				duration: "", title: "Testimonies", subTitle: "", sourceName: "", sourceImage: "", stars: undefined
			},
			{
				nb: undefined, type: "", id: "", link: GetStrLocalized("en", "readingsFolderName") + "/" + GetStrLocalized("en", "bibleFolderName"), image: "/buttons/bible.jpg",
				duration: "", title: "Bible", subTitle: "", sourceName: "", sourceImage: "", stars: undefined
			},
			{
				nb: undefined, type: "", id: "", link: "/en/categories/discovery", image: "/buttons/discover.jpg",
				duration: "", title: "Discovery", subTitle: "", sourceName: "", sourceImage: "", stars: undefined
			},
			{
				nb: undefined, type: "", id: "", link: "/en/categories/solidarity", image: "/buttons/solidarite.jpg",
				duration: "", title: "Solidarity", subTitle: "", sourceName: "", sourceImage: "", stars: undefined
			},
			{
				nb: undefined, type: "", id: "", link: "/en/categories/teachings", image: "/buttons/teachings.jpeg",
				duration: "", title: "Teachings", subTitle: "", sourceName: "", sourceImage: "", stars: undefined
			},
			{
				nb: undefined, type: "", id: "", link: "/en/categories/church", image: "/buttons/church.jpg",
				duration: "", title: "Church", subTitle: "", sourceName: "", sourceImage: "", stars: undefined
			},
			{
				nb: undefined, type: "", id: "", link: "/en/categories/prayers", image: "/buttons/prier.jpg",
				duration: "", title: "Prayers", subTitle: "", sourceName: "", sourceImage: "", stars: undefined
			},
			{
				nb: undefined, type: "", id: "", link: "/en/categories/memorable-people", image: "/buttons/person.jpg",
				duration: "", title: "Memorable people", subTitle: "", sourceName: "", sourceImage: "", stars: undefined
			},
			{
				nb: undefined, type: "", id: "", link: "/en/categories/humor", image: "/buttons/laugh.png",
				duration: "", title: "Humor", subTitle: "", sourceName: "", sourceImage: "", stars: undefined
			},
			{
				nb: undefined, type: "", id: "", link: "/en/categories/god", image: "/buttons/god.jpg",
				duration: "", title: "God	", subTitle: "", sourceName: "", sourceImage: "", stars: undefined
			},
			{
				nb: undefined, type: "", id: "", link: "/en/categories/heaven", image: "/buttons/paradis.jpeg",
				duration: "", title: "Heaven", subTitle: "", sourceName: "", sourceImage: "", stars: undefined
			}
		]
	}

	if (location.pathname !== lastPath) {
		setLastPath(location.pathname);
		const messageIdsCookieVector = messageIdsCookie?.split("|")
		const allRecommendationIdsCookieVector = allRecommendationIdsCookie?.split("|")
		const postContent = new HomeGetContentType(language, messageIdsCookieVector ?? [],
			allRecommendationIdsCookieVector ?? [])
		fetch(hcContext.backendUrl + "/home_content", {
			method: 'POST',
			body: JSON.stringify(postContent),
			headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
		})
			.then(res => res.json())
			.then(jsonData => {
				if (jsonData.errorMessage) {
					hcContext.setInfo(createErrorModalContent(language, jsonData.errorMessage))
					return
				}
				setRequest(jsonData)
			})
			.catch(rejected => {
				hcContext.setInfo(createErrorModalContent(language, rejected.message))
			});
	}

	return (
		<PageContent>
			<H1TitleId language={language} titleId="helloTogetherLetsDiscoverTheChristianFaith" />
			<br /><br />

			<CategoriesBigButtons categories={request.recommendations} numberOfCategoriesToDisplay={hcContext.sizeOfWindow === SizeOfWindow.VERY_SHORT && smallNbOfVideoForShortScreen ? 2 : 100} hcContext={hcContext} />
			<br />
			{
				hcContext.sizeOfWindow === SizeOfWindow.VERY_SHORT &&
				<div className="hc-content-text">
					<Centered>
						{
							smallNbOfVideoForShortScreen ?
								<span onClick={() => setSmallNbOfVideoForShortScreen(false)} className="hc-clickable">Voir plus de vidéos</span>
								:
								<span onClick={() => setSmallNbOfVideoForShortScreen(true)} className="hc-clickable">Voir moins de vidéos</span>
						}
					</Centered>
					<br />
				</div>
			}

			<H2TitleId language={language} titleId="categories" />
			<CategoriesBigButtons categories={categories} hcContext={hcContext} />
			<br />

			<H2TitleId language={language} titleId="novelties" />
			<CategoriesBigButtons beginOfKey="recent-videos-" categories={request.recentVideos} hcContext={hcContext} />
			<br />

		</PageContent>
	)
}



export default Home;