import React, { useEffect, useRef, useState } from "react";
import { setCookie } from "typescript-cookie";
import { ChatbotMessageProps } from "../../props/chatbotmessageprops";
import { RecommandationProps } from "../../props/recomendationprops";
import { SuggestionProps } from "../../props/suggestionprops";
import { chatbotAlreadyStartedCookieName, ChatbotContext } from "../../types/chatbotcontext";
import BubbleFromUserInChat from "./BuddleFromUserInChat";
import BubbleToUserInChat from "./BuddleToUserInChat";
import ContentWrapperForChat from "./ContentWrapperForChat";
import Recommendation from "./Recommendation";
import TextWithLinks from "../util/TextWithLinks";
import { HcContext } from "../../types/hccontext";
import { createErrorModalContent } from "../../props/ModalContentProps";

interface ChatbotWindowProps {
    language: string;
    hcContext: HcContext;
    sessionId: string;
    chatbotContext: ChatbotContext;
    writtenText: string;
    setWrittenText: React.Dispatch<React.SetStateAction<string>>;
    setMenuSuggestions: React.Dispatch<React.SetStateAction<SuggestionProps[]>>;
    chatbotId: string;
    videoWidthClassName: string;
    debugMode: boolean;
}

function ChatbotContentWidget({ language, hcContext, sessionId, chatbotContext, writtenText, setWrittenText, setMenuSuggestions, chatbotId, videoWidthClassName, debugMode }: Readonly<ChatbotWindowProps>) {

    const messagesEndRef = useRef<HTMLDivElement>(null);
    const [isWritingInstantMessages, setIsWritingInstantMessages] = useState(false);
    const [botIsWriting, setBotIsWriting] = useState(false);
    const [writePendingMessage, setWritePendingMessage] = useState(false);
    const [botMessagesToWrite, setBotMessagesToWrite] = useState<ChatbotMessageProps[]>([]);
    const [messages, setMessages] = useState<ChatbotMessageProps[]>([]);
    const [recommendationsToWrite, setRecommendationsToWrite] = useState([]);
    const [recommendations, setRecommendations] = useState<RecommandationProps[]>([]);
    const [timeBeforeBotMessage, setTimeBeforeBotMessage] = useState(0);

    const [ws, setWs] = useState<WebSocket | null>(null);

    useEffect(() => {
        // Initialize WebSocket connection
        const socket = new WebSocket(hcContext.backendUrl.replace("http", "ws") + "/chat");
        setWs(socket);

        socket.onopen = () => {
            console.log("WebSocket connection opened.");
            sendInitialMessage(socket);
        };

        socket.onclose = (event) => {
            console.log("WebSocket connection closed: ", event.reason);
        };

        socket.onerror = (error) => {
            console.error("WebSocket error: ", error);
            hcContext.setInfo(createErrorModalContent(language, "WebSocket connection error"));
        };

        socket.onmessage = (event) => {
            handleWebSocketMessage(event.data);
        };

        return () => {
            socket.close();
        };
    }, []);

    useEffect(() => {
        if (!writePendingMessage) return;
        if (botMessagesToWrite.length > 0) {
            const messagesLocal = [...messages, botMessagesToWrite[0]];
            setMessages(messagesLocal);
            botMessagesToWrite.shift();
            setBotMessagesToWrite(botMessagesToWrite);

            if (botMessagesToWrite.length === 0) {
                setRecommendations(recommendationsToWrite);
            } else {
                setTimeBeforeBotMessage(2);
            }
        }
        setWritePendingMessage(false);
        setBotIsWriting(false);
    }, [writePendingMessage]);

    useEffect(() => {
        if (writtenText) {
            onNewText(writtenText);
            setWrittenText("");
        }
    }, [writtenText]);

    useEffect(() => {
        setTimeout(() => { // Start the timer
            if (timeBeforeBotMessage === 2) {
                setTimeBeforeBotMessage(1);
                setBotIsWriting(true);
            } else if (timeBeforeBotMessage === 1) {
                setWritePendingMessage(true);
            }
        }, 1000);
    }, [timeBeforeBotMessage]);

    const sendInitialMessage = (socket: WebSocket) => {
        const hours = new Date().getHours();
        const timestamp = Math.floor(Date.now() / 1000);
        const message = {
            l: language,
            sessionId: sessionId,
            hour: hours,
            timestamp: timestamp,
            newPage: true,
            chatbotId: chatbotId,
            alreadyStarted: chatbotContext.hasAWaitingMessage,
            debugMode: debugMode,
        };
        socket.send(JSON.stringify(message));

        chatbotContext.setStarted(true);
        chatbotContext.setHasAWaitingMessage(false);
        setCookie(chatbotAlreadyStartedCookieName, "true");
    };

    const handleWebSocketMessage = (data: string) => {
        const jsonData = JSON.parse(data);

        if (jsonData.errorMessage) {
            hcContext.setInfo(createErrorModalContent(language, jsonData.errorMessage));
            return;
        }

        if (jsonData.instantBubbles && jsonData.instantBubbles.length > 0) {
            setIsWritingInstantMessages(true);
            setMessages((prevMessages) => [...prevMessages, ...jsonData.instantBubbles]);
        }

        if (jsonData.bubbles && jsonData.bubbles.length > 0) {
            setBotMessagesToWrite((prevMessages) => [...prevMessages, ...jsonData.bubbles]);
            if (jsonData.recommendations) {
                setRecommendationsToWrite(jsonData.recommendations);
            } else {
                setRecommendationsToWrite([]);
            }
            setTimeBeforeBotMessage(2);
        } else {
            if (jsonData.recommendations) {
                setRecommendations(jsonData.recommendations);
            } else {
                setRecommendations([]);
            }
        }

        if (jsonData.bubbleContentToAppendToLastMessage) {
            setMessages((prevMessages) => {
                if (prevMessages.length === 0) {
                    return prevMessages; // No messages to update
                }

                const updatedMessages = [...prevMessages];
                const lastMessage = updatedMessages[updatedMessages.length - 1];

                // Update the last message in the array
                updatedMessages[updatedMessages.length - 1] = {
                    ...lastMessage,
                    elts: [
                        ...lastMessage.elts,
                        ...jsonData.bubbleContentToAppendToLastMessage
                    ],
                };

                return updatedMessages;
            });
        }

        if (jsonData.menuSuggestions) {
            setMenuSuggestions(jsonData.menuSuggestions);
        }
        setIsWritingInstantMessages(false);
    };

    const writeBubble = (fromUser: string, str: string, isHtmlCode: string): ChatbotMessageProps => {
        return {
            elts: [
                {
                    str: str,
                    isHtmlCode: isHtmlCode,
                    wordsFromDictionary: [],
                },
            ],
            fromUser: fromUser,
            source: {
                name: "",
                iconPath: "",
                path: "",
                url: "",
            },
            recommendations: [],
            plannerProblem: "",
        };
    };

    const onNewText = (newtext: string) => {
        if (newtext === "") return;

        setRecommendations([]);
        const bubble = writeBubble("true", newtext, "false");
        setMessages((prevMessages) => [...prevMessages, bubble]);

        const timestamp = Math.floor(Date.now() / 1000);
        const message = {
            l: language,
            userKey: hcContext.userToken,
            sessionId: sessionId,
            textInput: newtext,
            timestamp: timestamp,
            debugMode: debugMode,
        };
        ws?.send(JSON.stringify(message));
    };

    useEffect(() => {
        // Scroll to bottom
        if (isWritingInstantMessages) {
            messagesEndRef.current?.scrollIntoView();
        } else {
            messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
        }
    }, [botIsWriting, messages, recommendations]);

    const replaceWidthClassName = (str: string): string => {
        if (videoWidthClassName !== "hc-width-chat-inside-bubble")
            return str.replace("hc-width-chat-inside-bubble", videoWidthClassName);
        return str;
    };

    return (
        <div className="hc-chat-content">
            <br />
            {messages.map((message, messageIndex) => (
                <span key={`chat-msg-${messageIndex}`}>
                    {message.fromUser === "true" ? (
                        <BubbleFromUserInChat>
                            {message.elts.map((elt, eltIndex) => (
                                <span key={`msg-elt-${messageIndex}-${eltIndex}`}>{elt.str}</span>
                            ))}
                        </BubbleFromUserInChat>
                    ) : (
                        <BubbleToUserInChat source={message.source}>
                            {message.elts.map((elt, eltIndex) => (
                                <span key={`msg-elt-${messageIndex}-${eltIndex}`}>
                                    {elt.isHtmlCode === "true" ? (
                                        <span dangerouslySetInnerHTML={{ __html: replaceWidthClassName(elt.str) }}></span>
                                    ) : (
                                        <span>
                                            <TextWithLinks hcContext={hcContext} text={elt.str} wordsFromDictionary={elt.wordsFromDictionary} />
                                        </span>
                                    )}
                                </span>
                            ))}
                        </BubbleToUserInChat>
                    )}
                    {message.plannerProblem !== "" && (
                        <div style={{ paddingLeft: 30, paddingRight: 30 }} dangerouslySetInnerHTML={{ __html: message.plannerProblem }}></div>
                    )}
                    <ContentWrapperForChat>
                        {message.recommendations?.map((recommendation, recIndex) => (
                            <span key={`msg-rec-${messageIndex}-${recIndex}`}>
                                <Recommendation isPrimary={recommendation.isPrimary === "true"} onClick={() => onNewText(recommendation.str)}>
                                    <span dangerouslySetInnerHTML={{ __html: recommendation.str }}></span>
                                </Recommendation>
                            </span>
                        ))}
                    </ContentWrapperForChat>
                </span>
            ))}
            <ContentWrapperForChat>
                {recommendations?.map((recommendation, recIndex) => (
                    <span key={`request-rec-${recIndex}`}>
                        <Recommendation isPrimary={recommendation.isPrimary === "true"} onClick={() => onNewText(recommendation.str)}>
                            <span dangerouslySetInnerHTML={{ __html: recommendation.str }}></span>
                        </Recommendation>
                    </span>
                ))}
                {botIsWriting && <div className="hc-writing-box" style={{ lineHeight: '27px', margin: '5px' }} />}
            </ContentWrapperForChat>

            <div ref={messagesEndRef} />
        </div>
    );
}

export default ChatbotContentWidget;