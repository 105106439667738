import { useEffect, useState } from "react"
import { Navigate, useLocation } from "react-router-dom"
import H1Title from '../components/title/H1Title'
import PageContent from '../components/util/PageContent'
import GetStrLocalized from '../datas/GetStrLocalized'
import { HcContext } from '../types/hccontext'
import HorizontalSpace from '../components/util/HorizontalSpace'
import GetHtmlStrLocalized from "../datas/GetHtmlStrLocalized"
import { createErrorModalContent } from '../props/ModalContentProps'


interface UserStatProps {
	userEmail: string;
	count: string;
}

interface ChatHistoryProps {
	language: string;
	hcContext: HcContext;
}


class ContentModificationsPostContentType {
	language: string;
	userToken: string;

	constructor(
		language: string,
		userToken: string) {
		this.language = language
		this.userToken = userToken
	}
}


function ChatHistory({ language, hcContext }: Readonly<ChatHistoryProps>) {

	useEffect(() => {
		hcContext.setDocumentTitle(GetStrLocalized(language, "chatHistory") + " - Holy Chatter");
	}, [hcContext, language]);

	const location = useLocation()
	const [lastPath, setLastPath] = useState("")
	const [lastSearch, setLastSearch] = useState("")
	const [usersStat, setUsersStat] = useState<UserStatProps[]>([])


	if (location.pathname !== lastPath || location.search !== lastSearch) {
		setLastPath(location.pathname)
		setLastSearch(location.search)
		fetch(hcContext.backendUrl + "/chat_statistics")
			.then(res => res.json())
			.then(jsonData => {
				if (jsonData.counters === "")
					jsonData.counters = []
				setUsersStat(jsonData.counters)
			})
			.catch(rejected => {
				hcContext.setInfo(createErrorModalContent(language, rejected.message))
			});
	}



	return (
		<PageContent>
			{
				!hcContext.userAsRightsToModifyTheContent &&
				<Navigate to={"/" + language} />
			}

			<table width='100%'>
				<thead className="visually-hidden"><tr><th></th></tr></thead>
				<tbody>
					<tr>
						<td width={(30 + 15) * 3 + 45} style={{ paddingTop: 20 }} className="hc-not-very-short-screen">
							<HorizontalSpace width={45} />
						</td>
						<td>
							<H1Title><GetHtmlStrLocalized language={language} textId="chatHistory" /></H1Title>
						</td>
						<td width={(30 + 15) * 3 + 45} className="hc-not-very-short-screen"></td>
					</tr>
				</tbody>
			</table>

			<br />
			<div className='hc-content-text-paddings'>
				{
					usersStat.map((item, index) => {
						return <div key={"user-stat-" + index}>user: {item.userEmail} count: {item.count}
						</div>
					})
				}
			</div>
		</PageContent>
	)
}

export default ChatHistory;