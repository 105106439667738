import React, { ChangeEvent, useEffect, useState } from 'react'
import Modal, { ModalBody, ModalFooter, ModalHeader } from './Modal'
import { HcContext } from '../../types/hccontext';
import GetHtmlStrLocalized from '../../datas/GetHtmlStrLocalized';
import { HcInput } from '../input/HcInput';
import { createErrorModalContent } from '../../props/ModalContentProps';
import InputWithStyle from '../banner/InputWithStyle';


interface AddFactModalProps {
    language: string;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    hcContext: HcContext;
    onModification?: () => any;
}

export class FactAdditionPostContentType {
    language: string;
    userKey: string;
    id: string;

    constructor(
        language: string,
        userKey: string,
        id: string) {
        this.language = language;
        this.userKey = userKey;
        this.id = id;
    }
}

function AddFactModal({ language, show, setShow, hcContext, onModification }: Readonly<AddFactModalProps>) {

    const [newId, setNewId] = useState<string>("")

    useEffect(() => {
        if (show)
            setNewId("")
    }, [show]);

    function sendFactAddition() {
        if (!hcContext.userToken)
            return
        const postContent = new FactAdditionPostContentType(language, hcContext.userToken, newId)
        fetch(hcContext.backendUrl + "/add_fact", {
            method: 'POST',
            body: JSON.stringify(postContent),
            headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        })
            .then(res => res.json())
            .then(jsonData => {
                if (jsonData.errorMessage) {
                    hcContext.setInfo(createErrorModalContent(language, jsonData.errorMessage))
                } else if (jsonData.id === newId && onModification !== undefined) {
                    onModification()
                }
                setShow(false)
            })
            .catch(rejected => {
                setShow(false)
                hcContext.setInfo(createErrorModalContent(language, rejected.message))
            });
    }

    return (
        <Modal
            language={language}
            show={show}
            setShow={setShow}
            isDarkTheme={hcContext.isDarkTheme}
        >
            <ModalHeader>
                <h3><GetHtmlStrLocalized language={language} textId={"addAFact"} /></h3>
            </ModalHeader>
            <ModalBody>
                <InputWithStyle value={newId} onValidation={sendFactAddition}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setNewId(event.target.value)
                    }} isDarkTheme={hcContext.isDarkTheme} height={hcContext.defaultLineEditHeight()} autoFocus={true} />
            </ModalBody>
            <br />
            <ModalFooter>
                {
                    newId !== "" &&
                    <button onClick={() => {
                        sendFactAddition()
                    }}>
                        <GetHtmlStrLocalized language={language} textId="validate" />
                    </button>
                }
            </ModalFooter>
        </Modal>
    );
}

export default AddFactModal;