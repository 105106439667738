import { FactOrText } from "../../props/FactsProps";
import { DisplayPannerFactWithLink } from "./DisplayPlannerFactWithLink";


interface DisplayPreconditionFactProps {
    preconditionFact: FactOrText[];
    language: string;
}

export function DisplayPreconditionFact({ preconditionFact, language }: Readonly<DisplayPreconditionFactProps>) {
    return (
        <>
            {
                preconditionFact.map((factOrText, factOrTextIndex) => (
                    <span key={`fact-or-text-${factOrTextIndex}`}>{factOrText.text !== "" ? factOrText.text : <DisplayPannerFactWithLink fact={factOrText.fact} language={language} />}</span>
                ))
            }
        </>
    )
}