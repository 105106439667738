import React from "react"
import H2TitleId from '../title/H2TitleId'
import { ReferenceProps } from "../../props/referenceprops"
import DisplayAReference from "./DisplayAReference"
import { HcContext } from "../../types/hccontext";


interface RefsProps {
    language: string;
    type: string;
    refs: ReferenceProps[];
    allowToDisplayLinkFromCorpus: boolean;
    hcContext: HcContext;
}

function Refs({ language, type, refs, allowToDisplayLinkFromCorpus, hcContext }: Readonly<RefsProps>) {

    return (
        <React.Fragment>
            <br />
            {
                refs !== null &&
                refs.length > 0 &&
                <React.Fragment>
                    <H2TitleId language={language} titleId={type === "bible" ? "biblicalReferences" : "referencesToTheCatechismOfTheCatholicChurch"} />
                    {
                        refs.map((chapterItem, chapterIndex) => {
                            return (
                                <div key={"bible-chapter-" + chapterIndex} style={{ marginLeft: 40 }}>
                                    <br />
                                    &nbsp;&nbsp;
                                    <DisplayAReference language={language} type={type} reference={chapterItem} allowToDisplayLinkFromCorpus={allowToDisplayLinkFromCorpus} hcContext={hcContext} />
                                </div>
                            )
                        })
                    }
                </React.Fragment>
            }
        </React.Fragment>
    )
}

export default Refs