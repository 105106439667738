import { ModalContentProps } from "../props/ModalContentProps";
import { UserProps } from "../props/userprops";
import Select, { StylesConfig } from 'react-select';

export const darkTextColor = '#22292f'

export enum SizeOfWindow {
  VERY_SHORT,
  INTERMEDIARY,
  WIDE
}

export interface SelectEltProps {
  value: string;
  label: string;
}


export class HcContext {
  documentTitle: string;
  setDocumentTitle: React.Dispatch<React.SetStateAction<string>>;
  sizeOfWindow: SizeOfWindow;
  setSizeOfWindow: React.Dispatch<React.SetStateAction<SizeOfWindow>>;
  backendUrl: string;
  backendUrlNodeJS: string;
  user: UserProps | undefined;
  private readonly setUser: React.Dispatch<React.SetStateAction<UserProps | undefined>>;
  userToken: string | undefined;
  private readonly setUserToken: React.Dispatch<React.SetStateAction<string | undefined>>;
  userIsIdentified: boolean;
  private readonly setUserIsIdentified: React.Dispatch<React.SetStateAction<boolean>>;
  userAsRightsToModifyTheContent: boolean;
  private readonly setUserAsRightsToModifyTheContent: React.Dispatch<React.SetStateAction<boolean>>;
  blackCoverOfContent: boolean;
  setBlackCoverOfContent: React.Dispatch<React.SetStateAction<boolean>>;
  info: ModalContentProps;
  setInfo: React.Dispatch<React.SetStateAction<ModalContentProps>>;
  isDarkTheme: boolean;

  constructor(
    documentTitle: string,
    setDocumentTitle: React.Dispatch<React.SetStateAction<string>>,
    sizeOfWindow: SizeOfWindow,
    setSizeOfWindow: React.Dispatch<React.SetStateAction<SizeOfWindow>>,
    backendUrl: string,
    backendUrlNodeJS: string,
    user: UserProps | undefined,
    setUser: React.Dispatch<React.SetStateAction<UserProps | undefined>>,
    userToken: string | undefined,
    setUserToken: React.Dispatch<React.SetStateAction<string | undefined>>,
    userIsIdentified: boolean,
    setUserIsIdentified: React.Dispatch<React.SetStateAction<boolean>>,
    userAsRightsToModifyTheContent: boolean,
    setUserAsRightsToModifyTheContent: React.Dispatch<React.SetStateAction<boolean>>,
    blackCoverOfContent: boolean,
    setBlackCoverOfContent: React.Dispatch<React.SetStateAction<boolean>>,
    info: ModalContentProps,
    setInfo: React.Dispatch<React.SetStateAction<ModalContentProps>>,
    isDarkTheme: boolean
  ) {
    this.documentTitle = documentTitle;
    this.setDocumentTitle = setDocumentTitle;
    this.sizeOfWindow = sizeOfWindow;
    this.setSizeOfWindow = setSizeOfWindow;
    this.backendUrl = backendUrl;
    this.backendUrlNodeJS = backendUrlNodeJS;
    this.user = user;
    this.setUser = setUser;
    this.userToken = userToken;
    this.setUserToken = setUserToken;
    this.userIsIdentified = userIsIdentified;
    this.setUserIsIdentified = setUserIsIdentified;
    this.userAsRightsToModifyTheContent = userAsRightsToModifyTheContent;
    this.setUserAsRightsToModifyTheContent = setUserAsRightsToModifyTheContent;
    this.blackCoverOfContent = blackCoverOfContent;
    this.setBlackCoverOfContent = setBlackCoverOfContent;
    this.info = info;
    this.setInfo = setInfo;
    this.isDarkTheme = isDarkTheme;
  }


  setNewUser(user: UserProps, userToken: string) {
    this.setUser(user)
    this.setUserToken(userToken)
    this.setUserIsIdentified(true)
    this.setUserAsRightsToModifyTheContent(user.asRightsToModifyTheContent)
  }

  clearUser() {
    this.setUser(undefined)
    this.setUserToken("")
    this.setUserIsIdentified(false)
    this.setUserAsRightsToModifyTheContent(false)
  }

  getBackgroundColorClass() {
    return this.isDarkTheme ? 'hc-dark-theme' : 'hc-light-theme'
  }

  getBackgroundColor() {
    return this.isDarkTheme ? '#1a1a1a' : 'white'
  }

  getTextColor() {
    return this.isDarkTheme ? 'white' : darkTextColor
  }

  getSubTextColor() {
    return this.isDarkTheme ? '#aeb9b9' : '#6f6160'
  }

  getStylesForSelect<SelectEltType>(): StylesConfig<SelectEltType, false> {
    return {
      control: (base) => ({
        ...base,
        background: this.getBackgroundColor(),
        borderColor: "grey",
      }),
      singleValue: (provided) => ({
        ...provided,
        color: this.getTextColor(),
      }),
      option: (provided) => ({
        ...provided,
        color: this.getTextColor(),
        background: this.getBackgroundColor(),
        "&:hover": {
          background: this.isDarkTheme ? "Gray" : "#F0F3F4",
          cursor: 'pointer'
        },
      }),
    }
  }

defaultLineEditHeight() {
  return '37px'
}

}
