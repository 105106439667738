import './stars.scss';
import image_star_empty from '../../assets/resources/custom/icons/star_empty.png'
import { useEffect, useState } from 'react';
import { valueToStarImage } from './starsUtil';


interface StarsProps {
	initialScore: number | undefined;
	size: string;
	onChoosenValue: (nb: number) => any;
}

function StarsSelector({ initialScore, size, onChoosenValue }: Readonly<StarsProps>) {

	const [starImg1, setStarImg1] = useState(image_star_empty)
	const [starImg2, setStarImg2] = useState(image_star_empty)
	const [starImg3, setStarImg3] = useState(image_star_empty)
	const [starImg4, setStarImg4] = useState(image_star_empty)
	const [starImg5, setStarImg5] = useState(image_star_empty)

	function refreshStarImages(val: number) {
		setStarImg1(valueToStarImage(0, val))
		setStarImg2(valueToStarImage(1, val))
		setStarImg3(valueToStarImage(2, val))
		setStarImg4(valueToStarImage(3, val))
		setStarImg5(valueToStarImage(4, val))
	}

	useEffect(() => {
		if (initialScore !== undefined)
			refreshStarImages(initialScore)
	}, [])

	return (
		<div className={`stars-list stars-list-size-${size}`}>
			<button
				onMouseMove={(e) => refreshStarImages(1)}
				onClick={() => onChoosenValue(1)}
				aria-label="Star 1"
				className={`hc-cursor-pointer star-item star-item-size-${size}`}
				style={{ background: `url(${starImg1}) no-repeat center/contain`, border: 'none', padding: 0 }}
			/>
			<button
				onMouseMove={(e) => refreshStarImages(2)}
				onClick={() => onChoosenValue(2)}
				aria-label="Star 2"
				className={`hc-cursor-pointer star-item star-item-size-${size}`}
				style={{ background: `url(${starImg2}) no-repeat center/contain`, border: 'none', padding: 0 }}
			/>
			<button
				onMouseMove={(e) => refreshStarImages(3)}
				onClick={() => onChoosenValue(3)}
				aria-label="Star 3"
				className={`hc-cursor-pointer star-item star-item-size-${size}`}
				style={{ background: `url(${starImg3}) no-repeat center/contain`, border: 'none', padding: 0 }}
			/>
			<button
				onMouseMove={(e) => refreshStarImages(4)}
				onClick={() => onChoosenValue(4)}
				aria-label="Star 4"
				className={`hc-cursor-pointer star-item star-item-size-${size}`}
				style={{ background: `url(${starImg4}) no-repeat center/contain`, border: 'none', padding: 0 }}
			/>
			<button
				onMouseMove={(e) => refreshStarImages(5)}
				onClick={() => onChoosenValue(5)}
				aria-label="Star 5"
				className={`hc-cursor-pointer star-item star-item-size-${size}`}
				style={{ background: `url(${starImg5}) no-repeat center/contain`, border: 'none', padding: 0 }}
			/>
		</div>
	)
}

export default StarsSelector