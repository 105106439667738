import image_wait from '../../assets/resources/custom/icons/wait.gif'
import React, { ChangeEvent, useEffect, useState } from "react"
import { HcContext, SelectEltProps } from '../../types/hccontext'
import { FolderProps } from '../../props/folderprops'
import Centered from "../util/Centered";
import { getWaitPopupStyle } from "../util/GetWaitPopupStyle";
import GetHtmlStrLocalized from '../../datas/GetHtmlStrLocalized';
import H1Title from '../title/H1Title';
import EditionLabel from '../title/EditionLabel';
import { HcInput } from '../input/HcInput';
import { ChatbotEdition, ChatbotEditionProps } from '../../types/ChatbotEdition';
import Button from '../button/Button';
import HorizontalSpace from '../util/HorizontalSpace';
import { ButtonType } from '../../props/buttondataprops';
import { useNavigate } from 'react-router-dom';
import { imgToFirebaseUrl2 } from '../util/imagefallbacks';
import Select from 'react-select';
import TextsListViewer from '../listview/TextsListViewer';
import GetStrLocalized from '../../datas/GetStrLocalized';
import YesNoModal from '../modal/YesNoModal';
import { removeItem } from '../../util/removeItem';
import { createErrorModalContent } from '../../props/ModalContentProps';


interface ChatbotModifierProps {
	language: string;
	hcContext: HcContext;
	parentFolders: FolderProps[];
	currentFolderName: string;
	chatbotId: string;
	lastPath: string;
};

export class ChatbotModificationPostContentType {
	language: string;
	userKey: string;
	parentFolders: FolderProps[];
	currentFolderName: string;
	id: string;
	oldValue: ChatbotEditionProps;
	value: ChatbotEditionProps;

	constructor(
		language: string,
		userKey: string,
		parentFolders: FolderProps[],
		currentFolderName: string,
		id: string,
		oldValue: ChatbotEditionProps,
		value: ChatbotEditionProps) {
		this.language = language
		this.userKey = userKey
		this.parentFolders = parentFolders
		this.currentFolderName = currentFolderName
		this.id = id
		this.oldValue = oldValue
		this.value = value
	}
}

function ChatbotModifier({ language, hcContext, parentFolders, currentFolderName, chatbotId, lastPath }: Readonly<ChatbotModifierProps>) {

	const navigate = useNavigate()
	const [pageFound, setPageFound] = useState(false)
	const [fallbackEngines, setFallbackEngines] = useState<SelectEltProps[]>([])
	const [modificationDone, setModificationDone] = useState(false)
	const [canBeValidated, setCanBeValidated] = useState(false)
	const [processingModifications, setProcessingModifications] = useState(false)
	const [showCanCancelModifictionsModal, setShowCanCancelModifictionsModal] = useState(false)
	const [showCanDeleteModifictionsModal, setShowCanDeleteModifictionsModal] = useState(false)
	const [chatbotEditionGetOld, setChatbotEditionGetOld] = useState<ChatbotEditionProps>({
		nb: null, name: "", isHidden: false, logoImage: "", userBackgroundBubbleColor: "",
		useDefaultContent: false, allowToJumpFromLocalMessageToDefaultRecommendations: false,
		useDefaultProactivity: false, showOptionMenu: true, url: "", fallbackEngine: "",
		sourcesNotToDisplay: [], goals: [], changeSubjectInfos: []
	})
	const [nb, setNb] = useState<number | null>(null)
	const [name, setName] = useState<string>("")
	const [isHidden, setIsHidden] = useState<boolean>(false)
	const [logoImage, setLogoImage] = useState<string>("")
	const [userBackgroundBubbleColor, setUserBackgroundBubbleColor] = useState<string>("")
	const [useDefaultContent, setUseDefaultContent] = useState<boolean>(false)
	const [allowToJumpFromLocalMessageToDefaultRecommendations, setAllowToJumpFromLocalMessageToDefaultRecommendations] = useState<boolean>(false)
	const [useDefaultProactivity, setUseDefaultProactivity] = useState<boolean>(false)
	const [showOptionMenu, setShowOptionMenu] = useState<boolean>(true)
	const [url, setUrl] = useState<string>("")
	const [fallbackEngine, setFallbackEngine] = useState<string>("")
	const [sourcesNotToDisplay, setSourcesNotToDisplay] = useState<string[]>([])
	const [sourcesNotToDisplayStr, setSourcesNotToDisplayStr] = useState("")
	const [goals, setGoals] = useState<string[]>([])
	const [goalsStr, setGoalsStr] = useState("")
	const [changeSubjectInfos, setChangeSubjectInfos] = useState<string[]>([])
	const [changeSubjectInfosStr, setChangeSubjectInfosStr] = useState("")
	const chatbotEdition = new ChatbotEdition({
		nb, name, isHidden, logoImage, userBackgroundBubbleColor,
		useDefaultContent, allowToJumpFromLocalMessageToDefaultRecommendations, useDefaultProactivity,
		showOptionMenu, url, fallbackEngine, sourcesNotToDisplay, goals, changeSubjectInfos
	},
		setNb, setName, setIsHidden, setLogoImage, setUserBackgroundBubbleColor, setUseDefaultContent,
		setAllowToJumpFromLocalMessageToDefaultRecommendations, setUseDefaultProactivity,
		setShowOptionMenu, setUrl, setFallbackEngine, setSourcesNotToDisplay, setSourcesNotToDisplayStr,
		setGoals, setGoalsStr, setChangeSubjectInfos, setChangeSubjectInfosStr)

	useEffect(() => {
		if (pageFound) {
			if (chatbotId === "" ||
				JSON.stringify(chatbotEditionGetOld) !== JSON.stringify(chatbotEdition.value)) {
				setModificationDone(true)
				setCanBeValidated(
					chatbotEdition.value.name.length > 0)
			} else {
				setModificationDone(false)
				setCanBeValidated(false)
			}
		}
	}, [nb, name, isHidden, logoImage, userBackgroundBubbleColor,
		useDefaultContent, allowToJumpFromLocalMessageToDefaultRecommendations, useDefaultProactivity,
		showOptionMenu, url, fallbackEngine, sourcesNotToDisplayStr, goalsStr, changeSubjectInfosStr]);


	useEffect(() => {
		if (chatbotId !== "") {
			fetch(hcContext.backendUrl + "/chatbot_edit_get_page_json?l=" + language + "&id=" + chatbotId)
				.then(res => res.json())
				.then(jsonData => {
					if (jsonData.errorMessage) {
						setPageFound(false)
						hcContext.setInfo(createErrorModalContent(language, jsonData.errorMessage))
					} else {
						jsonData.chatbot.useDefaultContent = jsonData.chatbot.useDefaultContent === "true"
						jsonData.chatbot.allowToJumpFromLocalMessageToDefaultRecommendations = jsonData.chatbot.allowToJumpFromLocalMessageToDefaultRecommendations === "true"
						jsonData.chatbot.useDefaultProactivity = jsonData.chatbot.useDefaultProactivity === "true"
						jsonData.chatbot.showOptionMenu = jsonData.chatbot.showOptionMenu === "true"
						if (jsonData.chatbot.sourcesNotToDisplay === "")
							jsonData.chatbot.sourcesNotToDisplay = []
						if (jsonData.chatbot.goals === "")
							jsonData.chatbot.goals = []
						if (jsonData.chatbot.changeSubjectInfos === "")
							jsonData.chatbot.changeSubjectInfos = []
						setChatbotEditionGetOld(jsonData.chatbot)
						chatbotEdition.set(jsonData.chatbot)
						setFallbackEngines(jsonData.fallbackEngines)
						hcContext.setDocumentTitle(jsonData.chatbot.name + " - Edition")
						setPageFound(true)
					}
				})
				.catch(rejected => {
					setPageFound(false)
					hcContext.setInfo(createErrorModalContent(language, rejected.message))
				});
		}
		else {
			hcContext.setDocumentTitle("Ajout d'un chatbot")
			setPageFound(true)
		}
	}, [])

	function sendChatbotModification() {
		if (!hcContext.userToken)
			return
		setProcessingModifications(true)
		hcContext.setBlackCoverOfContent(true)
		const postContent = new ChatbotModificationPostContentType(language, hcContext.userToken,
			parentFolders, currentFolderName, chatbotId, chatbotEditionGetOld, chatbotEdition.value)
		const postContentStr = JSON.stringify(postContent)
		fetch(hcContext.backendUrl + "/modify_chatbot", {
			method: 'POST',
			body: postContentStr,
			headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
		})
			.then(res => res.json())
			.then(jsonData => {
				setProcessingModifications(false)
				hcContext.setBlackCoverOfContent(false)
				if (jsonData.errorMessage) {
					hcContext.setInfo(createErrorModalContent(language, jsonData.errorMessage))
				} else {
					navigate('/' + language + '/' + GetStrLocalized(language, "chatbotConfigFolderName") + '/' + jsonData.id);
				}
			})
			.catch(rejected => {
				setProcessingModifications(false)
				hcContext.setBlackCoverOfContent(false)
				hcContext.setInfo(createErrorModalContent(language, rejected.message))
			});
	}

	return (
		<>
			{pageFound ?
				<>
					{
						processingModifications &&
						<Centered>
							<div style={getWaitPopupStyle(true, 50)}>
								<img src={image_wait} alt="wait" height="100%" width="100%" />
							</div>
						</Centered>
					}
					<table width='100%'>
						<tbody>
							<tr>
								<td width={(30 + 15) * 2 + 45} style={{ paddingTop: 20 }}>
									{
										canBeValidated &&
										!processingModifications &&
										<button onClick={sendChatbotModification}>
											<GetHtmlStrLocalized language={language} textId="modify" />
										</button>
									}
								</td>
								<td>
									<H1Title>
										<EditionLabel paddingTop={0} language={language} textId="number" />&nbsp;
										<HcInput width='70px' value={chatbotEdition.value.nb?.toString()} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { chatbotEdition.setNb(Number(event.target.value)); }} />
										&nbsp; &nbsp;
										{
											chatbotEdition.value.name.length > 0 ?
												<EditionLabel paddingTop={0} language={language} textId="name" />
												:
												<EditionLabel paddingTop={0} language={language} textId="name" color="red" />
										}
										&nbsp;
										<HcInput width='500px' value={chatbotEdition.value.name} onChange={(e: ChangeEvent<HTMLInputElement>) => { chatbotEdition.setName(e.target.value); }} />
									</H1Title>
								</td>
								{
									(hcContext.userAsRightsToModifyTheContent && !processingModifications) ?
										<td width={(30 + 15) * 2 + 45} style={{ paddingTop: 20 }} className="hc-not-very-short-screen">
											{
												(modificationDone || chatbotId === "") ?
													<>
														{
															canBeValidated ?
																<>
																	<Button btnData={{ type: ButtonType.YES, onClick: () => sendChatbotModification() }} language={language} altId="modify" isDarkTheme={hcContext.isDarkTheme} />
																	<HorizontalSpace />
																</>
																:
																<>
																	<HorizontalSpace width={30} />
																	<HorizontalSpace />
																</>
														}
													</>
													:
													<>
														<Button btnData={{ type: ButtonType.DELETE, onClick: () => setShowCanDeleteModifictionsModal(true) }} language={language} isDarkTheme={hcContext.isDarkTheme} />
														<HorizontalSpace />
													</>
											}
											<Button btnData={{ type: ButtonType.NO, onClick: () => modificationDone ? setShowCanCancelModifictionsModal(true) : navigate(lastPath) }} language={language} altId="cancel" isDarkTheme={hcContext.isDarkTheme} />
											<HorizontalSpace />
										</td>
										:
										<td width={(30 + 15) * 2 + 45} className="hc-not-very-short-screen"></td>
								}
							</tr>
						</tbody>
					</table>
					<br />
					<br />

					<div style={{ marginLeft: 20, marginRight: 20 }}>

						<table style={{ width: "100%" }}>
							<thead className="visually-hidden"><tr><th></th></tr></thead>
							<tbody>
								<tr>
									<td style={{ width: "50%", paddingRight: 20 }}>
										<table>
											<thead className="visually-hidden"><tr><th></th></tr></thead>
											<tbody>
												<tr>
													<td>
														<EditionLabel paddingTop={0} language={language} textId="logo" />
													</td>
													<td style={{ textAlign: 'center', width: '100%' }}></td>
													<td style={{ paddingRight: '15px' }}>
														{
															chatbotEdition.value.logoImage.length === 0 ?
																<Button btnData={{
																	type: ButtonType.ADD, onClick: () => {
																		//setShowImageAdditionModal(true)
																	}
																}} language={language} isDarkTheme={hcContext.isDarkTheme} />
																:
																<Button btnData={{
																	type: ButtonType.EDIT, onClick: () => {
																		//setShowImageAdditionModal(true)
																	}
																}} language={language} isDarkTheme={hcContext.isDarkTheme} />
														}
													</td>
												</tr>
											</tbody>
										</table>

										&nbsp; &nbsp; &nbsp;
										{
											chatbotEdition.value.logoImage.length > 0 &&
											<img src={imgToFirebaseUrl2("upload/" + chatbotEdition.value.logoImage)} alt={chatbotId} className="hc-not-selectable" height={50} />
										}
										<br />
										<br />

										<EditionLabel paddingTop={0} language={language} textId="bubbleBackgroundColor" />
										<br />
										<HcInput width='500px' value={chatbotEdition.value.userBackgroundBubbleColor} onChange={(e: ChangeEvent<HTMLInputElement>) => { chatbotEdition.setUserBackgroundBubbleColor(e.target.value); }} />
										<br />
										<br />
										<br />

										<input type="checkbox" checked={chatbotEdition.value.useDefaultContent} onChange={(event: ChangeEvent<HTMLInputElement>) => {
											chatbotEdition.setUseDefaultContent(event.target.checked)
										}
										} />
										<GetHtmlStrLocalized language={language} textId="infUseTheDefaultContent" />
										<br />

										<input type="checkbox" checked={chatbotEdition.value.allowToJumpFromLocalMessageToDefaultRecommendations} onChange={(event: ChangeEvent<HTMLInputElement>) => {
											chatbotEdition.setAllowToJumpFromLocalMessageToDefaultRecommendations(event.target.checked)
										}
										} />
										<GetHtmlStrLocalized language={language} textId="allowToJumpFromLocalMessageToDefaultRecommendations" />
										<br />

										<input type="checkbox" checked={chatbotEdition.value.useDefaultProactivity} onChange={(event: ChangeEvent<HTMLInputElement>) => {
											chatbotEdition.setUseDefaultProactivity(event.target.checked)
										}
										} />
										<GetHtmlStrLocalized language={language} textId="useDefaultProactivity" />
										<br />

										<input type="checkbox" checked={chatbotEdition.value.showOptionMenu} onChange={(event: ChangeEvent<HTMLInputElement>) => {
											chatbotEdition.setShowOptionMenu(event.target.checked)
										}
										} />
										<GetHtmlStrLocalized language={language} textId="showOption" />
										<br />
										<br />
										<br />

										<EditionLabel paddingTop={0} language={language} textId="url" />
										<br />
										<HcInput width='500px' value={chatbotEdition.value.url} onChange={(e: ChangeEvent<HTMLInputElement>) => { chatbotEdition.setUrl(e.target.value); }} />
										<br />
										<br />
										<br />

										<EditionLabel paddingTop={0} language={language} textId="fallbackEngine" />
										<Select
											value={{ label: chatbotEdition.value.fallbackEngine, value: chatbotEdition.value.fallbackEngine }}
											onChange={(elt: SelectEltProps | null) => {
												if (elt !== null)
													chatbotEdition.setFallbackEngine(elt.value);
											}}
											getOptionValue={(elt: SelectEltProps) => elt.value}
											getOptionLabel={(elt: SelectEltProps) => elt.value}
											options={fallbackEngines}
											styles={hcContext.getStylesForSelect<SelectEltProps>()}
										/>
									</td>
									<td style={{ width: "50%", paddingLeft: 20 }}>

										<TextsListViewer
											language={language}
											titleId='sourcesNotToDisplay'
											descriptions={chatbotEdition.value.sourcesNotToDisplay}
											setDescriptions={(t) => { chatbotEdition.setSourcesNotToDisplayFn(t) }}
											isDarkTheme={hcContext.isDarkTheme}
										/>
										<br />
										<br />

										<TextsListViewer
											language={language}
											titleId='goals'
											descriptions={chatbotEdition.value.goals}
											setDescriptions={(t) => { chatbotEdition.setGoalsFn(t) }}
											isDarkTheme={hcContext.isDarkTheme}
										/>
										<br />
										<br />

										<TextsListViewer
											language={language}
											titleId='toChangeTheSubject'
											descriptions={chatbotEdition.value.changeSubjectInfos}
											setDescriptions={(t) => { chatbotEdition.setChangeSubjectInfosFn(t) }}
											isDarkTheme={hcContext.isDarkTheme}
										/>
										<br />
										<br />
									</td>
								</tr>
							</tbody>
						</table>
						<br />
						<br />

					</div>
				</>
				:
				<>
					<br />
					<br />
					<div className='hc-content-text' style={{ textAlign: 'center', margin: '0 auto' }}>
						<GetHtmlStrLocalized language={language} textId="sorryThisPageIsNotAvailable" />
					</div>
				</>
			}

			<YesNoModal language={language} show={showCanDeleteModifictionsModal} setShow={setShowCanDeleteModifictionsModal}
				isDarkTheme={hcContext.isDarkTheme}
				title={GetStrLocalized(language, "removalConfirmation")}
				text={GetStrLocalized(language, "areYouSureToPermanentlyDeletethisChatbot")}
				onYes={() => removeItem(chatbotId, "chatbot", hcContext, language, setProcessingModifications, navigate)} />
		</>
	)
}

export default ChatbotModifier
