import React from 'react'
import GetHtmlStrLocalized from '../../datas/GetHtmlStrLocalized'
import Modal, { ModalBody, ModalFooter, ModalHeader } from './Modal'


interface ThankYouModalProps {
    language: string;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    isDarkTheme: boolean;
    title: string;
    text: string;
    texts?: string[];
}


function ThankYouModal({ language, setShow, isDarkTheme, title, text, texts }: Readonly<ThankYouModalProps>) {

    setTimeout(() => setShow(false), 3000); // Auto close after 3 seconds

    return (
        <Modal
            language={language}
            show={true}
            setShow={setShow}
            isDarkTheme={isDarkTheme}
        >
            <ModalHeader>
                <h3>{title}</h3>
            </ModalHeader>
            <ModalBody>
                <span style={{ color: 'green' }}>
                    {text}
                    {
                        texts !== undefined &&
                        <ul>
                            {
                                texts.map((currText, index) => (
                                    <li key={`info-modal-text-${index}`}>{currText}</li>
                                ))
                            }
                        </ul>
                    }
                </span>
            </ModalBody>
            <br />
            <ModalFooter>
                <button onClick={() => {
                    setShow(false)
                }}>
                    <GetHtmlStrLocalized language={language} textId="ok" />
                </button>
            </ModalFooter>
        </Modal>
    );
}

export default ThankYouModal;